import React from "react";
import { Form, Col, Button, Table } from "react-bootstrap";
import SpinnerComponent from "../../components/common/spinner";
import PaginationV2 from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import moment from "moment";
import momentJalaali from "moment-jalaali";
import DatePicker from "react-datepicker2";
import ProductMasterModel from "../../models/productMaster/productMasterModel";
class ProductMaster extends ProductMasterModel {
    // getPagedData = () => {
    //     const { pageSize, currentPage, productMasterList } = this.state;

    //     const filteredList = paginate(productMasterList, currentPage, pageSize);

    //     return { totalCount: productMasterList.length, data: filteredList };
    // };
    render() {
        const { showSpinner, errors, isSubmitted, pageSize, currentPage, successMsg, productMasterList, isSaveDisabled, openForm, formData, isFormDisabled, searchField, lendingPartnerList, isCloneButtonDisabled, isDeleteButtonDisabled, isEditButtonDisabled, hasName, subscriptionCheck, isProcessingFeeValueType, processingFeesRadioValue, interestComputationMethodValue, isCreditLineCollectionTypeSelected, isSubventionInterestCheckNotSelectedM, isSubventionInterestCheckNotSelectedEP, isSubventionInterestCheckNotSelectedLP, isSubventionInterestCheckNotSelectedFF, isSubventionProcessingFeesCheckNotSelectedM, isSubventionProcessingFeesCheckNotSelectedEP, isSubventionProcessingFeesCheckNotSelectedLP, isSubventionProcessingFeesCheckNotSelectedFF, creditLineCollectionDropDownValue, creditLineNoOfInstallments, creditLineMaxNoOfDays, subventionProcessingFeesValueTypeM, subventionInterestValueTypeM, subventionInterestValueTypeEP, subventionInterestValueTypeLP, subventionInterestValueTypeFF, subventionInterestRICOF, subventionProcessingFeesValueTypeEP, subventionProcessingFeesValueTypeLP, subventionProcessingFeesValueTypeFF, interestPrePostRadioCheck, tenureObject, collectionTypeValue, interestCollectionCheckbox, principalCollectionCheckbox, principalAtEndOfTenureCollectionCheckbox, subventionInterestCheckFullEntity, isSubventionInterestCheckDisabledM, isSubventionInterestCheckDisabledEP,
            isSubventionInterestCheckDisabledLP, isSubventionInterestCheckDisabledFF, subventionProcessingFeesCheckFullEntity, isSubventionProcessingFeesCheckDisabledM, isSubventionProcessingFeesCheckDisabledEP, isSubventionProcessingFeesCheckDisabledLP, isSubventionProcessingFeesCheckDisabledFF } = this.state;


        // const { totalCount, data: productMasterListState } = this.getPagedData();
        console.log("rendering component")
        console.log("productMasterList: ", productMasterList)
        return (
            <React.Fragment>
                {!openForm && <div>
                    <SpinnerComponent showSpinner={showSpinner} />
                    <h2
                        className="fundfinaColor"
                        style={{ textDecoration: "underline", textAlign: "center" }}
                    >
                        Product Master
                    </h2>{" "}
                    <br />
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} md="10" controlId="productName" style={{ display: "flex", flexWrap: "wrap" }}>
                                <Form.Label>Product Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productName"
                                    defaultValue={searchField}
                                    onChange={(e) => this.handleSearchFieldChange(e)}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                            </Form.Group>{" "}
                            <Form.Group as={Col} md="1">
                                <Button
                                    disabled={searchField === "" ? true : false}
                                    className="ff-button"
                                    type="submit"
                                    style={{ marginTop: 32, marginLeft: 10 }}
                                    onClick={(e) => this.handleProductSearchByName(e)}
                                >
                                    Search
                                </Button>
                            </Form.Group>
                            <Form.Group as={Col} md="1">
                                <Button
                                    className="ff-button"
                                    type="button"
                                    style={{ marginTop: 32, marginLeft: 10 }}
                                    onClick={(e) => this.handleCreateForm(e)}
                                >
                                    Create
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                    {errors["globalError"] && (
                        <div className="alert alert-danger">
                            {errors["globalError"]}
                        </div>
                    )}
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{ width: "75%" }}>Product Name</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productMasterList &&
                                productMasterList.map((product) => (
                                    <tr key={product['_id']} >
                                        <td>{product.name}</td>
                                        <td style={{ display: "flex", justifyContent: "center" }}>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                // disabled={product.hasLoans && product.hasLoans === true ? true : false}
                                                style={{ marginRight: 10 }}
                                                onClick={() => this.handleViewForm({ ...product })}
                                            >
                                                View
                                            </Button>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                disabled={product.hasLoans && product.hasLoans === true ? true : false}
                                                style={{ marginRight: 10 }}
                                                onClick={(e) => this.handleDeleteProduct(e, product.name)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                className="ff-button"
                                                type="button"
                                                // disabled={isSubmitted}
                                                style={{ marginRight: 10 }}
                                                onClick={(e) => this.handleCloneForm(e, { ...product })}
                                            >
                                                Clone
                                            </Button>
                                        </td>
                                    </tr>
                                ))}

                        </tbody>
                    </Table>

                    <div style={{ width: "100%", overflowX: "scroll" }}>
                        {/* <Pagination
                            itemsCount={totalCount}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange}
                            onPagePrevious={this.handlePagePrevious}
                            onPageNext={this.handlePageNext}
                        /> */}
                        <PaginationV2
                            currentPage={currentPage}
                            pageSize={pageSize}
                            onPageChange={this.onPageChange}
                            onPagePrevious={this.onPagePrevious}
                            onPageNext={this.onPageNext}
                        />
                    </div>
                </div>}
                {openForm && <div>
                    <div style={{ textAlign: "right" }}>
                        <Button
                            className="ff-button"
                            type="button"
                            style={{ marginRight: 10 }}
                            onClick={this.goBack}
                        >
                            Back
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            disabled={isEditButtonDisabled}
                            style={{ marginRight: 10 }}
                            onClick={(e) => this.handleEnableAndEditForm(e)}
                        >
                            Edit
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            disabled={isDeleteButtonDisabled}
                            style={{ marginRight: 10 }}
                            onClick={(e) => this.handleDeleteProductInForm(e)}
                        >
                            Delete
                        </Button>
                        <Button
                            className="ff-button"
                            type="button"
                            disabled={isCloneButtonDisabled}
                            style={{ marginRight: 10 }}
                            onClick={(e) => this.handleCloneProductInForm(e)}
                        >
                            Clone
                        </Button>
                    </div>

                    <Form onSubmit={(e) => this.handleFormSubmit(e)}>
                        <Form.Row>
                            <Form.Group
                                as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}

                            >
                                <Form.Group md="12">
                                    <Form.Row>
                                        <h6>Properties</h6>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="category">
                                            <Form.Label>Loan Type</Form.Label>
                                            <Form.Control
                                                //required
                                                as="select"
                                                name="category"
                                                defaultValue={formData['category'] && formData['category'] !== null ? formData['category'] : ""}
                                                disabled={isFormDisabled}
                                                onChange={(e) => this.handleCategoryChange(e)}
                                            >
                                                {" "}
                                                <option key="AMORTISING" value="AMORTISING">
                                                    Amortising
                                                </option>
                                                <option key="NON_AMORTISING" value="NON_AMORTISING">
                                                    Non Amortising
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="typeSelect">
                                            <Form.Label>Product Type</Form.Label>
                                            <Form.Control
                                                //required
                                                as="select"
                                                name="category"
                                                defaultValue={formData['type'] ? formData['type'] : ""}
                                                disabled={isFormDisabled}

                                                onChange={(e) => this.handleTypeChange(e)}
                                            >
                                                {" "}
                                                <option key="select" value="" id="select" name="type">
                                                    Select Type
                                                </option>
                                                {formData['category'] === "NON_AMORTISING" ? (<option key="DL" value="DL" id="DL" name="type">
                                                    Daily Loans
                                                </option>) : null}
                                                {formData['category'] === "NON_AMORTISING" ? (<option key="CL" value="CL" id="CL" name="type">
                                                    Credit Line
                                                </option>) : null}
                                                {formData['category'] === "AMORTISING" ? (<option key="TL" value="TL" id="TL" name="type">
                                                    Term Loan
                                                </option>) : null}
                                            </Form.Control>
                                            {errors["typeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["typeError"]}
                                                </div>
                                            )}

                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="name">
                                            <Form.Label>Product Name</Form.Label>
                                            <Form.Control
                                                //required
                                                type="text"
                                                name="name"
                                                value={formData['name'] ? formData['name'] : ""}
                                                onChange={(e) => this.handleFormFieldChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled || hasName}
                                            />
                                            {errors["nameError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["nameError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="startDate">
                                            <Form.Label>Start Date</Form.Label>
                                            <DatePicker
                                                name="startDate"
                                                id="startDate"
                                                value={formData['startDate'] ? momentJalaali(formData['startDate']) : momentJalaali()}
                                                inputFormat="DD/MM/YYYY"
                                                isGregorian={true}
                                                className="form-control"
                                                inputReadOnly={true}
                                                showTodayButton={false}
                                                timePicker={false}
                                                onChange={(value) => this.handleFormDateChange(value, "startDate")}
                                                placeholder="Enter Start Date"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["startDate"] && (
                                                <div className="alert alert-danger">
                                                    {errors["startDate"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="endDate">
                                            <Form.Label>End Date</Form.Label>
                                            <DatePicker
                                                name="endDate"
                                                id="endDate"
                                                value={formData['endDate'] ? momentJalaali(formData['endDate']) : momentJalaali()}
                                                inputFormat="DD/MM/YYYY"
                                                isGregorian={true}
                                                className="form-control"
                                                inputReadOnly={true}
                                                showTodayButton={false}
                                                timePicker={false}
                                                onChange={(value) => this.handleFormDateChange(value, "endDate")}
                                                placeholder="Enter End Date"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["endDate"] && (
                                                <div className="alert alert-danger">
                                                    {errors["endDate"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="lender">
                                            <Form.Label>Lender</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="lender"
                                                defaultValue={formData['lender'] && formData['lender']['name'] && formData['lender']['name'] ? formData['lender']['name'] : ""}
                                                onChange={(e, key) => this.handleLenderChange(e, key)}
                                                disabled={isFormDisabled}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    Select Lender
                                                </option>
                                                {lendingPartnerList.map((lender) => (
                                                    <option key={lender._id} value={lender.displayName} name={lender._id}>
                                                        {lender.displayName}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                            {errors["lenderError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["lenderError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Min Loan Amount</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="minLoanAmount"
                                                id="minLoanAmountError"
                                                defaultValue={formData['loanAmountRange'] && formData['loanAmountRange']['min'] ? formData['loanAmountRange']['min'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleLoanRangeChange(e, "min")}
                                                autoComplete="off"
                                                style={{ textTransform: "uppercase" }}
                                                disabled={isFormDisabled}

                                            />
                                            {errors["minLoanAmountError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["minLoanAmountError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>Max Loan Amount</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="maxLoanAmount"
                                                id="maxLoanAmountError"
                                                defaultValue={formData['loanAmountRange'] && formData['loanAmountRange']['max'] ? formData['loanAmountRange']['max'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleLoanRangeChange(e, "max")}
                                                autoComplete="off"
                                                style={{ textTransform: "uppercase" }}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["maxLoanAmountError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["maxLoanAmountError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="4">
                                            <Form.Row>
                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>Min Tenure</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="min"
                                                        id="minTenureError"
                                                        value={tenureObject && tenureObject['minTenure'] ? tenureObject['minTenure'] : (formData['tenure'] && formData['tenure']['min'] ? formData['tenure']['min'] : "")}
                                                        onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                        onChange={(e) => this.handleTenureChange(e)}
                                                        autoComplete="off"
                                                        disabled={isFormDisabled}
                                                    />
                                                    {errors["minTenureError"] && (
                                                        <div className="alert alert-danger">
                                                            {errors["minTenureError"]}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                    <Form.Label>Max Tenure</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        name="max"
                                                        id="maxTenureError"
                                                        value={tenureObject && tenureObject['maxTenure'] ? tenureObject['maxTenure'] : (formData['tenure'] && formData['tenure']['max'] ? formData['tenure']['max'] : "")}
                                                        onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                        onChange={(e) => this.handleTenureChange(e)}
                                                        autoComplete="off"
                                                        disabled={isFormDisabled}
                                                    />
                                                    {errors["maxTenureError"] && (
                                                        <div className="alert alert-danger">
                                                            {errors["maxTenureError"]}
                                                        </div>
                                                    )}
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4">
                                            <Form.Label>KYC RECAPTURE (In Days)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="kycRecaptureInDays"
                                                id="kycRecaptureInDaysError"
                                                value={formData['kycRecaptureInDays'] ? formData['kycRecaptureInDays'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleKycRecaptureInDaysChange(e)}
                                                autoComplete="off"
                                                // style={{ textTransform: "uppercase" }}
                                                disabled={isFormDisabled}

                                            />
                                            {errors["kycRecaptureInDaysError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["kycRecaptureInDaysError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group
                                as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}
                                className="row"
                            >
                                <Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="processingFeesLabel">
                                            <Form.Label>Processing Fees</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="2">
                                            <Form.Check

                                                inline
                                                type="radio"
                                                name="valueType1"
                                                value="FLAT"
                                                id="FLAT"
                                                label="Flat"
                                                checked={formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['valueType'] && formData['loan']['processingFees']['valueType'] === 'FLAT' ? "checked" : ""}
                                                onChange={(e) => this.handleProcessingFeeRadioChange(e)}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["processingFeeValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["processingFeeValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="2">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                name="valueType2"
                                                value="PERCENTAGE"
                                                id="PERCENTAGE"
                                                label="Percentage"
                                                onChange={(e) => this.handleProcessingFeeRadioChange(e)}
                                                checked={formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['valueType'] && formData['loan']['processingFees']['valueType'] === 'PERCENTAGE' ? "checked" : ""}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["processingFeeValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["processingFeeValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="4">
                                            <Form.Control
                                                type="number"
                                                name="value"
                                                placeholder="Value"
                                                value={formData['loan'] && formData['loan']['processingFees'] && (formData['loan']['processingFees']['value'] || formData['loan']['processingFees']['value'] === 0) ? formData['loan']['processingFees']['value'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleProcessingFeeValueChange(e)}
                                                autoComplete="off"
                                                // maxLength="100"
                                                // tabIndex="0"
                                                disabled={isFormDisabled || (formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['valueType'] ? false : true)}
                                            />
                                            {errors["processingFeeValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["processingFeeValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" ></Form.Group>
                                        <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "flex-start" }} controlId="processingFees">
                                            <Form.Check
                                                inline
                                                type="checkbox"
                                                id="SUBSCRIPTION"
                                                label="Subscription (In Days)"
                                                disabled={isFormDisabled}
                                                checked={formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['subscriptionTenure'] && formData['loan']['processingFees']['subscriptionTenure']['valueType'] ? "checked" : ""}
                                                onChange={(e) => this.handleEnableSubscriptionChange(e)}
                                            />
                                            <Form.Control //required
                                                style={{ width: "35%" }}
                                                type="number"
                                                name="SubscriptionValue"
                                                placeholder="Tenure"
                                                value={formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['subscriptionTenure'] && formData['loan']['processingFees']['subscriptionTenure']['value'] ? formData['loan']['processingFees']['subscriptionTenure']['value'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleSubscriptionValueChange(e)}
                                                autoComplete="off"
                                                // maxLength="100"
                                                disabled={isFormDisabled || subscriptionCheck}
                                            // min="0"
                                            />
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>

                                {errors["processingFeeSubscriptionTenureValueError"] && (<Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }}>
                                            <div className="alert alert-danger">
                                                {errors["processingFeeSubscriptionTenureValueError"]}
                                            </div>
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>)}

                                <Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="interestFeesLabel">
                                            <Form.Label>Interest Fees</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="8" style={{ display: "flex" }} controlId="interestFees">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="PRE"
                                                label="Pre"
                                                name="stage1"
                                                value="PRE"
                                                disabled={isFormDisabled}
                                                checked={formData['loan'] && formData['loan']['recoverInterest'] && formData['loan']['recoverInterest']['stage'] && formData['loan']['recoverInterest']['stage'] === "PRE" ? "checked" : ""}
                                                onChange={(e) => this.handleInterestValueChange(e)}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="POST"
                                                name="stage2"
                                                value="POST"
                                                label="Post"
                                                disabled={isFormDisabled}
                                                checked={formData['loan'] && formData['loan']['recoverInterest'] && formData['loan']['recoverInterest']['stage'] && formData['loan']['recoverInterest']['stage'] === "POST" ? "checked" : ""}
                                                onChange={(e) => this.handleInterestValueChange(e)}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                {errors["recoverInterestStageError"] && (<Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }}>
                                            <div className="alert alert-danger">
                                                {errors["recoverInterestStageError"]}
                                            </div>
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>)}

                                <Form.Group as={Col} md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="interestComputationLabel">
                                            <Form.Label>Interest Computation</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" >
                                            <Form.Control
                                                as="select"
                                                name="interestComputationType"
                                                // defaultValue={interestComputationMethodValue ? interestComputationMethodValue : ""}
                                                defaultValue={formData['interest'] && formData['interest']['type'] ? formData['interest']['type'] : ""}
                                                onChange={(e) => this.handleInterestComputationMethodChange(e)}
                                                style={{ width: "auto", marginBottom: "20px" }}
                                                disabled={isFormDisabled}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    Select Computation method
                                                </option>
                                                <option key="SIMPLE" value="SIMPLE">
                                                    Simple
                                                </option>
                                                <option key="COMPOUND" value="COMPOUND">
                                                    Compound
                                                </option>
                                            </Form.Control>
                                            {errors["interestComputationTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["interestComputationTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" />
                                        <Form.Group as={Col} md="2">
                                            <Form.Check

                                                inline
                                                type="radio"
                                                name="valueType3"
                                                value="FLAT"
                                                id="INTEREST_FLAT"
                                                label="Flat"
                                                checked={formData['interest'] && formData['interest']['charges'] && formData['interest']['charges']['valueType'] && formData['interest']['charges']['valueType'] === 'FLAT' ? "checked" : ""}
                                                onChange={(e) => this.handleInterestTypeRadioChange(e)}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["interestValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["interestValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="2">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                name="valueType4"
                                                value="PERCENTAGE"
                                                id="INTEREST_PERCENTAGE"
                                                label="Percentage"
                                                onChange={(e) => this.handleInterestTypeRadioChange(e)}
                                                checked={formData['interest'] && formData['interest']['charges'] && formData['interest']['charges']['valueType'] && formData['interest']['charges']['valueType'] === 'PERCENTAGE' ? "checked" : ""}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["interestValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["interestValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="4">
                                            <Form.Control
                                                type="number"
                                                name="interestValue"
                                                placeholder="Value"
                                                value={formData['interest'] && formData['interest']['charges'] && (formData['interest']['charges']['value'] || formData['interest']['charges']['value'] === 0) ? formData['interest']['charges']['value'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleInterestTypeValueChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled || (formData['interest'] && formData['interest']['charges'] && formData['interest']['charges']['valueType'] ? false : true)}
                                            />
                                            {errors["interestValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["interestValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}
                                className="row">

                                {/* MERCHANT */}
                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="3" controlId="subventionMerchant">
                                            <Form.Label>Subvention - Merchant</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="9" controlId="subventionMerchant">
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }} controlId="subventionMerchantLabel">
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionInterestM"
                                                        id="subMInterest"
                                                        // disabled={isFormDisabled || isSubventionInterestCheckDisabledM}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionInterestCheckChange(e, "MERCHANT")}
                                                        checked={formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }} controlId="subventionMerchantLabel">
                                                    <Form.Label style={{ textDecoration: "underline" }}>Interest</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>

                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionMInterestFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionInterestFullM"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedM}
                                                checked={subventionInterestValueTypeM === "FULL" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] >= 100 ? true : false)}

                                                // checked={subventionInterestValueTypeM === "FULL" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] === 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "MERCHANT")}
                                            />
                                            {errors["subVentionMInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionMInterestPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionInterestPercentageM"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedM}
                                                // checked={subventionInterestValueTypeM === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && (formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] < 100) ? true : false)}

                                                checked={subventionInterestValueTypeM === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "MERCHANT")}
                                            />
                                            {errors["subVentionMInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionInterestValueM"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedM}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionInterest("MERCHANT", "value")}
                                                onChange={(e) => this.handleSubventionInterestValueChange(e, "MERCHANT")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionMInterestValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMInterestValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 9, offset: 3 }}>
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }}>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionProcessingFeesM"
                                                        id="PROCESSING_FEES"
                                                        // disabled={isFormDisabled || isSubventionProcessingFeesCheckDisabledM}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionProcessingFeesCheckChange(e, "MERCHANT")}
                                                        checked={formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }}>
                                                    <Form.Label style={{ textDecoration: "underline" }}>Processing Fees</Form.Label>
                                                </Form.Group>
                                            </Form.Row>

                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>

                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionMProcessingFeesFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionProcessingFeesFlatM"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedM}
                                                // checked={subventionProcessingFeesValueTypeFF === "FLAT"}
                                                checked={subventionProcessingFeesValueTypeM === "FULL" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "MERCHANT")}
                                            />
                                            {errors["subVentionMProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionMProcessingFeesPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionProcessingFeesPercentageM"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedM}
                                                checked={subventionProcessingFeesValueTypeM === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "MERCHANT")}
                                            />
                                            {errors["subVentionMProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionProcessingFeesValueM"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedM}
                                                value={this.handleValueSubventionProcessingFees("MERCHANT", "value")}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleSubventionProcessingFeesValueChange(e, "MERCHANT")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionMProcessingFeesValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionMProcessingFeesValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                {/* EP */}
                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="3" controlId="subventionEnterprisePartnerLabel">
                                            <Form.Label>Subvention - Enterprise Partner</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="9" controlId="subventionEnterprisePartner">
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }} controlId="subventionEnterprisePartnerLabel">
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionInterestEP"
                                                        id="subFFInterest"
                                                        // disabled={isFormDisabled || isSubventionInterestCheckDisabledEP}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionInterestCheckChange(e, "ENTERPRISE_PARTNER")}
                                                        checked={formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 ? "checked" : ""}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }} controlId="subventionEnterprisePartnerLabel">
                                                    <Form.Label style={{ textDecoration: "underline" }}>Interest</Form.Label>
                                                </Form.Group>
                                            </Form.Row>

                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>

                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }} >
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionEPInterestFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionInterestFullEP"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedEP}
                                                checked={subventionInterestValueTypeEP === "FULL" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "ENTERPRISE_PARTNER")}
                                            />
                                            {errors["subVentionEPInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }} >
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionEPInterestPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionInterestPercentageEP"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedEP}
                                                checked={subventionInterestValueTypeEP === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "ENTERPRISE_PARTNER")}
                                            />
                                            {errors["subVentionEPInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }} >
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionInterestValueEP"
                                                placeholder="Value"
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedEP}
                                                value={this.handleValueSubventionInterest("ENTERPRISE_PARTNER", "value")}
                                                onChange={(e) => this.handleSubventionInterestValueChange(e, "ENTERPRISE_PARTNER")}
                                                autoComplete="off"
                                            />
                                            {errors["subVentionEPInterestValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPInterestValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>

                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }}>
                                            <Form.Label inline="true">Borne Upto</Form.Label>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="borneUptoValue"
                                                placeholder="Days"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedEP}
                                                value={this.handleValueSubventionInterestBorneUpto()}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleSubventionInterestBorneUptoValueChange(e)}
                                                autoComplete="off"
                                                maxLength="100"

                                            />
                                            {errors["subVentionEPInterestBorneUptoError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPInterestBorneUptoError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Check
                                                inline
                                                type="checkbox"
                                                id="returnInCaseOfForeClosure"
                                                label="Return in case of Foreclosure"
                                                name="returnInCaseOfForeClosure"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedEP}
                                                checked={subventionInterestRICOF ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['returnInCaseOfForeClosure'] ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRICOFChange(e)}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 9, offset: 3 }}>
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }}>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionProcessingFeesEP"
                                                        id="PROCESSING_FEES"
                                                        // disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedEP}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionProcessingFeesCheckChange(e, "ENTERPRISE_PARTNER")}
                                                        checked={formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }}>
                                                    <Form.Label style={{ textDecoration: "underline" }}>Processing Fees</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionEPProcessingFeesFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionProcessingFeesFlatEP"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedEP}
                                                checked={subventionProcessingFeesValueTypeEP === "FULL" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "ENTERPRISE_PARTNER")}
                                            />
                                            {errors["subVentionEPProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionEPProcessingFeesPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionProcessingFeesPercentageEP"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedEP}
                                                checked={subventionProcessingFeesValueTypeEP === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "ENTERPRISE_PARTNER")}
                                            />
                                            {errors["subVentionEPProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionProcessingFeesValueEP"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedEP}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionProcessingFees("ENTERPRISE_PARTNER", "value")}
                                                onChange={(e) => this.handleSubventionProcessingFeesValueChange(e, "ENTERPRISE_PARTNER")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionEPProcessingFeesValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionEPProcessingFeesValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                {/* LP */}
                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="3" controlId="subventionLendingPartnerLabel">
                                            <Form.Label>Subvention - Lending Partner</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="9" controlId="subventionLendingPartner">
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }} controlId="subventionLendingPartnerLabel">
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionInterestLP"
                                                        id="subLPInterest"
                                                        // disabled={isFormDisabled || isSubventionInterestCheckDisabledLP}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionInterestCheckChange(e, "LENDING_PARTNER")}
                                                        checked={formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }} controlId="subventionLendingPartnerLabel">
                                                    <Form.Label style={{ textDecoration: "underline" }}>Interest</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionLPInterestFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionInterestFullLP"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedLP}
                                                checked={subventionInterestValueTypeLP === "FULL" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] > 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "LENDING_PARTNER")}
                                            />
                                            {errors["subVentionLPInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionLPInterestPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionInterestPercentageLP"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedLP}
                                                checked={subventionInterestValueTypeLP === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "LENDING_PARTNER")}
                                            />
                                            {errors["subVentionLPInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionInterestValueLP"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedLP}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionInterest("LENDING_PARTNER", "value")}
                                                onChange={(e) => this.handleSubventionInterestValueChange(e, "LENDING_PARTNER")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionLPInterestValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPInterestValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 9, offset: 3 }}>
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }}>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionProcessingFeesLP"
                                                        id="PROCESSING_FEES"
                                                        // disabled={isFormDisabled || isSubventionProcessingFeesCheckDisabledLP}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionProcessingFeesCheckChange(e, "LENDING_PARTNER")}
                                                        checked={formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }}>
                                                    <Form.Label style={{ textDecoration: "underline" }}>Processing Fees</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionLPProcessingFeesFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionProcessingFeesFlatLP"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedLP}
                                                checked={subventionProcessingFeesValueTypeLP === "FULL" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "LENDING_PARTNER")}
                                            />
                                            {errors["subVentionLPProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionLPProcessingFeesPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionProcessingFeesPercentageLP"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedLP}
                                                checked={subventionProcessingFeesValueTypeLP === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "LENDING_PARTNER")}
                                            />
                                            {errors["subVentionLPProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionProcessingFeesValueLP"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedLP}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionProcessingFees("LENDING_PARTNER", "value")}
                                                onChange={(e) => this.handleSubventionProcessingFeesValueChange(e, "LENDING_PARTNER")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionLPProcessingFeesValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionLPProcessingFeesValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>

                                </Form.Group>

                                {/* FF */}
                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="3" controlId="subventionFundfina">
                                            <Form.Label>Subvention - Fundfina</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="9" controlId="subventionFundfina">
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }} controlId="subventionFundfinaLabel">
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionInterestFF"
                                                        id="subFFInterest"
                                                        // disabled={isFormDisabled || isSubventionInterestCheckDisabledFF}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionInterestCheckChange(e, "FUNDFINA")}
                                                        checked={formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }} controlId="subventionFundfinaLabel">
                                                    <Form.Label style={{ textDecoration: "underline" }}>Interest</Form.Label>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>

                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionFFInterestFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionInterestFullFF"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedFF}
                                                checked={subventionInterestValueTypeFF === "FULL" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "FUNDFINA")}
                                            />
                                            {errors["subVentionFFInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionFFInterestPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionInterestPercentageFF"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedFF}
                                                checked={subventionInterestValueTypeFF === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] !== "" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionInterestRadioChange(e, "FUNDFINA")}
                                            />
                                            {errors["subVentionFFInterestTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFInterestTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionInterestValueFF"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionInterestCheckNotSelectedFF}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionInterest("FUNDFINA", "value")}
                                                onChange={(e) => this.handleSubventionInterestValueChange(e, "FUNDFINA")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionFFInterestValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFInterestValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 9, offset: 3 }}>
                                            <Form.Row style={{ flexWrap: "nowrap" }}>
                                                <Form.Group as={Col} md="1" sm="1" xs="1" style={{ textAlign: "right" }}>
                                                    <Form.Check
                                                        inline
                                                        type="checkbox"
                                                        name="SubVentionProcessingFeesFF"
                                                        id="PROCESSING_FEES"
                                                        // disabled={isFormDisabled || isSubventionProcessingFeesCheckDisabledFF}
                                                        disabled={isFormDisabled}
                                                        style={{ marginRight: "0" }}
                                                        onChange={(e) => this.handleSubventionProcessingFeesCheckChange(e, "FUNDFINA")}
                                                        checked={formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 ? true : false}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} md="10" sm="10" xs="10" style={{ marginLeft: "20px" }}>
                                                    <Form.Label style={{ textDecoration: "underline" }}>Processing Fees</Form.Label>
                                                </Form.Group>
                                            </Form.Row>

                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>


                                        <Form.Group as={Col} md={{ span: 2, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionFFProcessingFeesFULL"
                                                value="FULL"
                                                label="Full"
                                                name="subVentionProcessingFeesFlatFF"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedFF}
                                                // checked={subventionProcessingFeesValueTypeFF === "FLAT"}
                                                checked={subventionProcessingFeesValueTypeFF === "FULL" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] >= 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "FUNDFINA")}
                                            />
                                            {errors["subVentionFFProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 2 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="subVentionFFProcessingFeesPARTLY"
                                                label="Partly"
                                                value="PARTLY"
                                                name="subVentionProcessingFeesPercentageFF"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedFF}
                                                checked={subventionProcessingFeesValueTypeFF === "PARTLY" ? true : (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] !== "" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] < 100 ? true : false)}
                                                onChange={(e) => this.handleSubventionProcessingFeesRadioChange(e, "FUNDFINA")}
                                            />
                                            {errors["subVentionFFProcessingFeesTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFProcessingFeesTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="number"
                                                name="subVentionProcessingFeesValueFF"
                                                placeholder="Value"
                                                disabled={isFormDisabled || isSubventionProcessingFeesCheckNotSelectedFF}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                value={this.handleValueSubventionProcessingFees("FUNDFINA", "value")}
                                                onChange={(e) => this.handleSubventionProcessingFeesValueChange(e, "FUNDFINA")}
                                                autoComplete="off"
                                                maxLength="100"
                                            />
                                            {errors["subVentionFFProcessingFeesValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["subVentionFFProcessingFeesValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}
                                className="row">
                                {!(formData['type']) ? (<Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="collectionsLabel">
                                            <Form.Label>Collections</Form.Label>
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>) : null}

                                {formData['type'] && formData['type'] !== "CL" ? (<Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="collectionsLabel">
                                            <Form.Label>Collections</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" >
                                            <Form.Label>Start After (In Days)</Form.Label>
                                            <Form.Control
                                                type="number"
                                                name="collectionAfterDays"
                                                placeholder="Value"
                                                value={formData['loan'] && formData['loan']['collection'] && (formData['loan']['collection']['afterDays'] || formData['loan']['collection']['afterDays'] === 0) ? formData['loan']['collection']['afterDays'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleCollectionAfterDaysValue(e)}
                                                autoComplete="off"
                                                style={{ width: "auto" }}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["collectionAfterDaysValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["collectionAfterDaysValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="collectionsLabel" />
                                        <Form.Group as={Col} md="8" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <Form.Control
                                                as="select"
                                                name="collectionType"
                                                defaultValue={collectionTypeValue ? collectionTypeValue : ""}
                                                onChange={(e) => this.handleLoanCollectionFrequency(e)}
                                                style={{ width: "auto", marginBottom: "20px" }}
                                                disabled={isFormDisabled}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    Select Frequency
                                                </option>
                                                <option key="DAILY" value="DAILY">
                                                    Daily
                                                </option>
                                                <option key="WEEKLY" value="WEEKLY">
                                                    Weekly
                                                </option>
                                                <option key="MONTHLY" value="MONTHLY">
                                                    Monthly
                                                </option>
                                                <option key="ADHOC" value="ADHOC">
                                                    Adhoc
                                                </option>
                                            </Form.Control>
                                            {formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['frequency'] && formData['loan']['collection']['frequency']['valueType'] === "ADHOC" ? <Form.Control
                                                type="number"
                                                name="adhocDaysValue"
                                                placeholder="Days in case of Ad hoc"
                                                value={formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['frequency'] && formData['loan']['collection']['frequency']['valueType'] && formData['loan']['collection']['frequency']['valueType'] === "ADHOC" ? formData['loan']['collection']['frequency']['value'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleTermLoanCollectionAdhocValue(e)}
                                                autoComplete="off"
                                                style={{ width: "auto", marginBottom: "20px" }}
                                                disabled={isFormDisabled}
                                            /> : null}
                                        </Form.Group>
                                    </Form.Row>
                                    {(errors["collectionTypeError"] || errors["collectionAdhocValueError"]) && (<Form.Group as={Col} md="12">
                                        <Form.Row>
                                            <Form.Group as={Col} md={{ span: 4, offset: 4 }}  >
                                                {errors["collectionTypeError"] && (
                                                    <div className="alert alert-danger">
                                                        {errors["collectionTypeError"]}
                                                    </div>
                                                )}
                                            </Form.Group>
                                            <Form.Group as={Col} md={{ span: 4 }}  >
                                                {errors["collectionAdhocValueError"] && (
                                                    <div className="alert alert-danger">
                                                        {errors["collectionAdhocValueError"]}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Form.Row>
                                    </Form.Group>)}
                                </Form.Group>) : null}

                                {formData['type'] && formData['type'] !== "CL" ? (<Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 8, offset: 4 }} style={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap", boxSizing: "border-box" }}>
                                            {this.handleCollectionRadioOptionsInterestCheck() ? (<Form.Check

                                                inline
                                                type="checkbox"
                                                name="CollectionInterest"
                                                id="CollectionInterest"
                                                label="Interest"
                                                disabled={isFormDisabled}
                                                onChange={(e) => this.handleCollectionRulesChange(e)}
                                                checked={formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['collect'] && formData['loan']['collection']['collect']['interest'] ? "checked" : ""}
                                                style={{ marginRight: "100px", marginBottom: "20px" }}
                                            />) : null}
                                            {this.handleCollectionRadioOptionsPrincipalCheck() ? (<Form.Check

                                                inline
                                                type="checkbox"
                                                name="CollectionPrincipal"
                                                id="CollectionPrincipal"
                                                label="Principal"
                                                disabled={isFormDisabled}
                                                onChange={(e) => this.handleCollectionRulesChange(e)}
                                                checked={formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['collect'] && formData['loan']['collection']['collect']['principal'] ? "checked" : ""}
                                                style={{ marginRight: "100px", marginBottom: "20px" }}
                                            />) : null}
                                            {this.handleCollectionRadioOptionsPrincipalAtEndCheck() ? (<Form.Check

                                                inline
                                                type="checkbox"
                                                name="CollectionPrincipalAtEnd"
                                                id="CollectionPrincipalAtEnd"
                                                label="Principal at End of Tenure"
                                                disabled={isFormDisabled}
                                                onChange={(e) => this.handleCollectionRulesChange(e)}
                                                checked={formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['collect'] && formData['loan']['collection']['collect']['principalAtEnd'] ? "checked" : ""}
                                                style={{ marginRight: "100px", marginBottom: "20px" }}
                                            />) : null}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>) : null}

                                {formData['type'] && formData['type'] === "CL" ? (<Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="collectionsLabel">
                                            <Form.Label>Collections</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" >
                                            <Form.Label>Start After (In Days)</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="collectionAfterDays"
                                                placeholder="Value"
                                                value={formData['loan'] && formData['loan']['collection'] && (formData['loan']['collection']['afterDays'] || formData['loan']['collection']['afterDays'] === 0) ? formData['loan']['collection']['afterDays'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleCollectionAfterDaysValue(e)}
                                                autoComplete="off"
                                                style={{ width: "auto" }}
                                                disabled={isFormDisabled}
                                            />
                                            {errors["collectionAfterDaysValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["collectionAfterDaysValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Row>
                                        <Form.Group as={Col} md="4" />
                                        <Form.Group as={Col} md="3" >
                                            <Form.Control
                                                as="select"
                                                name="invoiceOrDrawDown"
                                                onChange={(e) => this.handleCreditLineCollectionRulesFromChange(e)}
                                                defaultValue={(formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['type']) ? (formData['loan']['collection']['type'].slice(0, 7) === "INVOICE" ? "I" : "D") : ""}
                                                sm="12"
                                                xs="12"
                                                disabled={isFormDisabled}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    Select Type
                                                </option>
                                                <option key="INVOICE" value="I">
                                                    Invoice(I)
                                                </option>
                                                <option key="DRAW_DOWN" value="D">
                                                    Draw Down(D)
                                                </option>
                                            </Form.Control>
                                            {errors["collectionTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["collectionTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="3" >
                                            <Form.Control
                                                as="select"
                                                name="installmentsOrNextID"
                                                onChange={(e) => this.handleCreditLineCollectionRulesToChange(e)}
                                                value={creditLineCollectionDropDownValue ? creditLineCollectionDropDownValue : ((formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['type'] && formData['loan']['collection']['type'].split("_").length > 1) ? (formData['loan']['collection']['type'].split("_")[formData['loan']['collection']['type'].split("_").length - 1] === "INSTALLMENTS" ? "IN" : "I/D") : "")}
                                                sm="12"
                                                xs="12"
                                                disabled={isFormDisabled || (isCreditLineCollectionTypeSelected && !(formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['type']))}
                                            >
                                                {" "}
                                                <option key="select" value="">
                                                    Select Type
                                                </option>
                                                <option key="INSTALLMENTS" value="IN">
                                                    Installments
                                                </option>
                                                <option key="NEXT_I/D" value="I/D">
                                                    Next I/D
                                                </option>
                                            </Form.Control>
                                            {errors["collectionTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["collectionTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md="2" >
                                            <Form.Control
                                                sm="12"
                                                xs="12"
                                                type="text"
                                                name="installments"
                                                placeholder="# of Installments"
                                                value={creditLineNoOfInstallments ? creditLineNoOfInstallments : ((formData['loan'] && formData['loan']['collection'] && (formData['loan']['collection']['installments'] || formData['loan']['collection']['installments'] === 0)) ? formData['loan']['collection']['installments'] : "")}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleCreditLineCollectionRulesValueToChange(e)}
                                                autoComplete="off"
                                                disabled={isFormDisabled || (isCreditLineCollectionTypeSelected && !(formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['installments']))}
                                            />
                                            {errors["collectionNoOfInstallmentsError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["collectionNoOfInstallmentsError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>

                                </Form.Group>) : null}

                                {formData['type'] && formData['type'] === "CL" ? (<Form.Group as={Col}
                                    md="12"
                                >
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 8 }} style={{ display: "flex", justifyContent: "flex-end" }} controlId="collections">
                                            <Form.Control
                                                style={{ width: "auto" }}
                                                type="text"
                                                name="maxDays"
                                                placeholder="Max. # of Days"
                                                value={creditLineMaxNoOfDays ? creditLineMaxNoOfDays : ((formData['loan'] && formData['loan']['collection'] && (formData['loan']['collection']['maxDays'] || formData['loan']['collection']['maxDays'] === 0)) ? formData['loan']['collection']['maxDays'] : "")}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleCreditLineCollectionRulesValueToChange(e)}
                                                autoComplete="off"
                                                disabled={isFormDisabled || (isCreditLineCollectionTypeSelected && !(formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['maxDays']))}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    {errors["collectionmaxDaysError"] && (
                                        <Form.Row>
                                            <Form.Group as={Col} md={{ span: 3, offset: 9 }}  >
                                                {errors["collectionmaxDaysError"] && (
                                                    <div className="alert alert-danger">
                                                        {errors["collectionmaxDaysError"]}
                                                    </div>
                                                )}
                                            </Form.Group>
                                        </Form.Row>)}
                                </Form.Group>) : null}

                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}
                            >
                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="latePaymentsLabel">
                                            <Form.Label>Late Payments</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="3">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="latePaymentFull"
                                                label="Flat"
                                                name="valueTypeLatePaymentFull"
                                                value="FLAT"
                                                onChange={(e) => this.handleLatePaymentRadioChange(e)}
                                                disabled={isFormDisabled}
                                                checked={formData['latePayment'] && formData['latePayment']['valueType'] && formData['latePayment']['valueType'] === 'FLAT' ? "checked" : ""}
                                            />
                                            {errors["latePaymentValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["latePaymentValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="3">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="valueTypeLatePaymentPercentage"
                                                label="Percentage"
                                                disabled={isFormDisabled}
                                                onChange={(e) => this.handleLatePaymentRadioChange(e)}
                                                name="valueTypeLP"
                                                value="PERCENTAGE"
                                                checked={formData['latePayment'] && formData['latePayment']['valueType'] && formData['latePayment']['valueType'] === 'PERCENTAGE' ? "checked" : ""}
                                            />
                                            {errors["latePaymentValueTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["latePaymentValueTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                        <Form.Group as={Col} md="2">
                                            <Form.Control
                                                type="number"
                                                name="latePaymentsValue"
                                                placeholder="Value"
                                                value={formData['latePayment'] && (formData['latePayment']['value'] || formData['latePayment']['value'] == 0) ? formData['latePayment']['value'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericValue(e)}
                                                onChange={(e) => this.handleLatePaymentValueChange(e)}
                                                autoComplete="off"
                                                // maxLength="100"
                                                // pattern="^(\d*\.)?\d+$"
                                                disabled={isFormDisabled || (formData['latePayment'] && formData['latePayment']['valueType'] ? false : true)}
                                            />
                                            {errors["latePaymentValueError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["latePaymentValueError"]}
                                                </div>
                                            )}
                                        </Form.Group>

                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }} style={{ display: "flex", justifyContent: "space-between" }} controlId="latePayments">
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="latePaymentPerInstallment"
                                                value="PER_INSTALLMENT"
                                                label="Per Installment"
                                                name="type1"
                                                disabled={isFormDisabled || (formData['latePayment'] && !formData['latePayment']['valueType'])}
                                                onChange={(e) => this.handleLatePaymentRadioValuesChange(e)}
                                                checked={(formData['latePayment'] && formData['latePayment']['installments'] && formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PER_INSTALLMENT") ? true : false}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }}>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="latePaymentPendingAmount"
                                                value="PENDING_AMOUNT"
                                                label="Pending Amount"
                                                name="type2"
                                                disabled={isFormDisabled || (formData['latePayment'] && !formData['latePayment']['valueType']) || (formData['latePayment'] && formData['latePayment']['valueType'] && formData['latePayment']['valueType'] === "FLAT")}
                                                onChange={(e) => this.handleLatePaymentRadioValuesChange(e)}
                                                checked={(formData['latePayment'] && formData['latePayment']['installments'] && formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PENDING_AMOUNT") ? true : false}
                                            />
                                            {errors["latePaymentInstallmentsTypeError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["latePaymentInstallmentsTypeError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                        <Form.Group as={Col} md={{ span: 4 }}>
                                            <Form.Check
                                                inline
                                                type="checkbox"
                                                id="PRINCIPAL"
                                                label="Principal"
                                                name="LatePaymentsPrincipal"
                                                disabled={isFormDisabled || (formData['latePayment'] && ((formData['latePayment']['valueType'] && formData['latePayment']['valueType'] === "FLAT") || (formData['latePayment']['installments'] && (!formData['latePayment']['installments']['type'] || (formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PER_INSTALLMENT")))))}
                                                onChange={(e) => this.handleLatePaymentCheckValuesChange(e)}
                                                checked={(formData['latePayment'] && formData['latePayment']['installments'] && formData['latePayment']['installments']['principal']) && !(formData['latePayment']['installments'] && formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PER_INSTALLMENT")}
                                            />
                                            <Form.Check
                                                inline
                                                type="checkbox"
                                                id="latePaymentInterest"
                                                label="Interest"
                                                name="LatePaymentsInterest"
                                                disabled={isFormDisabled || (formData['latePayment'] && ((formData['latePayment']['valueType'] && formData['latePayment']['valueType'] === "FLAT") || (formData['latePayment']['installments'] && (!formData['latePayment']['installments']['type'] || (formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PER_INSTALLMENT")))))}
                                                onChange={(e) => this.handleLatePaymentCheckValuesChange(e)}
                                                checked={(formData['latePayment'] && formData['latePayment']['installments'] && formData['latePayment']['installments']['interest']) && !(formData['latePayment']['installments'] && formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PER_INSTALLMENT")}
                                            />
                                            {errors["latePaymentCheckValuesError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["latePaymentCheckValuesError"]}
                                                </div>
                                            )}
                                        </Form.Group>


                                    </Form.Row>

                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md={{ span: 4, offset: 4 }} controlId="graceDays">
                                            <Form.Label>Grace Days</Form.Label>
                                            <Form.Control
                                                style={{ width: "65%" }}
                                                type="text"
                                                name="graceDays"
                                                placeholder="Value"
                                                value={(formData['latePayment'] && (formData['latePayment']['graceDays'] || formData['latePayment']['graceDays'] === 0)) ? formData['latePayment']['graceDays'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleLateValuesChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["graceDaysError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["graceDaysError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}
                                md="12"
                                style={{ borderColor: "lightgrey !important", border: "0.5px solid", borderRadius: "10px", margin: "10px 0px", padding: "15px 50px", boxSizing: "border-box" }}
                            >
                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="lateAfter">
                                            <Form.Label>Late After</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="lateAfterDays">
                                            <Form.Control
                                                type="text"
                                                name="lateAfter"
                                                placeholder="Days"
                                                value={(formData['latePayment'] && (formData['latePayment']['lateAfter'] || formData['latePayment']['lateAfter'] === 0)) ? formData['latePayment']['lateAfter'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleLateValuesChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["lateAfterError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["lateAfterError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="delinquentAfter">
                                            <Form.Label>Delinquent After</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="delinquentAfterDays">
                                            <Form.Control
                                                type="text"
                                                name="delinquentAfter"
                                                placeholder="Days"
                                                value={(formData['latePayment'] && (formData['latePayment']['delinquentAfter'] || formData['latePayment']['delinquentAfter'] === 0)) ? formData['latePayment']['delinquentAfter'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleLateValuesChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["delinquentAfterError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["delinquentAfterError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="defaultAfter">
                                            <Form.Label>Default After</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="defaultAfterDays">
                                            <Form.Control
                                                type="text"
                                                name="defaultAfter"
                                                placeholder="Days"
                                                value={(formData['latePayment'] && (formData['latePayment']['defaultAfter'] || formData['latePayment']['defaultAfter'] === 0)) ? formData['latePayment']['defaultAfter'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleLateValuesChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["defaultAfterError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["defaultAfterError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>

                                <Form.Group md="12">
                                    <Form.Row>
                                        <Form.Group as={Col} md="4" controlId="writeOffAfter">
                                            <Form.Label>WriteOff After</Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Col} md="4" controlId="writeOffAfterDays">
                                            <Form.Control
                                                type="text"
                                                name="writeOffAfter"
                                                placeholder="Days"
                                                value={(formData['latePayment'] && (formData['latePayment']['writeOffAfter'] || formData['latePayment']['writeOffAfter'] === 0)) ? formData['latePayment']['writeOffAfter'] : ""}
                                                onKeyDown={(e) => this.handleKeyPressNumericNoDecimalValue(e)}
                                                onChange={(e) => this.handleLateValuesChange(e)}
                                                autoComplete="off"
                                                maxLength="100"
                                                disabled={isFormDisabled}
                                            />
                                            {errors["writeOffAfterError"] && (
                                                <div className="alert alert-danger">
                                                    {errors["writeOffAfterError"]}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Form.Row>
                                </Form.Group>
                            </Form.Group>
                        </Form.Row>

                        {
                            errors["globalError"] && (
                                <div className="alert alert-danger">
                                    {errors["globalError"]}
                                </div>
                            )
                        }
                        {
                            this.state.submitError && (
                                <div className="alert alert-danger">
                                    {this.state.submitError}
                                </div>
                            )
                        }
                        <Button
                            className="ff-button"
                            type="submit"
                            disabled={isSaveDisabled}
                            style={{ marginRight: 10 }}
                            id="saveDraft"
                        >
                            Save Draft
                        </Button>
                        <Button
                            className="ff-button"
                            type="submit"
                            disabled={isSaveDisabled}
                            style={{ marginRight: 10 }}
                            id="save"
                        >
                            Save
                        </Button>
                    </Form >
                </div >}
            </React.Fragment>
        );
    }
}

export default ProductMaster;