import React, { Component, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import PrivateRoute from './route-wrapper';
import { ToastContainer } from "react-toastify";
import MegaNavBar from "./components/common/megaNavBar";
import Footer from "./components/common/footer";
import auth from "./services/common/authService";
import UserContext from "./context/userContext";
import Home from "../src/views/home/home";
import Team from "./views/companyInfo/team";
import Tnc from "./views/companyInfo/tnc";
import AboutUs from "./views/companyInfo/aboutus";
import PrivacyPolicy from "./views/companyInfo/privacyPolicy";
import FairPractices from "./views/companyInfo/fairPractices";
import ContactUs from "./views/companyInfo/contactus";
import BorrowForm from "./views/borrow/borrowForm";
import ApplyLoan from "./views/borrow/applyLoan";
import Partner from "./views/partner/partner";
import MyProfile from "./views/profile/myProfileForm";
import LoanTable from "./views/loan/loanTable";
import TeleCalling from "./views/teleCalling/loanTable";
import LoanInfoForm from "./views/loan/loanInfoForm";
import ForgotPasswordForm from "./views/login/forgotPasswordForm";
import Faq from "./views/companyInfo/faq";
import BorrowContent from "./views/companyInfo/borrowContent";
import PartnerRegistrationForm from "./views/partner/partnerRegistrationForm";
import UpdateAlternateEmail from "./views/profile/updateAlternateEmail";
import UpdateAlternateMobile from "./views/profile/updateAlternateMobile";
import InvestContent from "./views/companyInfo/investContent";
import UpdateMobileForm from "./views/profile/updateMobileV2";
import LoanDecisionForm from "./views/borrow/loanDecisionForm";
import UploadProfilePicForm from "./views/profile/uploadProfilePic";
import PartnerKycForm from "./views/partner/partnerKycForm";
import PersonalLoan from "./views/companyInfo/personalLoan";
import ScrutinyList from "./views/scrutiny/scrutinyList";
import ScrutinyInfo from "./views/scrutiny/scrutinyInfo";
import VerifyLoanContract from "./views/contract/verifyLoanContract";
import Msme from "./views/companyInfo/msme";

import NotFound from "./components/common/notFound";
import Login from "./views/login/login";
import RegisterForm from "./views/login/registerForm";
import Logout from "./views/login/logout";
import LeadInfo from "./views/partner/leadInfo";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ContractRedirect from "./views/contract/contractRedirect";
import DisbursalList from "./views/disbursal/disbursalList";
import DisbursalListV3 from "./views/disbursalV3/disbursalList";
import TrueScore from "./views/trueScore/trueScore";
import TrueScoreDetails from "./views/trueScore/trueScoreDetails";
import TrueScoreTransactions from "./views/trueScore/trueScoreTransactions";
import TrueScoreResult from "./views/trueScore/trueScoreResult";
import ViewSchedule from "./views/borrow/viewSchedule";
import MerchantAuthenticate from "./views/partner/merchantAuthenticate";
import InvalidData from "./views/partner/invalidData";
import RedirectInvalid from "./views/partner/redirectInvalid";
import UserListTable from "./views/userList/userListTable";
import PayInPayOutTable from "./views/payInPayOut/payInPayOutTable";
import EarlyWarningSystemTable from "./views/earlyWarningSystem/earlyWarningSystemTable";
import PayInPayOutInfo from "./views/payInPayOut/payInPayOutInfo";
import IdleTimerContainer from "./components/common/idleTimerContainer";
import PortfolioSummary from "./views/portfolio/portfolioSummary";
import Portfolio from "./views/portfolio/portfolio";
import PreApproval from './views/preApproval/preApproval';
import PayOut from './views/payOut/payOut';
import Tranche from './views/tranche/tranche';
import TrancheUpdate from './views/tranche/trancheUpdate';
import LendingPledge from './views/companyInfo/lendingPledge';
import Insights from './views/companyInfo/insights';
import TrancheMIS from './views/tranche/trancheMIS';
import NachRedirect from './views/eNach/nachRedirect';
import ExcelUpload from './views/upload/excelUpload';
import UpdateBankDetail from './views/profile/updateBankDetail';
import PartnerRedirect from './views/login/partnerRedirect';
// import withTracker from './components/common/withTracker';
// import Analytics from 'react-router-ga';
// import { analyticsTrackingCode } from './config.json';
import UpdateBankRedirect from './views/profile/updateBankRedirect';
import EmiCalculator from './components/common/emiCalculator';
// import VideoView from './views/test/VideoView';
// import WebCam from "./views/test/WebCam";
// import PhotoView from './views/test/PhotoView';
// import CameraView from './views/test/CameraView';
import ScrutinyInfoRedirect from './views/scrutiny/scrutinyInfoRedirect';
import LoanApplications from './views/loan/loanApplications';
import CollectionsQueueMain from './views/collectionsQueue/collectionsQueueMain';
import CreditLineRedirect from './views/partner/creditLineRedirect';
import CreditLineSummaryRedirect from './views/partner/creditLineSummaryRedirect';
import CreditLine from './views/partner/creditLine';
import CreditLineSummary from './views/partner/creditLineSummary';
import Notifications from './views/notification/notificaton';
import CollectionCaseNotifications from './views/notification/collection-notification';
import withClearCache from "./ClearCache";
import LenderAccountSummary from './views/lenderAccountSummary/lenderAccountSummary';
import DisbursalTransactions from './views/disbursalTransactions/disbursalTransactions';
import SharedCache from "./SharedCache";
import http from "./services/common/httpService";
import { apiUrl, encryptionKey } from "./config.json";
import DigilockerResponse from "./views/borrow/digilockerResponse";
import cibilReport from './views/reports/cibil';
import Digilocker from "./views/borrow/digilocker";
import ExcelFiles from "./views/excel-files/ExcelFiles";
import MandateInfo from "./views/borrow/mandateInfo";
import PartnerConfigurations from './views/partnerConfigurations/partnerConfigurations'
import PartnerConfigurationsDetails from './views/partnerConfigurations/partnerConfigurationsDetails'
import TeleCallingActionsForCollections from './views/collectionQueueTeleCalling/collectionsQueueTeleCalling'
import CollectionQueueTeleCallingDetails from './views/collectionQueueTeleCallingDetails/collectionQueueTeleCallingDetails'
import ImagePreview from "./components/common/image-preview";
import ProductMaster from "./views/productMaster/productMaster";
import ProductMasterForm from "./views/productMaster/productMasterForm";
import LoanInfo from './views/partner/loanOfferStp';
import BankStatements from './views/BankStatements/BankStatements'
import LenderSettlements from './views/lenderSettlements/lenderSettlements';
import RollbackTable from "./views/rollback/rollbackTable";
import Reconciliation from './views/reconciliation-wrapper/reconciliation-wrapper';
import SettlementMIS from './views/settlementMIS/settlementMIS'
import InvoiceDashboard from "./views/invoice/invoiceDashboard";
import InvoiceDetails from "./views/invoice/invoiceDetails";
import LoanTableFC from './views/foreclosure/loanTableFC';
import ManualQueue from './views/manualQueue/manualQueue';
import LenderPayout from './views/lenderPayout/lenderPayout';
import LenderPayoutList from './views/lenderPayoutList/lenderPayoutList'
import CreateInvoice from "./views/invoice/createInvoice";
import LenderPayoutListV2 from './views/lenderPayoutList-v2/lenderPayoutList-v2'
import AgentLoanCollection from "./views/agent/agent";
import AgentLoanReport from "./views/agent/agent-report";
import AgentAuthenticate from "./views/agent/agent-login";
// import LenderPayoutListV2 from './views/lenderPayoutList-v2/lenderPayoutList-v2'
import ReportsJobLit from './views/ReportsJobList/ReportsJobList';
import CollectionsOutcomeList from './views/collectionOutcome/collectionOutcome'
import CollectionsOutcomeDetailsList from './views/collectionOutcomeDetailsList/collectionOutcomeDetailsList'
import RedirectShopType from "./views/userCentricData/redirectShopType";
import ShopType from "./views/userCentricData/shopType";
import PersonalLoanDetails from "./views/personalLoanDetails/personalLoanDeatils";
import SuccessSubmissions from "./views/successSubmission/successSubmission";
import PersonalLoanTable from "./views/personalLoanDecision/personalLoanTable";
const apiEndpoint = apiUrl + "/ops/auth";

class MainApp extends Component {

  state = {}

  async componentDidMount() {

    await this.refresh();

    SharedCache.app = this;
  }


  async refresh() {

    let user = auth.getCurrentUser();
    let menu = [];
    if (user)
      menu = await auth.getMenu();

    let partner = auth.getCurrentPartner();

    if (
      window.location.pathname.toLowerCase() === "/home" &&
      user &&
      (user.loggedInFrom === "Mail" || user.loggedInFrom === "MerchantAuthenticate")
    ) {
      user = await auth.clearLoggedInFrom(user);
    }
    this.setState({ user, menu, partner });

  }

  onClickImagePreview = (src) => {
    this.setState({ isPreviewImage: true, previewImageUrl: src })
  }

  closePreview = () => {
    this.setState({ isPreviewImage: false })
  }

  handleAppState(key, value) {
    this.setState({
      [key]: value,
    });
  }
  render() {
  

    return (

      
      // <CacheBuster>
      //   {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      //     if (loading) return null;
      //     if (!loading && !isLatestVersion) {
      //       // You can decide how and when you want to force reload
      //       refreshCacheAndReload();
      //     }

      //     return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          menu: this.state.menu,
          partner: this.state.partner,
          hideFFLogo: this.state.hideFFLogo,
          app: this
        }}
      >
        <React.Fragment>
          <IdleTimerContainer />
          <ToastContainer />
          <div className="row">
            <div className="col-md-12">
              <MegaNavBar />
              <main className="container">
                <Switch>
                  {/* <Route path="/register/:id" component={RegisterForm} /> */}
                  <Route path="/register" component={RegisterForm} />
                  <Route path="/login" component={Login} />
                  <Route path="/personalAndMSMELoans/:partnerId/:merchantCode/:referrerId"  component={PersonalLoanDetails} />
                  <Route path="/personalAndMSMELoans/successfullSubmission" component={SuccessSubmissions} />

                  
                  <Route path="/:partner/login" component={Login} />
                  <PrivateRoute path="/logout" component={Logout} />
                  <Route path="/home" component={Home} />
                  <Route path="/team" component={Team} />
                  <Route path="/tnc" component={Tnc} />
                  <Route path="/aboutus" component={AboutUs} />
                  <Route path="/contactus" component={ContactUs} />
                  <Route path="/investContent" component={InvestContent} />
                  <Route path="/borrowContent" component={BorrowContent} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/privacyPolicy" component={PrivacyPolicy} />
                  <Route path="/fairPractices" component={FairPractices} />
                  <Route path="/borrow" component={BorrowForm} />
                  <PrivateRoute path="/applyLoan/:id" component={ApplyLoan} />
                  <PrivateRoute path="/loanOffer/:id" component={LoanDecisionForm} />
                  <PrivateRoute path="/applyLoan" component={ApplyLoan} />
                  <Route
                    path="/leadInfo/:merchantCode/:trxnId/:loanAmount/:token/:ffLogo?"
                    component={LeadInfo}
                  />
                  <Route
                    path="/leadOffer/:merchantCode/:trxnId/:loanAmount/:token/:ffLogo?"
                    component={LoanInfo}
                  />
                  <Route
                    path="/:id/merchantAuthenticate"
                    component={MerchantAuthenticate}
                  />
                  <Route path="/partner" component={Partner} />
                  <PrivateRoute path="/myprofile" component={MyProfile} />
                  <PrivateRoute path="/partnerKyc" component={PartnerKycForm} />
                  <Route path="/msme" component={Msme} />
                  <Route path="/personalLoan" component={PersonalLoan} />
                  <PrivateRoute path="/scrutinyInfo" component={ScrutinyInfo} />
                  <PrivateRoute path="/invoiceDashboard" component={InvoiceDashboard} />
                  <PrivateRoute path="/scrutinyInfoRedirect/:userId/:isPartnerUser?/:partnerId?/:merchantCode?" component={ScrutinyInfoRedirect} />
                  <PrivateRoute path="/scrutiny" component={ScrutinyList} />
                  <PrivateRoute path="/loanModule" component={LoanTable} />
                  <PrivateRoute path="/personalLoanDecision" component={PersonalLoanTable} />
                  <PrivateRoute path="/teleCallingCollections" component={TeleCallingActionsForCollections} />
                  <PrivateRoute path="/agent-collection" component={AgentLoanReport} />
                  <PrivateRoute path="/teleCallingCollectionsDetails/:id" component={CollectionQueueTeleCallingDetails} />
                  <PrivateRoute path="/teleCalling" component={TeleCalling} />
                  <PrivateRoute path="/reconciliation" component={Reconciliation} />
                  <PrivateRoute path="/settlementMis" component={SettlementMIS} />
                  <PrivateRoute path="/partnerConfigurations" component={PartnerConfigurations} />
                  <PrivateRoute path="/partnerConfigurationsDetails/:id" component={PartnerConfigurationsDetails} />
                  <PrivateRoute path="/loanInfo/:id/:isFrom" component={LoanInfoForm} />
                  <PrivateRoute path="/loanInfo/:id" component={LoanInfoForm} />
                  <PrivateRoute path="/manualqueue" component={ManualQueue} />
                  <PrivateRoute path="/lenderpayout" component={LenderPayout} />
                  <PrivateRoute path="/lenderpayoutlist" component={LenderPayoutListV2} />
                  <PrivateRoute path="/bankstatement" component={BankStatements} />
                  <PrivateRoute path="/reportsjoblist" component={ReportsJobLit} />
                  <PrivateRoute path="/collectionsOutcomeList" component={CollectionsOutcomeList} />
                  <PrivateRoute path="/collectionsOutcomeDetailsList" component={CollectionsOutcomeDetailsList} />
                  <PrivateRoute path="/lendersettlement" component={LenderSettlements} />
                  <PrivateRoute
                    path="/verifyLoanContract/:id"
                    component={VerifyLoanContract}
                  />
                  <Route
                    path="/regLink/:type/:id"
                    component={PartnerRedirect}
                  />
                  <PrivateRoute path="/viewSchedule/:id" component={ViewSchedule} />
                  <Route
                    path="/contractRedirect/:id"
                    component={ContractRedirect}
                  />
                  <PrivateRoute path="/disbursal" component={DisbursalList} />
                  <PrivateRoute path="/autoDisbursal" component={DisbursalListV3} />
                  <Route path="/trueScore" component={TrueScore} />
                  <PrivateRoute path="/userList" component={UserListTable} />
                  <PrivateRoute path="/payInPayOut" component={PayInPayOutTable} />
                  <PrivateRoute path="/payOut" component={PayOut} />
                  <PrivateRoute path="/tranche" component={Tranche} />
                  <PrivateRoute path="/trancheUpdate/:id" component={TrancheUpdate} />
                  <PrivateRoute path="/trancheMIS" component={TrancheMIS} />
                  <PrivateRoute
                    path="/portfolioSummary"
                    component={PortfolioSummary}
                  />
                  <PrivateRoute path="/portfolio" component={Portfolio} />
                  <PrivateRoute path="/loan-collection" component={AgentLoanCollection} />
                  <Route path="/agent-login" component={AgentAuthenticate} />
                  <PrivateRoute path="/loanApplications" component={LoanApplications} />
                  <PrivateRoute
                    path="/payInPayOutInfo/:id"
                    component={PayInPayOutInfo}
                  />
                  <PrivateRoute
                    path="/productMaster"
                    component={ProductMaster}
                  />
                  {/* temporary */}
                  <PrivateRoute
                    path="/productMasterForm"
                    component={ProductMasterForm}
                  />
                  <PrivateRoute
                    path="/earlyWarningSystem"
                    component={EarlyWarningSystemTable}
                  />
                  <PrivateRoute
                    path="/trueScoreDetails"
                    component={TrueScoreDetails}
                  />
                  <PrivateRoute
                    path="/trueScoreTransactions"
                    component={TrueScoreTransactions}
                  />
                  <PrivateRoute path="/trueScoreResult" component={TrueScoreResult} />

                  <PrivateRoute
                    path="/updateAltEmail"
                    component={UpdateAlternateEmail}
                  />
                  <PrivateRoute
                    path="/updateAltMobile"
                    component={UpdateAlternateMobile}
                  />
                  <PrivateRoute path="/updateMobile" component={UpdateMobileForm} />
                  <Route
                    path="/partnerRegistration"
                    component={PartnerRegistrationForm}
                  />
                  <PrivateRoute
                    path="/uploadProfilePic"
                    component={UploadProfilePicForm}
                  />
                  <Route
                    path="/forgotPassword"
                    component={ForgotPasswordForm}
                  />
                  <Route
                    path="/redirectInvalidData/:message"
                    component={RedirectInvalid}
                  />
                  <PrivateRoute
                    path="/preApproval"
                    component={PreApproval}
                  />
                  <PrivateRoute
                    path="/updateBankDetail/:loanId?"
                    component={UpdateBankDetail}
                  />
                  <Route
                    path="/updateBankRedirect/:id"
                    component={UpdateBankRedirect}
                  />
                  <Route
                    path="/creditLineRedirect/:id"
                    component={CreditLineRedirect}
                  />
                  <Route
                    path="/creditLineSummaryRedirect/:loanId/:shortauthKey/:agentName?/:agentNumber?"
                    component={CreditLineSummaryRedirect}
                  />
                  <PrivateRoute
                    path="/creditLine"
                    component={CreditLine}
                  />
                  <Route
                    path="/:id/creditLineSummary"
                    component={CreditLineSummary}
                  />
                  <PrivateRoute
                    path="/offerNotifications"
                    component={Notifications}
                  />
                  <PrivateRoute
                    path="/collectionCaseNotification"
                    component={CollectionCaseNotifications}
                  />

                  <PrivateRoute path="/lenderAccountSummary" component={LenderAccountSummary} />

                  <PrivateRoute path="/loanTransactionSummary" component={DisbursalTransactions} />

                  <PrivateRoute path="/mandateInfo/:id" component={MandateInfo} />

                  <PrivateRoute path="/rollback" component={RollbackTable} />

                  <PrivateRoute path="/invoiceDetails/:id" component={InvoiceDetails} />
                  <PrivateRoute path="/createInvoice" component={CreateInvoice} />

                  {/* <Route
                    path="/video"
                    component={VideoView}
                  />
                  <Route
                    path="/webcam"
                    component={WebCam}
                  />

                  <Route
                    path="/photo"
                    component={PhotoView}
                  />

                  <Route
                    path="/camera"
                    component={CameraView}
                  /> */}

                  {/* <Route
                      path="/shopDetails/:id"
                      component={ShopDetails}
                    /> */}

                  {/* <Route
                    path="/camera"
                    component={CameraView}
                  /> */}
                  <Route path="/:partnerId/register" component={RegisterForm} />
                  <Route path="/:partnerId/login" component={Login} />
                  <Route path="/calculator" component={EmiCalculator} />
                  <PrivateRoute path="/collectionsQueue" component={CollectionsQueueMain} />
                  <PrivateRoute path="/digilocker/:id/:force?" component={Digilocker} />
                  <Route path="/eNachResponse" component={NachRedirect} />
                  <Route path="/digilockerResponse" component={DigilockerResponse} />
                  <PrivateRoute path="/upload" component={ExcelUpload} />
                  <Route path="/insights" component={Insights} />
                  <Route path="/lendingPledge" component={LendingPledge} />
                  <Route path="/invalidData" component={InvalidData} />
                  <PrivateRoute path="/cibil" component={cibilReport} />
                  <PrivateRoute path="/truescore-files" component={ExcelFiles} />
                  <PrivateRoute path="/foreclosure" component={LoanTableFC} />
                  <Route path="/shopType/:merchantCode/:partnerCode" component={RedirectShopType} />
                  <Route path="/shopType" component={ShopType} />
                  <Route path="/not-found" component={NotFound} />
                  <Redirect from="/" exact to="/home" />
                  <Redirect to="/not-found" />

                </Switch>
              </main>
            </div>
          </div>

          <Footer />
        </React.Fragment>
        {this.state.isPreviewImage ? <ImagePreview show={this.state.isPreviewImage} src={this.state.previewImageUrl} close={this.closePreview} /> : ""}
      </UserContext.Provider>);
    //     }}
    //   </CacheBuster>
    // );
  }
}

function extractPaths() {

  let paths = window.location.pathname.split("/");
  let headers = {};


  if(paths[1] == "personalAndMSMELoans"){
    if(paths[2] == '667ace6846554b63d045aa26') {
      sessionStorage.setItem("loginPartner", 'Pay&Serve');
      headers = {
        partnerkey: '667ace6846554b63d045aa26',
        isencrypted: false
      }
    } else {
      sessionStorage.setItem("loginPartner", 'Master_Pay');
      headers = {
        partnerkey: '5f7d7c8d04b4af37bc1b5e55',
        isencrypted: false
      }
    }

    return {
      headers: headers,
      loggedInFrom: "RedirectAuthLink"
    }
  }
  if (paths.length == 3 && paths[2] == "merchantAuthenticate") {

    headers = {
      partnerkey: paths[1],
      isencrypted: false
    }

    return {
      headers: headers,
      loggedInFrom: "RedirectAuthLink"
    }
  }
  if (paths.length == 3 && paths[2] == "login") {

    headers = {
      partnerkey: paths[1],
      isencrypted: true
    }

    return {
      headers: headers,
      loggedInFrom: "RedirectAuthLink"
    }
  }

  if (paths.length == 3 && paths[2] == "register") {

    headers = {
      partnerkey: paths[1],
      isencrypted: true
    }

    return {
      headers: headers,
      loggedInFrom: "RedirectAuthLink"
    }
  }

  return {
    headers: {},
    loggedInFrom: ""
  };
}

const ClearCacheComponent = withClearCache(MainApp);

function App() {

  const [isLoading, handleLoading] = useState(true);
  let headers = extractPaths();
  http.get(apiEndpoint + "/userdata", { headers: headers.headers }).then((res) => {

    if (!res.data.user) {
      res.data['user'] = {
        loggedInFrom: headers.loggedInFrom
      }
    }

    SharedCache.init(res.data);
    handleLoading(false);

  }).catch(err => {

    handleLoading(false);
  });

  if (isLoading) return "";

  return <ClearCacheComponent />;
}

export default App;
