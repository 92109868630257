import React from "react";
import { Link, Redirect } from "react-router-dom";
import auth from "../../services/common/authService";
import { Form, Col, Button, Dropdown, SplitButton } from "react-bootstrap";
import moment from "moment";
import SpinnerComponent from "../../components/common/spinner";
import DatePicker from "react-datepicker2";
import Pagination from "../../components/common/paginationV2";
import { paginate } from "../../utils/paginate";
import NumberFormat from 'react-number-format';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import ReactExport from "react-export-excel";
import LoanTableModel from "../../models/loan/loanTableModel";
import { assignPersonalLoan } from "../../services/personalLoanDecision/personalLoanDecisionService";
import { toast } from "react-toastify";

class PersonalLoanTable extends LoanTableModel {

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      selectedLender: {},
      lenderAssignmentList: [ "L&T", "RESPO"]
    };
  }

  async componentDidMount() {
    if (!auth.getCurrentUser()) return;

    let { data } = this.state;

    const currentUser = auth.getCurrentUser();

    if (currentUser.role) this.setState({ evaluatorRole: currentUser.role });

    if (this.props.location.state) {
      data.merchantCode = this.props.location.state.merchantCode;

      this.setState({ data });
      await this.getPersonalLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
    }
    else {
      await this.getPersonalLoanList();
      await this.loadDropdowns();
      await this.getBorroingPartners();
    }
  }

  getPagedData = () => {
    const { pageSize, currentPage, data } = this.state;
    return { totalCount: data.loans.length, data: data.loans };
  };
  handleSelectALL = (e) => {
    var value = false;

    if (e.target.checked) {
      value = true;
    }

    this.setState({ selectAll: value, successMsg: "" });

    var checkboxes = document.querySelectorAll('input[name^="chkLoans"]');
    for (var checkbox of checkboxes) {

      checkbox.checked = value;
      const chkLoan = checkbox.name.split("_");
      const loanId = chkLoan[1];
      const selectedLoans = this.state.selectedLoans;
      if (!value && selectedLoans.includes(loanId, 0)) {
        const index = selectedLoans.indexOf(loanId);
        if (index != -1) {
          selectedLoans.splice(index, 1);
        }
      } else if (value && !selectedLoans.includes(loanId, 0)) {
        selectedLoans.push(loanId);
      }
      this.setState({ selectedLoans });
    }
  }
  renderCheckBox = (loan) => {

    let checkbox = (<></>);

    if (loan.status.toLowerCase() === "lender approval") {
      return (<Form.Check
        type="checkbox"
        name={"chkLoans_" + loan._id}
        label=""
        onChange={this.handleChecked(loan._id)}
        color="red"
      />)
    }

    return (<></>);
  }
  exportExcel = () => {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const { excelList } = this.state;

    return (
      <ExcelFile
        hideElement={true}
        filename={`LoanList_${moment(new Date()).format("DDMMYYYY")}`}
      >
        <ExcelSheet data={excelList} name="LoanList">
          <ExcelColumn
            label="Loan No."
            value="loanNo"
          ></ExcelColumn>
          <ExcelColumn
            label="Date"
            value={(col) =>
              col.updatedOn
                ? moment(col.updatedOn).format(
                  "DD/MM/yyyy"
                )
                : ""
            }
          ></ExcelColumn>
          <ExcelColumn
            label="Name"
            value={(col) =>
              col.firstName + " " + col.lastName
            }
          ></ExcelColumn>
          <ExcelColumn
            label="Amount"
            value="amount"
          ></ExcelColumn>
          <ExcelColumn
            label="Maturity"
            value="tenure"
          ></ExcelColumn>
          <ExcelColumn
            label="Frequency"
            value="repaymentFrequency"
          ></ExcelColumn>
          <ExcelColumn
            label="Loan Status"
            value="status"
          ></ExcelColumn>
          <ExcelColumn
            label="Post Disbursal Status"
            value="postDisbursalStatus"
          ></ExcelColumn>
          <ExcelColumn
            label="EMI Amount"
            value="emiAmount"
          ></ExcelColumn>
          <ExcelColumn
            label="Principal Outstanding"
            value={(col) =>
              col.principalAmount ? col.principalAmount.outstanding : ""
            }
          ></ExcelColumn>
          <ExcelColumn
            label="Interest Recovered"
            value={(col) =>
              col.interestAmount ? col.interestAmount.collected : ""
            }
          ></ExcelColumn>
        </ExcelSheet>
      </ExcelFile>
    )
  }

  async doSubmit() {
    this.resetPage()
    await this.getPersonalLoanList();
  };

  async lenderAssignment(event, loan) {
      const lenderName = event;
      const loanId = loan?._id;
      
      if(lenderName === "---Select---" || !loanId) {
        return;
      }

      try {
        // Assigning Personal Loan to the user's loanInfo
        let responseData = await assignPersonalLoan(loanId, lenderName);

        // Refetching the Loan list to show the updated Loan Information
        await this.getPersonalLoanList();

        if(responseData.isAxiosError) {
          const {data, status} = responseData.response;
          throw {data,status};
        }

        if(responseData?.response?.status == 201) {
          toast.success("Lender has successfully Assigned");
        }
        
      } catch(e) {
        if(e?.status == 409) {
          toast.error("Application already exists!");
        } else {
          toast.error("Oops something went wrong");
        }
        console.log(e);
      }
  }

  onPageChange = (page) => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    this.setState({ currentPage: page }, () => {
      this.getPersonalLoanList();
    })
  }

  onPagePrevious = () => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    if (this.state.currentPage <= 1)
      return

    this.setState({ currentPage: this.state.currentPage - 1 }, () => {
      this.getPersonalLoanList();
    })
  }

  onPageNext = () => {
    this.setState({ selectedLoans: [], selectAll: false, successMsg: "" });

    if (this.state.pageSize < this.state.currentPage)
      return

    this.setState({ currentPage: this.state.currentPage + 1 }, () => {
      this.getPersonalLoanList();
    })
  }

  render() {
    const {
      pageSize,
      currentPage,
      data,
      showSpinner,
      loanStatusList,
      partners,
      evaluatorRole,
      successMsg,
      errors,
      selectAll
    } = this.state;

    const { totalCount, data: loans } = this.getPagedData();

    if (!auth.getCurrentUser()) return <Redirect to="/login" />;
    return (
      <React.Fragment>
        <SpinnerComponent showSpinner={showSpinner} />
        <h2>{evaluatorRole === "Admin" ? "Personal Loan Decision" : ""} {evaluatorRole !== "Admin" ? "Personal Loan Decision" : ""}</h2>
        <Form onSubmit={this.handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="fromDate">
              <Form.Label>From Date</Form.Label>
              <DatePicker
                name="fromDate"
                id="fromDate"
                value={data["fromDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleFromDateChange(value)}
                placeholder="Enter From Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="toDate">
              <Form.Label>To Date</Form.Label>
              <DatePicker
                name="toDate"
                id="toDate"
                value={data["toDate"]}
                max={moment()}
                inputFormat="DD/MM/YYYY"
                isGregorian={true}
                className="form-control"
                inputReadOnly={true}
                showTodayButton={false}
                timePicker={false}
                onChange={(value) => this.handleToDateChange(value)}
                placeholder="Enter To Date"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="mobileNo">
              <Form.Label>Mobile No</Form.Label>
              <Form.Control
                type="text"
                name="mobileNo"
                value={data["mobileNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={data["name"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="loanNo">
              <Form.Label>Loan No.</Form.Label>
              <Form.Control
                type="text"
                name="loanNo"
                value={data["loanNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>{" "}
            <Form.Group as={Col} md="4" controlId="loanStatus">
              <Form.Label>Loan Status</Form.Label>
              <Form.Control
                as="select"
                name="loanStatus"
                value={data["loanStatus"]}
                onChange={this.handleLoanStatusChange}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                {loanStatusList.map((item, i) => (
                  <option key={i} value={item.defination}>
                    {item.defination}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="4" controlId="postDisbursalStatus">
              <Form.Label>Post Disbursal Status</Form.Label>
              <Form.Control
                as="select"
                name="postDisbursalStatus"
                value={data["postDisbursalStatus"]}
                onChange={this.handleChange}
                disabled={(data["loanStatus"] === "" || data["loanStatus"] === "Loan In Progress") ? "" : "disabled"}
              >
                {" "}
                <option key="select" value="">
                  ---Select---
                </option>
                <option key="Delinquent" value="Delinquent">
                  Delinquent
                </option>
                <option key="Default" value="Default">
                  Default
                </option>
                <option key="Written-Off" value="Written-Off">
                  Written-Off
                </option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="merchantCode">
              <Form.Label>Merchant Code</Form.Label>
              <Form.Control
                type="text"
                name="merchantCode"
                value={data["merchantCode"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="panNo">
              <Form.Label>PAN No</Form.Label>
              <Form.Control
                type="text"
                name="panNo"
                value={data["panNo"]}
                onChange={this.handleChange}
                autoComplete="off"
                maxLength="100"
              />
            </Form.Group>
            {evaluatorRole === "Admin" && (
              <Form.Group as={Col} md="4" controlId="partner">
                <Form.Label>Partner</Form.Label>
                <Form.Control
                  as="select"
                  name="partner"
                  value={data["partner"]}
                  onChange={this.handleChange}
                >
                  {" "}
                  <option key="all" value="all">
                    ALL
                  </option>
                  {partners && partners.map((partner) => (
                    <option key={partner.partnerName} value={partner.partnerName}>
                      {partner.displayName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>)}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Button
                className="ff-button"
                type="submit"
              >
                Apply Filters
              </Button>
              {evaluatorRole === "Lending Partner" && (
                <>
                  <Button
                    className="ff-button"
                    type="button"
                    style={{ marginLeft: 10 }}
                    onClick={() => this.handleLenderStateChange("Approved By Lender")}
                  >
                    Approve All
                  </Button>
                  <Button
                    className="ff-button"
                    type="button"
                    style={{ marginLeft: 10 }}
                    onClick={() => this.handleLenderStateChange("Rejected By Lender")}
                  >
                    Reject All
                  </Button>
                </>
              )}
            </Form.Group>
          </Form.Row>
        </Form>
        <br />
        {errors["globalError"] && (<div className="alert alert-danger">{errors["globalError"]}</div>)}
        {successMsg && <div className="alert alert-success">{successMsg}</div>}
        {evaluatorRole === "Lending Partner" ?
          <>
            <div style={{ width: "100%" }}>
              <Button
                className="ff-button"
                type="button"
                onClick={() => !this.state.showSpinner && this.downloadLoanList()}
                disabled={loans.length === 0}
              >
                Export to Excel
              </Button></div><br />
          </> : <></>
        }
        {this.state.downloadAll && this.exportExcel()}
        <div style={{ width: '100%' }}>
          <StickyTable stickyHeaderCount={0} rightStickyColumnCount={3} leftStickyColumnCount={evaluatorRole === "Lending Partner" ? 2 : 1}>
            <Row>
              {evaluatorRole === "Lending Partner" && (
                <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderTop: "1px solid" }}>
                  <Form.Check
                    type="checkbox"
                    label=""
                    onChange={this.handleSelectALL}
                    color="red"
                    checked={selectAll ? "checked" : ""}
                  />
                </Cell>)}
              <Cell style={{ borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan No.</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Date</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Name</b></Cell>
              <Cell style={{ minWidCell: 100, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>Amount</b></Cell>
              <Cell style={{ minWidCell: 80, textAlign: "right", borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid" }}><b>ROI</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Maturity</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Frequency</b></Cell>
              {
                evaluatorRole === "Admin" || evaluatorRole === 'Lending Partner' ? <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Download Docs</b></Cell>
                  : ""
              }
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Post Disbursal Status</b></Cell>
              {evaluatorRole === "Admin" && (<Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lender Error Msg</b></Cell>)}
              {evaluatorRole === "Admin" && (<Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Reason</b></Cell>)}
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>EMI Amount</b></Cell>
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Merchant Code</b></Cell>
              {evaluatorRole === "Admin" && (
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Offer Ref.</b></Cell>
              )}
              {evaluatorRole === "Admin" && (
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Application Link</b></Cell>
              )}
              {evaluatorRole === "Admin" && (
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Contract Link</b></Cell>
              )}
              {evaluatorRole === "Admin" && (
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Bank Link</b></Cell>
              )}
              {evaluatorRole === "Admin" && (
                <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Generate Contract</b></Cell>
              )}
              <Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Loan Status</b></Cell>
              {evaluatorRole === "Admin" && (<Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lender Assigned</b></Cell>)}
              {evaluatorRole === "Admin" && (<Cell style={{ borderColor: "lightgrey", borderRight: "1px solid", borderTop: "1px solid", textAlign: "center" }}><b>Lender Assignment</b></Cell>)}
            </Row>
            {loans &&
              loans.map((loan, i) => (
                <Row key={loan._id}>
                  {evaluatorRole === "Lending Partner" && (
                    <Cell style={{ borderColor: "lightgrey", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderLeft: "1px solid" }}>
                      {this.renderCheckBox(loan)}
                    </Cell>
                  )}
                  <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderLeft: "1px solid", borderRight: "1px solid" }}>{loan.loanNo}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.insertedOn && moment(loan.insertedOn).format("DD/MM/yyyy")}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.userInfo?.userDetails?.firstName ? loan.userInfo?.userDetails?.firstName : ""} {loan.userInfo?.userDetails?.lastName ? loan.userInfo?.userDetails?.lastName : ""}</Cell>
                  <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    <NumberFormat value={loan.amount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />
                  </Cell>
                  <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {" "}
                    {loan.interestRate}
                    {loan.interestRate && (
                      <i className="fa fa-percent" aria-hidden="true"></i>
                    )}
                  </Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {" "}
                    {loan.maturity &&
                      loan.repaymentFrequency === "Monthly" &&
                      `${loan.maturity} months`}
                    {loan.maturity &&
                      loan.repaymentFrequency === "Daily" &&
                      `${loan.maturity} days`}
                    {loan.maturity &&
                      loan.repaymentFrequency === "Weekly" &&
                      `${loan.maturity} weeks`}
                    {loan.maturity &&
                      loan.repaymentFrequency === "Forthnightly" &&
                      `${loan.maturity} forthnights`}
                    {loan.maturity &&
                      loan.repaymentFrequency === "Adhoc" &&
                      `${loan.tenure} days`}
                  </Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.repaymentFrequency}</Cell>
                  {
                    evaluatorRole === "Admin" || evaluatorRole === 'Lending Partner' ?
                      <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                        <Button
                          onClick={() => this.downloadAllDocuments(loan._id, loan?.loanNo)}
                          className="ff-button"
                          disabled={!this.checkAfterLenderAssignment(loan.status)}
                        >
                          {this.state.downloadAllLoanId != loan._id ? "Download" : "Downloading"}
                        </Button>
                      </Cell> : ""
                  }
                  
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {loan.postDisbursalStatus}
                  </Cell>
                  {
                    evaluatorRole === "Admin" && (
                      <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} > {(loan.lenderDetails && loan.lenderDetails.length > 0) ? loan.lenderDetails[0].errorMsg : ""}</Cell>
                    )
                  }
                  {
                    evaluatorRole === "Admin" && (
                      loan.status === "Rejected By Client" ? <Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} title={loan.rejectDescription ? loan.rejectDescription : loan.clientRemarks}> {loan.clientRemarks}</Cell> :
                        <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }} title={loan.rejectDescription ? loan.rejectDescription : loan.adminRemarks}> {loan.adminRemarks}</Cell>
                    )
                  }
                  < Cell style={{ textAlign: "right", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.emiAmount && <NumberFormat value={loan.emiAmount} displayType={'text'} thousandSeparator={true} thousandsGroupStyle="lakh" prefix={'₹'} />}</Cell>
                  <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.merchantCode}</Cell>
                  {evaluatorRole === "Admin" && (
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.preApprovals && loan.preApprovals.length > 0 && <Link to={{
                      pathname: `/preApproval`,
                      state: {
                        merchantCode: loan.merchantCode,
                        partnerId: loan.partnerId,
                        option: "preapproved",
                        transactionMonth: loan.preApprovals[0].transactionMonth,
                        transactionYear: loan.preApprovals[0].transactionYear
                      }
                    }}>Link</Link>}</Cell>)}
                  {evaluatorRole === "Admin" && (
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handleSendReview(loan._id)}
                        className="ff-button"
                        disabled={loan.kycStatus === "Review" ? false : true}
                      >
                        Resend
                      </Button>
                    </Cell>)}
                  {evaluatorRole === "Admin" && (
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handleSendContract(loan._id)}
                        className="ff-button"
                        disabled={(loan.status === "Loan Is Funding" || loan.status === "Review" || (loan.status === "Draft" && loan.isStp) || ((loan.status === "Loan Contract Accepted" || loan.status === "Recourse Pending") && loan.isForceMandate)) ? false : true}
                      >
                        Resend
                      </Button>
                    </Cell>)}
                  {evaluatorRole === "Admin" && (
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.handSendBankLink(loan._id)}
                        className="ff-button"
                        disabled={(loan.status === "Draft" || loan.status === "Under Review" || loan.status === "Approved By Admin" || loan.status === "Accepted By Client" || loan.status === "Loan Is Funding") &&
                          (loan.loanStepDetails && loan.loanStepDetails.find(x => x.Step === "Review" && x.isCompleted === true)) ? false : true}
                      >
                        Send
                      </Button>
                    </Cell>)}
                  {evaluatorRole === "Admin" && (
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                      <Button
                        onClick={() => this.regenerateLoanContract(loan.loanNo)}
                        className="ff-button"
                        disabled={(loan.status === "Lender Assigned" || loan.status === "Initiated Fund Transfer" || loan.status === "Ready For Disbursal" || loan.status === "Loan In Progress" || loan.status === "Written-Off") ? false : true}
                      >
                        Generate
                      </Button>
                    </Cell>)}
                    <Cell style={{ textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                    {loan.status}
                  </Cell>
                    {/* This is for lender assigned. */}
                    {<Cell style={{  textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>{loan.lenderDetails.length > 0  ? (loan.lenderDetails?.[0]?.lenderName || "Assigned"): null}</Cell>
                    }
                  {/* This is for Lender Assignment  */}
                  {evaluatorRole === "Admin" && <Cell style={{ display:'flex', gap: '5px', textAlign: "center", backgroundColor: i % 2 === 0 ? "#E8E8E8" : "", borderColor: "lightgrey", borderRight: "1px solid" }}>
                  <Form.Control
                  as="select"
                  name="lender"
                  disabled={!loan?._id}
                  value={this.state.selectedLender?.[loan._id] || "---Select---"}
                  onChange={(event) => {
                    event.preventDefault();
                    event.persist();
                    this.setState(pre => ({selectedLender: {...pre.selectedLender, [loan._id]: event.target?.value}}));
                  }}
                >
                    <option>---Select---</option>
                    {this.state.lenderAssignmentList.map(l => !loan.lenderDetails?.[0]?.lenderName?.includes(l) ? <option key={l} value={l}>{l}</option>: null).filter(Boolean)}
                  </Form.Control>
                  {this.state.selectedLender?.[loan._id] && this.state.selectedLender?.[loan._id] !== "---Select---" && <Button
                        onClick={() => this.lenderAssignment(this.state.selectedLender[loan._id], loan)}
                        className="ff-button" >
                        Submit
                  </Button>}
                  </Cell>}
                </Row>
              ))}
            {!loans && (
              <Row>
                <Cell Col={13}>No record found</Cell>
              </Row>
            )}

          </StickyTable>
        </div >
       <div style={{ width: "100%", overflowX: "scroll" }}>

          <Pagination
            currentPage={currentPage}
            pageSize={pageSize}
            onPageChange={this.onPageChange}
            onPagePrevious={this.onPagePrevious}
            onPageNext={this.onPageNext}
          />
         </div> 
      </React.Fragment >
    );
  }
}

export default PersonalLoanTable;
