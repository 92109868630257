import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import SharedCache from './SharedCache';
import { apiUrl, encryptionKey } from "./config.json";
import Login from './views/login/login';
import http from "./services/common/httpService";
import auth from "./services/common/authService";
import AuthenticateRoute from './auth-route';

const apiEndpoint = apiUrl + "/ops/auth";

const ignoreUrls = [
    "/scrutinyInfo",
    "/scrutinyInfoRedirect",
    "/teleCallingCollectionsDetails",
    "/partnerConfigurationsDetails",
    "/loanInfo",
    "/verifyLoanContract",
    "/viewSchedule",
    "/trancheUpdate",
    "/payInPayOutInfo",
    "/productMasterForm",
    "/trueScoreDetails",
    "/trueScoreTransactions",
    "/trueScoreResult",
    "/updateAltEmail",
    "/updateAltMobile",
    "/updateMobile",
    "/uploadProfilePic",
    "/updateBankDetail",
    "/creditLine",
    "/mandateInfo",
    "/digilocker",
    "/applyLoan",
    "/invoiceDetails",
    "/paymentLinks",
    "/manualqueue",
    "/lenderpayout",
    "/lenderpayoutlist",
    "/reportsjoblist",
    "/collectionsOutcomeList",
    "/collectionsOutcomeDetailsList"
]

let public_routes = [
    "/login",
    "/home"
];


let routesForRoles = [
    {
        "entitlement": "KYC_SCRUTINY",
        "route": "/scrutiny"
    },
    {
        "entitlement": "PRE_APPROVAL",
        "route": "/preApproval"
    },
    {
        "entitlement": "LOAN_DECISION",
        "route": "/loanModule"
    },
    {
        "entitlement": "PERSONAL_LOAN_DECISION",
        "route": "/personalLoanDecision"
    },
    {
        "entitlement": "TELE_CALLING",
        "route": "/teleCalling"
    },
    {
        "entitlement": "LOAN_DISBURSAL",
        "route": "/disbursal"
    },
    {
        "entitlement": "USER_LIST",
        "route": "/userList"
    },
    {
        "entitlement": "LOAN_AUTO_DISBURSAL",
        "route": "/autoDisbursal"
    },
    {
        "entitlement": "PAY_IN",
        "route": "/payInPayOut"
    },
    {
        "entitlement": "PAY_OUT",
        "route": "/payOut"
    },
    {
        "entitlement": "EARLY_WARRNING_SYSTEM",
        "route": "/earlyWarningSystem"
    },
    {
        "entitlement": "COLLECTION_QUEUE",
        "route": "/collectionsQueue"
    },
    {
        "entitlement": "PORTFOLIO_SUMMARY",
        "route": "/portfolioSummary"
    },
    {
        "entitlement": "TRANCHE",
        "route": "/tranche"
    },
    {
        "entitlement": "TRANCHWISE_COLLECTION_MIS",
        "route": "/trancheMIS"
    },
    {
        "entitlement": "UPLOAD_EXCEL",
        "route": "/upload"
    },
    {
        "entitlement": "OFFER_NOTIFICATION",
        "route": "/offerNotifications"
    },
    {
        "entitlement": "LENDER_ACCOUNT_SUMMARY",
        "route": "/lenderAccountSummary"
    },
    {
        "entitlement": "LOAN_TRANSACTION_SUMMARY",
        "route": "/loanTransactionSummary"
    },
    {
        "entitlement": "LOAN_DETAILS",
        "route": "/loanModule"
    },
    {
        "entitlement": "CIBIL",
        "route": "/cibil"
    },
    {
        "entitlement": "MY_PROFILE",
        "route": "/myprofile"
    },
    {
        "entitlement": "PORTFOLIO",
        "route": "/portfolio"
    },
    {
        "entitlement": "FAQ",
        "route": "/faq"
    },
    {
        "entitlement": "REPORTS",
        "route": ""
    },
    {
        "entitlement": "PARTNER_KYC",
        "route": "/partnerKyc"
    },
    {
        "entitlement": "PRIVACY_POLICY",
        "route": "/privacyPolicy"
    },
    {
        "entitlement": "SIGN_OUT",
        "route": "/logout"
    },
    {
        "entitlement": "LOAN_APPLICATIONS",
        "route": "/loanApplications"
    },
    {
        "entitlement": "PORTFOLIO",
        "route": "/portfolio"
    },
    {
        "entitlement": "TERMS_AND_CONDITIONS",
        "route": "/tnc"
    }
];

export default function RouteWrapper({
    component: Component,
    isPrivate,
    ...rest
}) {

    if (public_routes.indexOf(rest.path) != -1) {
        return <Route {...rest} component={Component} />;
    }

    let user = auth.getCurrentUser();
    // let entitlements = [];

    if (!user) {

        return <Route {...rest} render={(props) => {
            return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
        }} />
    }

    
    let menu = auth.getCurrentUserMenu();
    
    if (menu) {
        let isAllowed = menu.filter(r => r.path == rest.path)
        if (isAllowed && isAllowed.length) {
            return <Route {...rest} component={Component} />;
        } else {

            let currentPathSplit = rest.path.split('/');

            for (let i = 0; i < ignoreUrls.length; i++) {
                let urlSplit = ignoreUrls[i].split('/');
                let matchedCount = 0;
                for (let j = 0; j < urlSplit.length; j++) {
                    if (urlSplit[j] == currentPathSplit[j])
                        matchedCount += 1
                }

                if (matchedCount == urlSplit.length) {
                    return <Route {...rest} component={Component} />;
                }
            }

            window.location.href = '/'
        }
    } else {
        return <AuthenticateRoute {...rest} component={Component}></AuthenticateRoute>
    }

    // if (user.entitlements) {
    //     entitlements = routesForRoles.filter(x => {

    //         for (let i = 0; i < user.entitlements.length; i++) {

    //             if (user.entitlements[i].entitlement == x.entitlement)
    //                 return true;
    //         }
    //     });
    // }

    // let isAllowed = entitlements.filter(r => r.route == rest.path)

    // if (isAllowed.length > 0 || user.role != "Admin") { 

    // return <Route {...rest} component={Component} />;
    // }
}

RouteWrapper.propTypes = {

    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {

    isPrivate: false
}