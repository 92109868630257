import React from "react";
import * as productMasterService from "../../services/productMaster/productMasterService";
import auth from "../../services/common/authService";
import _ from "lodash";
import momentJalaali from "moment-jalaali";
import InputForm from "../../components/common/form";

class ProductMasterModel extends InputForm {
    state = {
        openForm: false,
        showSpinner: false,
        errors: {},
        successMsg: "",
        isSubmitted: false,
        searchField: "",
        isFormDisabled: true,
        isFormEdit: false,
        isSaveDisabled: false,
        isCloneButtonDisabled: false,
        isDeleteButtonDisabled: false,
        isEditButtonDisabled: false,
        isCloneProduct: false,
        hasName: false,
        subscriptionCheck: true,
        isProcessingFeeValueType: true,
        interestPrePostRadioCheck: "",
        interestComputationMethodValue: "",
        processingFeesRadioValue: "",
        // tenureValue: "",
        tenureObject: {
            minTenure: "",
            maxTenure: ""
        },
        isSubventionInterestCheckNotSelectedM: true,
        isSubventionInterestCheckNotSelectedEP: true,
        isSubventionInterestCheckNotSelectedLP: true,
        isSubventionInterestCheckNotSelectedFF: true,
        subventionInterestCheckFullEntity: "",
        isSubventionInterestCheckDisabledM: false,
        isSubventionInterestCheckDisabledEP: false,
        isSubventionInterestCheckDisabledLP: false,
        isSubventionInterestCheckDisabledFF: false,
        isSubventionProcessingFeesCheckNotSelectedEP: true,
        isSubventionProcessingFeesCheckNotSelectedLP: true,
        isSubventionProcessingFeesCheckNotSelectedFF: true,
        isSubventionProcessingFeesCheckNotSelectedM: true,
        subventionProcessingFeesCheckFullEntity: "",
        isSubventionProcessingFeesCheckDisabledM: false,
        isSubventionProcessingFeesCheckDisabledEP: false,
        isSubventionProcessingFeesCheckDisabledLP: false,
        isSubventionProcessingFeesCheckDisabledFF: false,
        isCreditLineCollectionTypeSelected: true,
        creditLineCollectionDropDownValue: "",
        creditLineNoOfInstallments: "",
        creditLineMaxNoOfDays: "",
        subventionInterestValueTypeM: "",
        subventionInterestValueTypeEP: "",
        subventionInterestValueTypeLP: "",
        subventionInterestValueTypeFF: "",
        subventionInterestRICOF: false,
        subventionProcessingFeesValueTypeM: "",
        subventionProcessingFeesValueTypeEP: "",
        subventionProcessingFeesValueTypeLP: "",
        subventionProcessingFeesValueTypeFF: "",
        collectionTypeValue: "",
        interestCollectionCheckbox: false,
        principalCollectionCheckbox: false,
        principalAtEndOfTenureCollectionCheckbox: false,
        formData: {
            type: "",
            category: "AMORTISING",
            latePayment: {
                installments: {
                }
            },
            kycRecaptureInDays: 365
        },
        lendingPartnerList: [],
        productMasterListValues: [],
        productMasterList: [],
        currentPage: 1,
        pageSize: 0,
    }

    async componentDidMount() {
        if (!auth.getCurrentUser()) return (window.location = "/login");

        const currentUser = auth.getCurrentUser();
        if (currentUser.role != "Admin") return (window.location = "/");

        await this.getProductMasterData();
    }

    async getProductMasterData() {

        this.setState({ showSpinner: true });
        let currentPage = this.state.currentPage;
        try {
            const productMasterListData = await productMasterService.getAllProductMasterProducts(currentPage);


            const lendingPartnerListData = await productMasterService.getLendingPartnerListProductMaster();


            if (productMasterListData.success && lendingPartnerListData.success) {
                this.mapDataToState(productMasterListData, lendingPartnerListData.data);
            }

            this.setState({ showSpinner: false });
        } catch (ex) {
            this.setState({ showSpinner: false });
        }
    }

    mapDataToState({ data: productMasterListData, totalPages }, lendingPartnerListData) {
        let pageSize = this.state.pageSize;
        let productMasterList = this.state.productMasterList;
        let productMasterListValues = this.state.productMasterListValues;
        let lendingPartnerList = this.state.lendingPartnerList;
        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;
        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;

        productMasterList = JSON.parse(JSON.stringify(productMasterListData));
        productMasterListValues = JSON.parse(JSON.stringify(productMasterListData));
        if (productMasterListData['subVention'] && productMasterListData['subVention']['recoverInterest'] && productMasterListData['subVention']['recoverInterest']['spread']) {
            for (const item of productMasterListData['subVention']['recoverInterest']['spread']) {
                if (item['entity'] === "ENTERPRISE_PARTNER") {
                    subventionInterestValueTypeEP = item['amount']['valueType']
                }
                else if (item['entity'] === "LENDING_PARTNER") {
                    subventionInterestValueTypeLP = item['amount']['valueType']
                }
                else if (item['entity'] === "FUNDFINA") {
                    subventionInterestValueTypeFF = item['amount']['valueType']
                }
            }
        }

        if (productMasterListData['subVention'] && productMasterListData['subVention']['processingFees'] && productMasterListData['subVention']['processingFees']['spread']) {
            for (const item of productMasterListData['subVention']['processingFees']['spread']) {
                if (item['entity'] === "ENTERPRISE_PARTNER") {
                    subventionProcessingFeesValueTypeEP = item['amount']['valueType']
                }
                else if (item['entity'] === "LENDING_PARTNER") {
                    subventionProcessingFeesValueTypeLP = item['amount']['valueType']
                }
                else if (item['entity'] === "FUNDFINA") {
                    subventionProcessingFeesValueTypeFF = item['amount']['valueType']
                }
            }
        }
        lendingPartnerList = [...lendingPartnerListData];
        // console.log("TOTAL PAGES")
        pageSize = totalPages;
        // console.log("updating list")

        this.setState({
            pageSize,
            productMasterList,
            productMasterListValues,
            lendingPartnerList,
            subventionInterestValueTypeEP,
            subventionInterestValueTypeLP,
            subventionInterestValueTypeFF,
            subventionProcessingFeesValueTypeEP,
            subventionProcessingFeesValueTypeLP,
            subventionProcessingFeesValueTypeFF
        });
    }

    handleSearchFieldChange({ currentTarget: input }) {

        let searchField = this.state.searchField;
        let errors = this.state.errors;

        searchField = input.value;
        errors["globalError"] = "";
        if (input.value === "") {
            this.setState({
                searchField,
                currentPage: 1,
                errors
            }, () => {
                this.getProductMasterData()
            });
        } else {
            this.setState({
                searchField
            });
        }
    }

    async handleProductSearchByName(e) {
        e.preventDefault();
        let searchField = this.state.searchField;
        let errors = this.state.errors;

        let searchResult = await productMasterService.searchProductMasterProductByName(searchField);

        if (searchResult.success) {
            errors["globalError"] = ""
            this.setState({
                errors,
                productMasterList: [searchResult.data],
                pageSize: 1,
                currentPage: 1
            });
        } else {
            errors["globalError"] = searchResult.message;
            this.setState({
                errors,
                productMasterList: [],
                pageSize: 1,
                currentPage: 1
            })
        }
    }

    onPageChange = async (page) => {
        let currentPage = this.state.currentPage;
        this.setState({
            currentPage: page
        }, () => {
            this.getProductMasterData();
        })
    };

    onPagePrevious = async () => {
        let currentPage = this.state.currentPage;
        this.setState({
            currentPage: currentPage - 1
        }, () => {
            this.getProductMasterData();
        })
    };

    onPageNext = async () => {
        let currentPage = this.state.currentPage;
        this.setState({
            currentPage: currentPage + 1
        }, () => {
            this.getProductMasterData();
        })

    };

    handleViewForm(product) {
        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;
        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let isSaveDisabled = this.state.isSaveDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let collectionTypeValue = this.state.collectionTypeValue;
        let hasName = this.state.hasName;
        let processingFeesRadioValue = this.state.processingFeesRadioValue;

        let productObj = JSON.parse(JSON.stringify(product));

        isDeleteButtonDisabled = false;

        if (productObj['name']) {
            hasName = true;
        }


        if (productObj['loan'] && productObj['loan']['processingFees'] && productObj['loan']['processingFees']['valueType'] && productObj['loan']['processingFees']['valueType']) {
            processingFeesRadioValue = productObj['loan']['processingFees']['valueType'];
        }

        if (productObj['hasLoans']) {
            isEditButtonDisabled = true;
            isDeleteButtonDisabled = true;
        }

        if (productObj['loan'] && productObj['loan']['collection'] && productObj['loan']['collection']['frequency'] && productObj['loan']['collection']['frequency']['valueType']) {
            collectionTypeValue = productObj['loan']['collection']['frequency']['valueType'];
        }

        if (!productObj['kycRecaptureInDays']) {
            productObj = {
                ...productObj,
                kycRecaptureInDays: 365
            }
        }

        let isFormDisabled = this.state.isFormDisabled;
        let openForm = this.state.openForm;
        let formData = this.state.formData;
        formData = JSON.parse(JSON.stringify(productObj));
        openForm = true;
        isFormDisabled = true;
        isCloneButtonDisabled = false;

        isSaveDisabled = true;

        this.handleCollectionRadioOptionsInterestCheck(product)
        this.handleCollectionRadioOptionsPrincipalCheck(product)
        this.handleCollectionRadioOptionsPrincipalAtEndCheck(product)

        this.setState({
            openForm,
            isFormDisabled,
            hasName,
            formData,
            processingFeesRadioValue,
            isCloneButtonDisabled,
            isDeleteButtonDisabled,
            isSaveDisabled,
            isEditButtonDisabled,
            collectionTypeValue
        })
    }

    handleCreateForm(e) {
        let formData = this.state.formData;
        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;

        isCloneButtonDisabled = true;
        isEditButtonDisabled = true;
        isDeleteButtonDisabled = true;


        let openForm = this.state.openForm;
        let isFormDisabled = this.state.isFormDisabled;
        openForm = !openForm;
        isFormDisabled = false;
        delete formData['type']

        this.setState({
            openForm,
            isFormDisabled,
            formData,
            isCloneButtonDisabled,
            isEditButtonDisabled,
            isDeleteButtonDisabled
        })
    }

    handleCloneForm(e, product) {

        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let isCloneProduct = this.state.isCloneProduct;
        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let isFormDisabled = this.state.isFormDisabled;
        let openForm = this.state.openForm;
        let subscriptionCheck = this.state.subscriptionCheck;
        let formData = JSON.parse(JSON.stringify(product));
        let hasName = this.state.hasName;

        formData['name'] = ""
        hasName = false;
        openForm = !openForm;
        isFormDisabled = false;
        isCloneButtonDisabled = true;
        isEditButtonDisabled = true;
        isDeleteButtonDisabled = true;
        isCloneProduct = true;

        if (product['subVention'] && product['subVention']['recoverInterest']) {
            let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
            let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
            let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
            for (const item of product['subVention']['recoverInterest']['spread']) {
                isSubventionInterestCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionInterestCheckNotSelectedEP;
                isSubventionInterestCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionInterestCheckNotSelectedLP;
                isSubventionInterestCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionInterestCheckNotSelectedFF;
            }

            this.setState({
                isSubventionInterestCheckNotSelectedEP,
                isSubventionInterestCheckNotSelectedLP,
                isSubventionInterestCheckNotSelectedFF
            })

        }

        if (product['subVention'] && product['subVention']['processingFees']) {
            let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
            let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
            let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
            for (const item of product['subVention']['processingFees']['spread']) {
                isSubventionProcessingFeesCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedEP;
                isSubventionProcessingFeesCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedLP;
                isSubventionProcessingFeesCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionProcessingFeesCheckNotSelectedFF;
            }

            this.setState({
                isSubventionProcessingFeesCheckNotSelectedEP,
                isSubventionProcessingFeesCheckNotSelectedLP,
                isSubventionProcessingFeesCheckNotSelectedFF
            })

        }

        if (formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['subscriptionTenure'] && formData['loan']['processingFees']['subscriptionTenure']['value']) {
            subscriptionCheck = false;
            this.setState({
                subscriptionCheck
            })
        }


        this.setState({
            openForm,
            isFormDisabled,
            hasName,
            formData,
            isCloneButtonDisabled,
            isEditButtonDisabled,
            isDeleteButtonDisabled,
            isCloneProduct
        })
    }

    handleCloneProductInForm(e) {
        let formData = this.state.formData;
        let isFormDisabled = this.state.isFormDisabled;
        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let isCloneProduct = this.state.isCloneProduct;
        let isSaveDisabled = this.state.isSaveDisabled;
        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let subscriptionCheck = this.state.subscriptionCheck;
        let hasName = this.state.hasName;

        isFormDisabled = false;
        isDeleteButtonDisabled = true;
        isEditButtonDisabled = true;
        isCloneProduct = true;
        isSaveDisabled = false;
        isCloneButtonDisabled = true;
        let formDataValues = JSON.parse(JSON.stringify(formData));
        formDataValues['name'] = "";
        hasName = false;
        formData = { ...formDataValues };
        console.log("clone form Data: ", formData);

        if (formData['subVention'] && formData['subVention']['recoverInterest']) {
            let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
            let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
            let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
            for (const item of formData['subVention']['recoverInterest']['spread']) {
                isSubventionInterestCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionInterestCheckNotSelectedEP;
                isSubventionInterestCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionInterestCheckNotSelectedLP;
                isSubventionInterestCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionInterestCheckNotSelectedFF;
            }

            this.setState({
                isSubventionInterestCheckNotSelectedEP,
                isSubventionInterestCheckNotSelectedLP,
                isSubventionInterestCheckNotSelectedFF
            })

        }

        if (formData['subVention'] && formData['subVention']['processingFees']) {
            let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
            let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
            let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
            for (const item of formData['subVention']['processingFees']['spread']) {
                isSubventionProcessingFeesCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedEP;
                isSubventionProcessingFeesCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedLP;
                isSubventionProcessingFeesCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionProcessingFeesCheckNotSelectedFF;
            }

            this.setState({
                isSubventionProcessingFeesCheckNotSelectedEP,
                isSubventionProcessingFeesCheckNotSelectedLP,
                isSubventionProcessingFeesCheckNotSelectedFF
            })

        }

        if (formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['subscriptionTenure'] && formData['loan']['processingFees']['subscriptionTenure']['value']) {
            subscriptionCheck = false;
            this.setState({
                subscriptionCheck
            })
        }

        this.setState({
            formData,
            hasName,
            isDeleteButtonDisabled,
            isEditButtonDisabled,
            isFormDisabled,
            isCloneProduct,
            isSaveDisabled,
            isCloneButtonDisabled
        });

    }

    handleEnableAndEditForm(e) {
        console.log("handleEnableAndEditForm")
        let isFormEdit = this.state.isFormEdit;
        let formData = this.state.formData;
        let hasName = this.state.hasName;

        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;
        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let isFormDisabled = this.state.isFormDisabled;
        let isSaveDisabled = this.state.isSaveDisabled;
        let isCreditLineCollectionTypeSelected = this.state.isCreditLineCollectionTypeSelected;
        // let isSubventionInterestCheckNotSelectedEP=this.state.isSubventionInterestCheckNotSelectedEP;
        // let isSubventionProcessingFeesCheckNotSelectedEP=this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        // let isSubventionProcessingFeesCheckNotSelectedLP=this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        // let isSubventionInterestCheckNotSelectedFF=this.state.isSubventionInterestCheckNotSelectedFF;
        // let isSubventionProcessingFeesCheckNotSelectedFF=this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let subscriptionCheck = this.state.subscriptionCheck;

        if (formData['name']) {
            hasName = true;
        }

        console.log("isSubventionInterestCheckNotSelectedLP: ", isSubventionInterestCheckNotSelectedLP);

        if (formData['subVention'] && formData['subVention']['recoverInterest']) {
            let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
            let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
            let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
            for (const item of formData['subVention']['recoverInterest']['spread']) {
                isSubventionInterestCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionInterestCheckNotSelectedEP;
                isSubventionInterestCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionInterestCheckNotSelectedLP;
                isSubventionInterestCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionInterestCheckNotSelectedFF;
            }

            this.setState({
                isSubventionInterestCheckNotSelectedEP,
                isSubventionInterestCheckNotSelectedLP,
                isSubventionInterestCheckNotSelectedFF
            })

        }

        if (formData['subVention'] && formData['subVention']['processingFees']) {
            console.log("INSIDE");
            let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
            let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
            let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
            for (const item of formData['subVention']['processingFees']['spread']) {
                isSubventionProcessingFeesCheckNotSelectedEP = item.entity === "ENTERPRISE_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedEP;
                isSubventionProcessingFeesCheckNotSelectedLP = item.entity === "LENDING_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedLP;
                isSubventionProcessingFeesCheckNotSelectedFF = item.entity === "FUNDFINA" ? false : isSubventionProcessingFeesCheckNotSelectedFF;
            }

            this.setState({
                isSubventionProcessingFeesCheckNotSelectedEP,
                isSubventionProcessingFeesCheckNotSelectedLP,
                isSubventionProcessingFeesCheckNotSelectedFF
            })

        }

        if (formData['loan'] && formData['loan']['processingFees'] && formData['loan']['processingFees']['subscriptionTenure'] && formData['loan']['processingFees']['subscriptionTenure']['value']) {
            subscriptionCheck = false;
            this.setState({
                subscriptionCheck
            })
        }

        isSaveDisabled = false;
        isFormDisabled = false;
        isCloneButtonDisabled = true;
        isEditButtonDisabled = true;
        isDeleteButtonDisabled = true;
        isFormEdit = true;
        isCreditLineCollectionTypeSelected = false;
        // isSubventionInterestCheckNotSelectedEP=false;
        // isSubventionProcessingFeesCheckNotSelectedEP=false;
        // isSubventionInterestCheckNotSelectedLP=false;
        // isSubventionProcessingFeesCheckNotSelectedLP=false;
        // isSubventionInterestCheckNotSelectedFF=false;
        // isSubventionProcessingFeesCheckNotSelectedFF=false;
        // subscriptionCheck=false;

        this.setState({
            isFormDisabled,
            isCloneButtonDisabled,
            isDeleteButtonDisabled,
            isEditButtonDisabled,
            isFormEdit,
            isSaveDisabled,
            isCreditLineCollectionTypeSelected,
            hasName
            // isSubventionInterestCheckNotSelectedEP,
            // isSubventionProcessingFeesCheckNotSelectedEP,
            // isSubventionInterestCheckNotSelectedLP,
            // isSubventionProcessingFeesCheckNotSelectedLP,
            // isSubventionInterestCheckNotSelectedFF,
            // isSubventionProcessingFeesCheckNotSelectedFF,
            // subscriptionCheck
        });
    }

    async handleDeleteProduct(e, productName) {
        console.log("productName: ", productName)

        // e.preventDefault();
        let errors = this.state.errors;

        let deleteResult = await productMasterService.deleteProductMasterProduct(productName);

        if (deleteResult.success) {
            let productMasterList = this.state.productMasterList;
            let currentPage = this.state.currentPage;
            errors["globalError"] = "";
            if (productMasterList.length === 1) {
                currentPage--;
            }

            this.setState({
                currentPage
            }, () => { this.getProductMasterData() });
        } else {
            errors["globalError"] = deleteResult.message;
            this.setState({
                errors
            })
        }

    }

    async handleDeleteProductInForm(e) {
        let formData = this.state.formData;
        let errors = this.state.errors;
        let openForm = this.state.openForm;

        let deleteResult = await productMasterService.deleteProductMasterProduct(formData.name);

        if (deleteResult.success) {
            let productMasterList = this.state.productMasterList;
            let currentPage = this.state.currentPage;
            errors["globalError"] = "";
            openForm = false;

            if (productMasterList.length === 1) {
                currentPage--;
            }

            this.setState({
                openForm,
                currentPage
            }, () => { this.getProductMasterData() });
        } else {
            errors["globalError"] = deleteResult.message;
            this.setState({
                errors
            })
        }
    }

    async handleProductSaveInForm(boolean) {

        this.state.submitError = "";
        let formData = this.state.formData;
        let isFormEdit = this.state.isFormEdit;
        formData = { ...formData, isActive: boolean };


        if (formData['type'] === "CL") {
            let collectionTypeValueArray = formData['loan']['collection']['type'].split(" ");
            if (collectionTypeValueArray[0] === "I") {
                if (collectionTypeValueArray[1] === "IN") {
                    formData['loan']['collection']['type'] = "INVOICE_TO_INSTALLMENTS";
                } else
                    if (collectionTypeValueArray[1] === "I/D") {
                        formData['loan']['collection']['type'] = "INVOICE_TO_INVOICE";
                    }
            } else
                if (collectionTypeValueArray[0] === "D") {
                    if (collectionTypeValueArray[1] === "IN") {
                        formData['loan']['collection']['type'] = "DRAW_DOWN_TO_INSTALLMENTS";
                    } else
                        if (collectionTypeValueArray[1] === "I/D") {
                            formData['loan']['collection']['type'] = "DRAW_DOWN_TO_DRAW_DOWN";
                        }
                }
        }


        if (isFormEdit) {
            await this.handleEditProduct(formData);
        } else {
            await this.handleCreateOrCloneProduct(formData);
        }

    }

    // async handleProductSaveInForm(e) {
    //     let formData = this.state.formData;
    //     let isFormEdit = this.state.isFormEdit;
    //     formData['isActive'] = true;

    //     if (isFormEdit) {
    //         await this.handleEditProduct(formData);
    //     } else {
    //         await this.handleCreateOrCloneProduct(formData);
    //     }
    // }

    async handleCreateOrCloneProduct(formData) {
        this.state.submitError = ""
        let errors = this.state.errors;
        let openForm = this.state.openForm;

        let { isFormDisabled, isFormEdit, isSaveDisabled, isCloneButtonDisabled, isDeleteButtonDisabled, isEditButtonDisabled, isCloneProduct, subscriptionCheck, interestPrePostRadioCheck, tenureObject, isSubventionInterestCheckNotSelectedEP, isSubventionInterestCheckNotSelectedLP, isSubventionInterestCheckNotSelectedFF, isSubventionProcessingFeesCheckNotSelectedEP, isSubventionProcessingFeesCheckNotSelectedLP, isSubventionProcessingFeesCheckNotSelectedFF, isCreditLineCollectionTypeSelected, creditLineCollectionDropDownValue, creditLineNoOfInstallments, creditLineMaxNoOfDays, subventionInterestValueTypeEP, subventionInterestValueTypeLP, subventionInterestValueTypeFF, subventionInterestRICOF, subventionProcessingFeesValueTypeEP, subventionProcessingFeesValueTypeLP, subventionProcessingFeesValueTypeFF } = this.state;

        console.log("isClone: ", isCloneProduct);

        console.log("handleCreateOrCloneProduct")
        if (isCloneProduct) {
            console.log("isClone");
            delete formData['_id'];
            delete formData['createdOn'];
            delete formData['createdBy'];
        }
        // errors["globalError"] = "error happened";
        // console.log("err", errors["globalError"])
        // this.setState({
        //     errors
        // })
        let createProductResponse = await productMasterService.createProductMasterProduct(formData);
        console.log("createProductResponse!", createProductResponse)

        isFormDisabled = true
        // isFormEdit = false
        isSaveDisabled = false
        isCloneButtonDisabled = false
        isDeleteButtonDisabled = false
        isEditButtonDisabled = false
        isCloneProduct = false
        subscriptionCheck = true
        interestPrePostRadioCheck = ""
        // tenureValue = ""
        tenureObject = {
            minTenure: "",
            maxTenure: ""
        }
        isSubventionInterestCheckNotSelectedEP = true
        isSubventionInterestCheckNotSelectedLP = true
        isSubventionInterestCheckNotSelectedFF = true
        isSubventionProcessingFeesCheckNotSelectedEP = true
        isSubventionProcessingFeesCheckNotSelectedLP = true
        isSubventionProcessingFeesCheckNotSelectedFF = true
        isCreditLineCollectionTypeSelected = true
        creditLineCollectionDropDownValue = ""
        creditLineNoOfInstallments = ""
        creditLineMaxNoOfDays = ""
        subventionInterestValueTypeEP = ""
        subventionInterestValueTypeLP = ""
        subventionInterestValueTypeFF = ""
        subventionInterestRICOF = false
        subventionProcessingFeesValueTypeEP = ""
        subventionProcessingFeesValueTypeLP = ""
        subventionProcessingFeesValueTypeFF = ""

        if (createProductResponse.success) {
            errors["globalError"] = "";
            openForm = false;
            isSaveDisabled = false;
            this.getProductMasterData();
            this.setState({
                errors, openForm,
                isFormDisabled, isFormEdit, isSaveDisabled, isCloneButtonDisabled, isDeleteButtonDisabled, isEditButtonDisabled, isCloneProduct, subscriptionCheck, interestPrePostRadioCheck, tenureObject, isSubventionInterestCheckNotSelectedEP, isSubventionInterestCheckNotSelectedLP, isSubventionInterestCheckNotSelectedFF, isSubventionProcessingFeesCheckNotSelectedEP, isSubventionProcessingFeesCheckNotSelectedLP, isSubventionProcessingFeesCheckNotSelectedFF, isCreditLineCollectionTypeSelected, creditLineCollectionDropDownValue, creditLineNoOfInstallments, creditLineMaxNoOfDays, subventionInterestValueTypeEP, subventionInterestValueTypeLP, subventionInterestValueTypeFF, subventionInterestRICOF, subventionProcessingFeesValueTypeEP, subventionProcessingFeesValueTypeLP, subventionProcessingFeesValueTypeFF
            });
        } else {
            errors["globalError"] = createProductResponse.message;
            console.log("err", errors["globalError"])
            this.setState({
                errors,
                submitError: createProductResponse.message
            })
            console.log("err", errors)
        }
    }

    // async handleCloneProduct(formData) {
    //     let errors = this.state.errors;
    //     let openForm = this.state.openForm;
    //     let isSaveDisabled = this.state.isSaveDisabled;

    //     console.log("handleCloneProduct")
    //     delete formData['_id'];
    //     let createProductResponse = await productMasterService.createProductMasterProduct(formData);
    //     console.log("createProductResponse", createProductResponse)

    //     if (createProductResponse.success) {
    //         errors["globalError"] = "";
    //         openForm = false;
    //         isSaveDisabled = false;
    //         this.getProductMasterData();
    //         this.setState({
    //             errors,
    //             openForm,
    //             isSaveDisabled
    //         });
    //     } else {
    //         errors["globalError"] = createProductResponse.message;
    //         this.setState({
    //             errors
    //         })
    //     }
    // }

    async handleEditProduct(formData) {
        let errors = this.state.errors;
        let openForm = this.state.openForm;
        let isSaveDisabled = this.state.isSaveDisabled;
        let isEditButtonDisabled = this.state.isEditButtonDisabled

        let editProductResponse = await productMasterService.editProductMasterProduct(formData);

        if (editProductResponse.success) {
            errors["globalError"] = "";
            openForm = false;
            isSaveDisabled = false;
            isEditButtonDisabled = false;
            this.getProductMasterData();
            this.setState({
                errors,
                openForm,
                isSaveDisabled,
                isEditButtonDisabled
            });
        } else {
            errors["globalError"] = editProductResponse.message;
            this.setState({
                errors,
                submitError: editProductResponse.message
            })
        }
    }

    handleFormFieldChange({ currentTarget: input }) {
        let formData = this.state.formData;

        formData[input.name] = input.value;
        this.setState({
            formData
        })
    }

    handleCategoryChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let errors = this.state.errors;
        let interestPrePostRadioCheck = this.state.interestPrePostRadioCheck;

        formData[input.name] = input.value;

        if (formData['loan'] && formData['loan']['collection']) {
            delete formData['loan']['collection'];
        }

        if (formData['loan'] && formData['loan']['recoverInterest'] && formData['loan']['recoverInterest']) {
            delete formData['loan']['recoverInterest'];
        }

        if (formData['tenure']) {
            delete formData['tenure'];
        }

        if (formData['type']) {
            delete formData['type'];
        }

        delete errors['collectionTypeError'];
        delete errors['collectionAdhocValueError'];
        delete errors['collectionNoOfInstallmentsError'];
        delete errors['globalError'];

        interestPrePostRadioCheck = "";



        this.setState({
            formData,
            interestPrePostRadioCheck,
            errors
        })
    }

    handleTypeChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let interestPrePostRadioCheck = this.state.interestPrePostRadioCheck;
        let creditLineNoOfInstallments = this.state.creditLineNoOfInstallments;
        let creditLineMaxNoOfDays = this.state.creditLineMaxNoOfDays;
        let tenureObject = this.state.tenureObject;
        let errors = this.state.errors;

        if (input.value === "") {
            delete formData['type'];
        } else {
            formData['type'] = input.value;
        }


        if (formData['loan'] && formData['loan']['collection']) {
            delete formData['loan']['collection'];
        }

        if (formData['loan'] && formData['loan']['recoverInterest']) {
            delete formData['loan']['recoverInterest'];
        }

        if (formData['loan'] && Object.keys(formData['loan']).length === 0) {
            delete formData['loan'];
        }

        if (formData['tenure']) {
            delete formData['tenure'];
        }

        delete errors['collectionTypeError'];
        delete errors['collectionAdhocValueError'];
        delete errors['collectionNoOfInstallmentsError'];
        delete errors['globalError'];

        interestPrePostRadioCheck = "";
        creditLineNoOfInstallments = "";
        creditLineMaxNoOfDays = "";
        tenureObject['maxTenure'] = "";
        tenureObject['minTenure'] = "";

        this.setState({
            formData,
            interestPrePostRadioCheck,
            creditLineNoOfInstallments,
            creditLineMaxNoOfDays,
            tenureObject,
            errors
        })
    }

    handleFormDateChange(value, keyString) {
        let formData = this.state.formData;
        formData[keyString] = new Date(value);

        this.setState({
            formData
        })
    }

    getTenureValue() {
        let formData = this.state.formData;
        if (formData['tenure'] && formData['tenure']['value']) {
            return formData['tenure']['value'];
        }
        else if (formData['loan'] && formData['loan']['recoverInterest'] && formData['loan']['recoverInterest']['tenure']) {
            return formData['loan']['recoverInterest']['tenure'];
        }
        return "";
    }

    handleTenureChange(event) {
        console.log("handleTenureChange!!")
        console.log("event: ", event.charCode);
        let { currentTarget: input } = event;
        let formData = this.state.formData;
        let errors = this.state.errors;
        // let tenureValue = this.state.tenureValue;
        let tenureObject = this.state.tenureObject;

        if (input.value === "") {
            // if (formData['type'] && formData['type'] === "CL") {
            //     delete formData['loan']['recoverInterest']['tenure'];

            //     if (Object.keys(formData['loan']['recoverInterest']).length === 0) {
            //         delete formData['loan']['recoverInterest'];
            //     }

            // } else {
            //     delete formData['tenure'];
            // }
            delete formData['tenure'][input.name];
            tenureObject[`${input.name}Tenure`] = "";
            if (formData['tenure'] && !formData['tenure']['min'] && !formData['tenure']['max']) {
                delete formData['tenure'];
            }
        }
        else if (input.value > 0) {

            // let tenure = {
            //     value: Number(input.value),
            //     valueType: "DAYS"
            // }

            delete errors[input.id];
            // if (formData['type'] === "CL" && (!formData['subVention'] || !formData['subVention']['recoverInterest'] || !formData['subVention']['recoverInterest']['spread'] || !formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 || !formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['tenure'] || !formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['tenure']['value'])) {
            //     let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            //         recoverInterest: {
            //             type: "INTEREST",
            //             tenure: {
            //                 value: "",
            //                 valueType: "DAYS"
            //             }
            //         }
            //     }

            //     // loan={
            //     //     ...recoverInterest,
            //     // }

            //     if (!("recoverInterest" in loan)) {
            //         loan = {
            //             ...loan, recoverInterest: {
            //                 type: "INTEREST",
            //                 tenure: {
            //                     value: "",
            //                     valueType: "DAYS"
            //                 }
            //             }
            //         }
            //     }
            //     if (!("tenure" in loan['recoverInterest'])) {
            //         loan['recoverInterest'] = {
            //             ...loan['recoverInterest'],
            //             tenure: {
            //                 value: "",
            //                 valueType: "DAYS"
            //             }
            //         }
            //     }

            //     loan['recoverInterest']['tenure'] = JSON.parse(JSON.stringify(tenure));
            //     formData = { ...formData, loan }
            // }
            if (formData['tenure'] && formData['tenure']['value']) {
                delete formData['tenure']['value'];
            }
            if (!("tenure" in formData)) {
                formData = {
                    ...formData,
                    tenure: {
                        valueType: "DAYS"
                    }
                }
            }
            console.log(formData['tenure'])
            formData['tenure'][input.name] = Number(input.value);
            tenureObject[`${input.name}Tenure`] = Number(input.value);
        }
        this.setState({
            errors,
            formData,
            tenureObject
        })
    }

    handleKeyPressNumericValue(event) {

        if ((event.keyCode > 31 && (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 190 || event.keyCode > 190))
        ) {
            // errors[event.currentTarget.id] = "Please enter value greater than or equal to 0";
            event.preventDefault();
        }

    };

    handleKeyPressNumericNoDecimalValue(event) {

        if (event.keyCode === 190
        ) {
            // errors[event.currentTarget.id] = "Please enter value greater than or equal to 0";
            event.preventDefault();
        }

    };

    handleLenderChange({ currentTarget: input }) {

        let formData = this.state.formData;
        if (input.value === "") {
            delete formData['lender'];
        } else {
            let lendingPartnerList = this.state.lendingPartnerList;

            let lenderId = lendingPartnerList.filter((lender) => lender['displayName'] === input.value)[0]['_id']
            let lender = {
                name: input.value,
                id: lenderId
            }
            formData['lender'] = lender;
        }
        this.setState({
            formData
        })
    }

    handleLoanRangeChange({ currentTarget: input }, keyString) {
        let formData = this.state.formData;
        // let errors = this.state.errors;

        if (input.value === "") {

            if (formData['loanAmountRange'][keyString]) {
                // tenureValue = ""
                delete formData['loanAmountRange'][keyString]
            }

            if (Object.keys(formData['loanAmountRange']).length === 0) {
                delete formData['loanAmountRange'];
            }
            // else if (input.value <= 0 && formData['loanAmountRange']) {
            //     delete formData['loanAmountRange']
            // }

            // this.setState({
            //     errors,
            //     formData,
            //     // tenureValue
            // })
        } else {
            let loanAmountRange = formData['loanAmountRange'] ? JSON.parse(JSON.stringify(formData['loanAmountRange'])) : {}
            // let loanAmountRange = {
            //     min: formData['loanAmountRange'] && formData['loanAmountRange']['min'] ? formData['loanAmountRange']['min'] : 0,
            //     max: formData['loanAmountRange'] && formData['loanAmountRange']['max'] ? formData['loanAmountRange']['max'] : 0
            // }
            loanAmountRange[keyString] = Number(input.value);
            formData = { ...formData, loanAmountRange }
            // if (keyString === 'max') {
            // } else {
            //     loanAmountRange['min'] = Number(input.value);
            //     formData = { ...formData, loanAmountRange }
            // }
        }
        this.setState({
            // errors,
            formData
        })
    }

    handleKycRecaptureInDaysChange({ currentTarget: input }) {
        let formData = this.state.formData;

        if (!formData['kycRecaptureInDays']) {
            formData = {
                ...formData,
                kycRecaptureInDays: ""
            }
        }

        if (input.value === "") {

            formData['kycRecaptureInDays'] = 365;
        } else if (input.value > 0) {

            formData['kycRecaptureInDays'] = Number(input.value);
        }
        this.setState({
            formData
        })
    }

    // handleLoanTypeChange({currentTarget:input}){
    //     console.log("input: ",input.name);

    //     let formData=this.state.formData;
    //     formData[input.name]=input.value;

    //     this.setState({
    //         formData
    //     })
    // }


    handleProcessingFeeRadioChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let isProcessingFeeValueType = this.state.isProcessingFeeValueType;

        console.log('handleProcessingFeeChange: ', input.value);



        let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            processingFees: {
                stage: "PRE",
                // charges: [
                //     {
                //         chargeType: "GST",
                //         valueType: "PERCENTAGE",
                //         value: 18
                //     },
                //     {
                //         chargeType: "STAMPING",
                //         valueType: "FIXED",
                //         value: 10
                //     }
                // ]
            }
        }
        let obj = {};
        console.log("handleProcessingFeeChange: ", input.value);
        obj["value"] = "";
        obj["valueType"] = input.value;
        // if (input.type === "number") {
        //     obj[input.name] = Number(input.value);
        // } else {
        // }
        loan['processingFees'] = { ...loan['processingFees'], ...obj }
        // input.value;
        formData = { ...formData, loan };


        this.setState({
            formData,
            // isProcessingFeeValueType
        })
    }

    handleProcessingFeeValueChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let isProcessingFeeValueType = this.state.isProcessingFeeValueType;

        console.log('handleProcessingFeeChange: ', input.value);

        if (input.value === "") {
            formData['loan']['processingFees']['value'] = "";
        } else {


            let loan = JSON.parse(JSON.stringify(formData['loan']))
            let obj = {};
            console.log("handleProcessingFeeChange: ", input.value);
            obj[input.name] = Number(input.value);
            // if (input.type === "number") {
            // } else {
            //     obj["value"] = "";
            //     obj[input.name] = input.value;
            // }
            loan['processingFees'] = { ...loan['processingFees'], ...obj }
            // input.value;
            formData = { ...formData, loan };
        }

        this.setState({
            formData,
            isProcessingFeeValueType
        })
    }

    handleInterestTypeRadioChange({ currentTarget: input }) {
        let formData = this.state.formData;

        let interest = formData['interest'] ? JSON.parse(JSON.stringify(formData['interest'])) : {
            charges: {
                value: "",
                valueType: ""
            }
        }

        if (!("charges" in interest)) {

            interest = {
                ...interest,
                charges: {
                    value: "",
                    valueType: ""
                }
            }

        }
        interest['charges']["value"] = ""
        interest['charges']["valueType"] = input.value;
        formData = {
            ...formData,
            interest
        };

        // interestComputationMethodValue = input.value;


        this.setState({
            formData,
            // isProcessingFeeValueType
        })
    }

    handleInterestTypeValueChange({ currentTarget: input }) {
        let formData = this.state.formData;

        console.log("handleInterestValueChange: ", input.value);

        if (input.value === "") {
            formData['interest']['charges']['value'] = "";
        } else {


            // let interest = JSON.parse(JSON.stringify(formData['interest']))
            // let obj = {};
            console.log("handleInterestValueChange: ", input.value);
            formData['interest']['charges']['value'] = Number(input.value);
            // if (input.type === "number") {
            // } else {
            //     obj["value"] = "";
            //     obj[input.name] = input.value;
            // }
            // loan['processingFees'] = { ...loan['processingFees'], ...obj }
            // input.value;
            // formData = { ...formData, loan };

            // obj[input.name] = Number(input.value);
            // if (input.type === "number") {
            // } else {
            //     obj["value"] = "";
            //     obj[input.name] = input.value;
            // }
            // interest['charges'] = { ...interest['charges'], ...obj }
            // input.value;
            // formData = { ...formData, interest };
        }

        this.setState({
            formData
        })
    }

    handleEnableSubscriptionChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let subscriptionCheck = this.state.subscriptionCheck;
        let formDataObj = JSON.parse(JSON.stringify(formData));

        if (input.checked) {
            console.log("formData: ", formData)
            if (formDataObj['loan']) {
                if (formDataObj['loan']['processingFees']) {
                    formDataObj['loan']['processingFees'] = {
                        ...formDataObj['loan']['processingFees'],
                        subscriptionTenure: {
                            valueType: "DAYS",
                            value: ""
                        }
                    }
                } else {
                    formDataObj['loan'] = {
                        ...formDataObj['loan'],
                        processingFees: {
                            subscriptionTenure: {
                                valueType: "DAYS",
                                value: ""
                            }
                        }
                    }
                }

            } else {
                formDataObj = {
                    ...formDataObj, loan: {
                        processingFees: {
                            subscriptionTenure: {
                                valueType: "DAYS",
                                value: ""
                            }
                        }
                    }
                }
            }
            subscriptionCheck = false;
        } else {
            delete formDataObj['loan']['processingFees']['subscriptionTenure'];
            subscriptionCheck = true;

            if (Object.keys(formDataObj['loan']['processingFees']).length === 0) {
                delete formDataObj['loan']['processingFees'];
            }

            if (Object.keys(formDataObj['loan']).length === 0) {
                delete formDataObj['loan'];
            }
        }

        formData = JSON.parse(JSON.stringify(formDataObj));

        this.setState({
            formData,
            subscriptionCheck
        })
    }

    handleSubscriptionValueChange({ currentTarget: input }) {
        let formData = this.state.formData;
        // formData['loan']['processingFees']['subscriptionTenure']['valueTYPE'] = "DAYS";
        formData['loan']['processingFees']['subscriptionTenure']['value'] = input.value > 0 ? Number(input.value) : "";
        this.setState({
            formData
        })
    }

    handleInterestValueChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let interestPrePostRadioCheck = this.state.interestPrePostRadioCheck;
        // let interestCollectionCheckbox = this.state.interestCollectionCheckbox;

        console.log('handleInterestValueChange: ', input.value);
        let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            recoverInterest: {
                stage: "",
                type: "INTEREST"
            }
        }

        if (!("recoverInterest" in loan)) {
            loan = {
                ...loan, recoverInterest: {
                    stage: "",
                    type: "INTEREST"
                }
            }
        }

        // if (formData['type'] === "CL") {
        //     loan['recoverInterest'] = {
        //         ...loan['recoverInterest'], tenure: {
        //             valueType: "DAYS",
        //             value: (formData['collection'] && formData['collection']['maxDays']) ? formData['collection']['maxDays'] : 0
        //         }
        //     }
        // }

        console.log('handleInterestValueChange: ', loan);
        loan['recoverInterest']['stage'] = input.value;
        interestPrePostRadioCheck = input.value;

        if (loan['collection'] && loan['collection']['collect']) {
            console.log("int1: ", (loan['collection'] && loan['collection']['collect']))
            loan['collection']['collect'] = {
                interest: false,
                principal: false,
                principalAtEnd: false
            }
        }

        formData = { ...formData, loan };

        // interestCollectionCheckbox = input.value === "POST";


        this.setState({
            formData,
            interestPrePostRadioCheck,
            // interestCollectionCheckbox
        })
    }

    handleInterestComputationMethodChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let interestComputationMethodValue = this.state.interestComputationMethodValue;

        let interest = formData['interest'] ? JSON.parse(JSON.stringify(formData['interest'])) : {
            type: ""
        }

        interest["type"] = input.value;
        formData = {
            ...formData,
            interest
        };
        interestComputationMethodValue = input.value;

        this.setState({
            formData,
            interestComputationMethodValue
        })

    }

    handleLoanCollectionFrequency({ currentTarget: input }) {
        let formData = this.state.formData;
        let collectionTypeValue = this.state.collectionTypeValue;

        let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            collection: {
                stage: "POST",
                frequency: {
                    valueType: "",
                    value: ""
                },
                collect: {
                    interest: false,
                    principal: false,
                    principalAtEnd: false
                }
            },
        }

        if (!("collection" in loan)) {
            let collection = {
                stage: "POST",
                frequency: {
                    valueType: "",
                    value: ""
                },
                collect: {
                    interest: false,
                    principal: false,
                    principalAtEnd: false
                }
            }
            loan = { ...loan, collection };
        }
        else if (("collection" in loan) && !("frequency" in loan['collection'])) {
            let frequencyObj = {
                stage: "POST",
                frequency: {
                    valueType: "",
                    value: ""
                },
                collect: {
                    interest: false,
                    principal: false,
                    principalAtEnd: false
                }
            }
            loan['collection'] = { ...loan['collection'], ...frequencyObj };
        }


        loan["collection"]["frequency"]["valueType"] = input.value;
        collectionTypeValue = input.value;

        // if(formData['category']==="AMORTISING"){
        //     loan["collection"]['collect']
        // }

        console.log("loan Collections: ", loan);

        switch (input.value) {
            case "DAILY":
                loan["collection"]["frequency"]["value"] = 1;
                break;

            case "WEEKLY":
                loan["collection"]["frequency"]["value"] = 7;
                break;

            case "MONTHLY":
                loan["collection"]["frequency"]["value"] = 30;
                break;

            case "ADHOC":
                loan["collection"]["frequency"]["value"] = "";
                break;

            default:
                // delete loan["collection"]["frequency"];
                delete loan["collection"];
                console.log("loan: ", loan)
                // if (Object.keys(loan["collection"]).length === 0) {
                //     delete loan["collection"];
                // }
                break;
        }

        if (Object.keys(loan).length !== 0) {
            formData = { ...formData, loan };
        }
        else {
            delete formData['loan'];
        }

        this.setState({
            formData,
            collectionTypeValue
        })

    }

    handleTermLoanCollectionAdhocValue({ currentTarget: input }) {
        let formData = this.state.formData;

        formData['loan']['collection']['frequency']['value'] = Number(input.value);

        this.setState({
            formData
        })
    }

    handleCollectionRadioOptionsInterestCheck(product) {
        let formData = this.state.formData;
        // let interestCollectionCheckbox = this.state.interestCollectionCheckbox;

        return formData['loan'] && formData['loan']['recoverInterest'] && formData['loan']['recoverInterest']['stage'] === "POST";

        // interestCollectionCheckbox = product['loan'] && product['loan']['recoverInterest'] && product['loan']['recoverInterest']['stage'] === "POST";
        // this.setState({
        //     interestCollectionCheckbox
        // })
    }

    handleCollectionRadioOptionsPrincipalCheck(product) {
        let formData = this.state.formData;
        // let principalCollectionCheckbox = this.state.principalCollectionCheckbox;

        // principalCollectionCheckbox = product['category'] === "AMORTISING";
        // this.setState({
        //     principalCollectionCheckbox
        // })

        return formData['category'] === "AMORTISING";
    }

    handleCollectionRadioOptionsPrincipalAtEndCheck(product) {
        let formData = this.state.formData;
        // let principalAtEndOfTenureCollectionCheckbox = this.state.principalAtEndOfTenureCollectionCheckbox;

        // principalAtEndOfTenureCollectionCheckbox = product['category'] === "NON_AMORTISING";
        // this.setState({
        //     principalAtEndOfTenureCollectionCheckbox
        // })
        return formData['category'] === "NON_AMORTISING";
    }

    handleCollectionRulesChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            collection: {
                collect: {
                    interest: false,
                    principal: false,
                    principalAtEnd: false
                }
            },
        }

        if (!("collection" in loan)) {
            let collection = {
                collect: {
                    interest: false,
                    principal: false,
                    principalAtEnd: false
                }
            }
            loan = { ...loan, collection };
        }
        else if (("collection" in loan) && !("collect" in loan['collection'])) {
            let collect = {
                interest: false,
                principal: false,
                principalAtEnd: false
            }
            loan['collection'] = { ...loan['collection'], collect };
        }

        switch (input.name) {
            case "CollectionInterest":
                input.name = "interest";
                break;

            case "CollectionPrincipal":
                input.name = "principal";
                break;

            case "CollectionPrincipalAtEnd":
                input.name = "principalAtEnd";
                break;

        }

        loan['collection']['collect'][input.name] = input.checked;

        if (Object.keys(loan).length !== 0) {
            formData = { ...formData, loan };
        }
        else {
            delete formData['loan'];
        }

        this.setState({
            formData
        })
    }

    handleCreditLineCollectionRulesFromChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let isCreditLineCollectionTypeSelected = this.state.isCreditLineCollectionTypeSelected;
        let creditLineCollectionDropDownValue = this.state.creditLineCollectionDropDownValue;
        let creditLineNoOfInstallments = this.state.creditLineNoOfInstallments;
        let creditLineMaxNoOfDays = this.state.creditLineMaxNoOfDays;

        let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
            collection: {
                type: "",
                maxDays: -1,
                installments: -1
            },
        }

        if (!("collection" in loan)) {
            let collection = {
                type: "",
                maxDays: -1,
                installments: -1
            }

            loan = { ...loan, collection };
        }

        if (input.value === "") {
            creditLineNoOfInstallments = "";
            creditLineMaxNoOfDays = "";
            creditLineCollectionDropDownValue = "";
            delete loan['collection'];
            isCreditLineCollectionTypeSelected = true;
        } else {
            loan['collection']['type'] = `${input.value}`;
            loan['collection']['maxDays'] = "";
            loan['collection']['installments'] = "";
            creditLineCollectionDropDownValue = "";
            creditLineNoOfInstallments = "";
            creditLineMaxNoOfDays = "";
            isCreditLineCollectionTypeSelected = false;
        }

        if (Object.keys(loan).length !== 0) {
            formData = { ...formData, loan };
        }
        else {
            delete formData['loan'];
        }

        this.setState({
            isCreditLineCollectionTypeSelected,
            creditLineCollectionDropDownValue,
            creditLineNoOfInstallments,
            creditLineMaxNoOfDays,
            formData
        })
    }

    handleCreditLineCollectionRulesToChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let creditLineCollectionDropDownValue = this.state.creditLineCollectionDropDownValue;


        let loan = JSON.parse(JSON.stringify(formData['loan']))



        if (input.value === "") {
            loan['collection']['type'] = `${loan['collection']['type'].charAt(0)}`;
        } else {

            loan['collection']['type'] = `${loan['collection']['type'].charAt(0)} ${input.value}`;
        }

        creditLineCollectionDropDownValue = input.value;

        formData = { ...formData, loan };

        this.setState({
            // isCreditLineCollectionTypeSelected,
            creditLineCollectionDropDownValue,
            formData
        })
    }


    handleCreditLineCollectionRulesValueToChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let creditLineNoOfInstallments = this.state.creditLineNoOfInstallments;
        let creditLineMaxNoOfDays = this.state.creditLineMaxNoOfDays;

        formData['loan']['collection'][input.name] = Number(input.value);

        if (input.name === "installments") {
            creditLineNoOfInstallments = Number(input.value);
        } else {
            creditLineMaxNoOfDays = Number(input.value);
        }

        this.setState({
            creditLineNoOfInstallments,
            creditLineMaxNoOfDays,
            formData
        })
    }

    handleCollectionAfterDaysValue({ currentTarget: input }) {
        let formData = this.state.formData;

        if (input.value === "") {
            delete formData['loan']['collection']['afterDays'];

            if (formData['loan'] && formData['loan']['collection'] && Object.keys(formData['loan']['collection']).length === 0) {
                delete formData['loan']['collection'];
            }

            if (formData['loan'] && Object.keys(formData['loan']).length === 0) {
                delete formData['loan'];
            }
        } else if (input.value >= 0) {

            let collection = formData['loan'] && formData['loan']['collection'] ? JSON.parse(JSON.stringify(formData['loan']['collection'])) : {
                afterDays: ""
            }
            if (!("afterDays" in collection)) {
                collection = {
                    ...collection,
                    afterDays: ""
                }
            }


            collection['afterDays'] = Number(input['value']);

            if (formData['loan']) {
                formData['loan'] = {
                    ...formData['loan'],
                    collection
                }
            } else {

                formData = { ...formData, loan: { collection } };
            }

        }

        this.setState({
            formData
        })
    }

    handleCheckSubventionInterest(entity, name, value) {
        let formData = this.state.formData;

        if (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread']) {
            let spreadArray = formData['subVention']['recoverInterest']['spread'];
            return spreadArray.filter((item) => item['entity'] === entity && item['amount'][name] === value).length > 0
        } else {
            return false;
        }
    }

    handleValueSubventionInterest(entity, name) {
        let formData = this.state.formData;


        if (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread']) {

            let spreadArray = formData['subVention']['recoverInterest']['spread'];
            let spreadItem = spreadArray.filter((item) => item['entity'] === entity);

            return spreadItem.length > 0 ? spreadItem[0]['amount'][name] : ""
        } else {
            return "";
        }
    }

    handleSubventionInterestCheckChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;
        let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
        let isSubventionInterestCheckNotSelectedM = this.state.isSubventionInterestCheckNotSelectedM;

        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;
        let subventionInterestValueTypeM = this.state.subventionInterestValueTypeM;

        let isSubventionInterestCheckDisabledEP = this.state.isSubventionInterestCheckDisabledEP;
        let isSubventionInterestCheckDisabledLP = this.state.isSubventionInterestCheckDisabledLP;
        let isSubventionInterestCheckDisabledFF = this.state.isSubventionInterestCheckDisabledFF;
        let isSubventionInterestCheckDisabledM = this.state.isSubventionInterestCheckDisabledM;

        let subventionInterestCheckFullEntity = this.state.subventionInterestCheckFullEntity;


        if (input.checked) {

            let subVention = formData['subVention'] ?
                JSON.parse(JSON.stringify(formData['subVention'])) :
                {
                    recoverInterest: {
                        spreadType: "INTEREST_AMOUNT",
                        spread: []
                    }
                }

            if (!("recoverInterest" in subVention)) {
                let recoverInterest = {
                    spreadType: "INTEREST_AMOUNT",
                    spread: []
                }

                subVention = { ...subVention, recoverInterest }
            }

            let existingObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === entity);

            if (existingObjectIndex > -1) {
                subVention['recoverInterest']['spread'][existingObjectIndex]['amount']['valueType'] = "PERCENTAGE";
                subVention['recoverInterest']['spread'][existingObjectIndex]['amount']['value'] = "";

                // if (entity === "ENTERPRISE_PARTNER") {
                //     let existingMerchantObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT");

                //     subVention['recoverInterest']['spread'][existingMerchantObjectIndex]['amount']['valueType'] = "";
                //     subVention['recoverInterest']['spread'][existingMerchantObjectIndex]['amount']['value'] = 0;
                // }
            } else {
                let spreadObject = {
                    entity: entity,
                    type: "INTEREST",
                    amount: {
                        valueType: "PERCENTAGE",
                        value: ""
                    }
                }


                // if (entity === "ENTERPRISE_PARTNER") {
                //     spreadObject['tenure'] = {
                //         valueType: "DAYS",
                //         value: ""
                //     }
                //     // let spreadObjectMerchant = {
                //     //     entity: "MERCHANT",
                //     //     type: "INTEREST",
                //     //     amount: {
                //     //         valueType: "",
                //     //         value: 0
                //     //     }
                //     // }

                //     subVention['recoverInterest']['spread'].push(spreadObject);
                //     // subVention['recoverInterest']['spread'].push(spreadObjectMerchant);
                // } else {
                //     subVention['recoverInterest']['spread'].push(spreadObject);
                // }

                subVention['recoverInterest']['spread'].push(spreadObject);
            }

            formData = { ...formData, subVention };

            isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : isSubventionInterestCheckNotSelectedEP;
            isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : isSubventionInterestCheckNotSelectedLP;
            isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? false : isSubventionInterestCheckNotSelectedFF;
            isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : isSubventionInterestCheckNotSelectedM;
        } else {
            let existingObjectIndex = formData['subVention']['recoverInterest']['spread'].findIndex((item) => item['entity'] === entity);

            formData['subVention']['recoverInterest']['spread'].splice(existingObjectIndex, 1)

            // if (entity === "ENTERPRISE_PARTNER") {
            //     let existingMerchantObjectIndex = formData['subVention']['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT");
            //     formData['subVention']['recoverInterest']['spread'].splice(existingMerchantObjectIndex, 1)
            // }

            subventionInterestValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "" : subventionInterestValueTypeEP;
            subventionInterestValueTypeLP = entity === "LENDING_PARTNER" ? "" : subventionInterestValueTypeLP;
            subventionInterestValueTypeFF = entity === "FUNDFINA" ? "" : subventionInterestValueTypeFF;
            subventionInterestValueTypeM = entity === "MERCHANT" ? "" : subventionInterestValueTypeM;

            isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? true : isSubventionInterestCheckNotSelectedEP;
            isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? true : isSubventionInterestCheckNotSelectedLP;
            isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? true : isSubventionInterestCheckNotSelectedFF;
            isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : isSubventionInterestCheckNotSelectedM;

            if (formData['subVention']['recoverInterest']['spread'].length === 0) {
                delete formData['subVention']['recoverInterest'];
                isSubventionInterestCheckNotSelectedEP = true;
                isSubventionInterestCheckNotSelectedLP = true;
                isSubventionInterestCheckNotSelectedFF = true;
                isSubventionInterestCheckNotSelectedM = true;
            }

            if (Object.keys(formData['subVention']).length === 0) {
                delete formData['subVention'];
            }


            // if (subventionInterestCheckFullEntity === entity) {
            //     isSubventionInterestCheckDisabledEP = false;
            //     isSubventionInterestCheckDisabledLP = false;
            //     isSubventionInterestCheckDisabledFF = false;
            //     isSubventionInterestCheckDisabledM = false;
            //     subventionInterestCheckFullEntity = ""
            // }
        }

        this.setState({
            isSubventionInterestCheckNotSelectedM,
            isSubventionInterestCheckNotSelectedEP,
            isSubventionInterestCheckNotSelectedLP,
            isSubventionInterestCheckNotSelectedFF,
            // subventionInterestCheckFullEntity,
            isSubventionInterestCheckDisabledEP,
            isSubventionInterestCheckDisabledLP,
            isSubventionInterestCheckDisabledFF,
            isSubventionInterestCheckDisabledM,
            subventionInterestValueTypeEP,
            subventionInterestValueTypeLP,
            subventionInterestValueTypeFF,
            subventionInterestValueTypeM,
            formData
        })
    }

    handleSubventionInterestRadioChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;
        let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
        let isSubventionInterestCheckNotSelectedM = this.state.isSubventionInterestCheckNotSelectedM;

        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;
        let subventionInterestValueTypeM = this.state.subventionInterestValueTypeM;

        let isSubventionInterestCheckDisabledM = this.state.isSubventionInterestCheckDisabledM;
        let isSubventionInterestCheckDisabledEP = this.state.isSubventionInterestCheckDisabledEP;
        let isSubventionInterestCheckDisabledLP = this.state.isSubventionInterestCheckDisabledLP;
        let isSubventionInterestCheckDisabledFF = this.state.isSubventionInterestCheckDisabledFF;

        let subventionInterestCheckFullEntity = this.state.subventionInterestCheckFullEntity;

        let existingObjectIndex = formData['subVention']['recoverInterest']['spread'].findIndex((item) => item['entity'] === entity);

        formData['subVention']['recoverInterest']['spread'][existingObjectIndex]['amount']['valueType'] = "PERCENTAGE";
        formData['subVention']['recoverInterest']['spread'][existingObjectIndex]['amount']['value'] = "";

        if (input.value === "FULL") {
            formData['subVention']['recoverInterest']['spread'][existingObjectIndex]['amount']['value'] = 100;

            formData['subVention']['recoverInterest']['spread'] = formData['subVention']['recoverInterest']['spread'].filter(item => item['entity'] === entity);
            // for (let item = 0; item < formData['subVention']['recoverInterest']['spread'].length; item++) {
            //     if (formData['subVention']['recoverInterest']['spread'][item]['entity'] !== entity) {
            //         formData['subVention']['recoverInterest']['spread'].splice(item, 1);
            //     }
            // }

            subventionInterestValueTypeEP = entity === "ENTERPRISE_PARTNER" ? input.value : "";
            subventionInterestValueTypeLP = entity === "LENDING_PARTNER" ? input.value : "";
            subventionInterestValueTypeFF = entity === "FUNDFINA" ? input.value : "";
            subventionInterestValueTypeM = entity === "MERCHANT" ? input.value : "";

            // formData = { ...formData };

            isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionInterestCheckDisabledEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionInterestCheckDisabledLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionInterestCheckDisabledFF = entity === "FUNDFINA" ? false : true;
            // isSubventionInterestCheckDisabledM = entity === "MERCHANT" ? false : true;

            // subventionInterestCheckFullEntity = entity;

        } else {
            // if (entity === "ENTERPRISE_PARTNER") {
            //     let existingMerchantObjectIndex = formData['subVention']['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT");

            //     formData['subVention']['recoverInterest']['spread'][existingMerchantObjectIndex]['amount']['valueType'] = input.value;
            //     formData['subVention']['recoverInterest']['spread'][existingMerchantObjectIndex]['amount']['value'] = "";
            // }



            subventionInterestValueTypeEP = entity === "ENTERPRISE_PARTNER" ? input.value : subventionInterestValueTypeEP;
            subventionInterestValueTypeLP = entity === "LENDING_PARTNER" ? input.value : subventionInterestValueTypeLP;
            subventionInterestValueTypeFF = entity === "FUNDFINA" ? input.value : subventionInterestValueTypeFF;
            subventionInterestValueTypeM = entity === "MERCHANT" ? input.value : subventionInterestValueTypeM;

            // formData = { ...formData };

            isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : isSubventionInterestCheckNotSelectedEP;
            isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : isSubventionInterestCheckNotSelectedLP;
            isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? false : isSubventionInterestCheckNotSelectedFF;
            isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : isSubventionInterestCheckNotSelectedM;

            // if (subventionInterestCheckFullEntity == entity) {
            //     isSubventionInterestCheckDisabledEP = false;
            //     isSubventionInterestCheckDisabledLP = false;
            //     isSubventionInterestCheckDisabledFF = false;
            //     isSubventionInterestCheckDisabledM = false;

            //     subventionInterestCheckFullEntity = "";
            // }
        }



        this.setState({
            isSubventionInterestCheckNotSelectedM,
            isSubventionInterestCheckNotSelectedEP,
            isSubventionInterestCheckNotSelectedLP,
            isSubventionInterestCheckNotSelectedFF,
            subventionInterestValueTypeEP,
            subventionInterestValueTypeLP,
            subventionInterestValueTypeFF,
            subventionInterestValueTypeM,
            isSubventionInterestCheckDisabledM,
            isSubventionInterestCheckDisabledEP,
            isSubventionInterestCheckDisabledLP,
            isSubventionInterestCheckDisabledFF,
            subventionInterestCheckFullEntity,
            formData
        })
    }

    handleSubventionInterestValueChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;
        let subventionInterestValueTypeM = this.state.subventionInterestValueTypeM;
        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;

        let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
        let isSubventionInterestCheckNotSelectedM = this.state.isSubventionInterestCheckNotSelectedM;

        let isSubventionInterestCheckDisabledEP = this.state.isSubventionInterestCheckDisabledEP;
        let isSubventionInterestCheckDisabledLP = this.state.isSubventionInterestCheckDisabledLP;
        let isSubventionInterestCheckDisabledFF = this.state.isSubventionInterestCheckDisabledFF;
        let isSubventionInterestCheckDisabledM = this.state.isSubventionInterestCheckDisabledM;

        let subventionInterestCheckFullEntity = this.state.subventionInterestCheckFullEntity;

        let subVention = JSON.parse(JSON.stringify(formData['subVention']));

        // let spreadObject = {
        //     entity: entity,
        //     type: "INTEREST",
        //     amount: {
        //         // valueType : input.name,
        //         value: input.value
        //     },
        //     tenure: {
        //         valueType: "DAYS",
        //         value: -1
        //     }
        // }

        let spreadObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === entity)

        subVention['recoverInterest']['spread'][spreadObjectIndex]['amount']['value'] = input.value !== "" ? Number(input.value) : "";
        subVention['recoverInterest']['spread'][spreadObjectIndex]['amount']['valueType'] = "PERCENTAGE";

        if (Number(input.value) >= 100) {

            // && (subventionInterestValueTypeM === "PARTLY" || subventionInterestValueTypeEP === "PARTLY" || subventionInterestValueTypeLP === "PARTLY" || subventionInterestValueTypeFF === "PARTLY")

            subVention['recoverInterest']['spread'] = subVention['recoverInterest']['spread'].filter(item => item['entity'] === entity);

            subventionInterestValueTypeM = entity === "MERCHANT" ? "FULL" : "";
            subventionInterestValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "FULL" : "";
            subventionInterestValueTypeLP = entity === "LENDING_PARTNER" ? "FULL" : "";
            subventionInterestValueTypeFF = entity === "FUNDFINA" ? "FULL" : "";

            // formData = { ...formData };

            isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionInterestCheckDisabledEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionInterestCheckDisabledLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionInterestCheckDisabledFF = entity === "FUNDFINA" ? false : true;
            // isSubventionInterestCheckDisabledM = entity === "MERCHANT" ? false : true;

            // subventionInterestCheckFullEntity = entity;


        } else if (Number(input.value) < 100) {

            // && (subventionInterestValueTypeM === "FULL" || subventionInterestValueTypeEP === "FULL" || subventionInterestValueTypeLP === "FULL" || subventionInterestValueTypeFF === "FULL")

            subventionInterestValueTypeM = entity === "MERCHANT" ? "PARTLY" : "";
            subventionInterestValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "PARTLY" : "";
            subventionInterestValueTypeLP = entity === "LENDING_PARTNER" ? "PARTLY" : "";
            subventionInterestValueTypeFF = entity === "FUNDFINA" ? "PARTLY" : "";

            // formData = { ...formData };

            // isSubventionInterestCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionInterestCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionInterestCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            // isSubventionInterestCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionInterestCheckDisabledEP = false;
            // isSubventionInterestCheckDisabledLP = false;
            // isSubventionInterestCheckDisabledFF = false;
            // isSubventionInterestCheckDisabledM = false;

            // subventionInterestCheckFullEntity = entity;
        }

        // if (entity === "ENTERPRISE_PARTNER") {
        //     let spreadObjectMerchantIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT")

        //     if (subVention['recoverInterest']['spread'][spreadObjectMerchantIndex]['amount']['valueType'] === "PERCENTAGE") {
        //         subVention['recoverInterest']['spread'][spreadObjectMerchantIndex]['amount']['value'] = input.value !== "" ? (100 - Number(input.value)) : "";
        //     } else {
        //         subVention['recoverInterest']['spread'][spreadObjectMerchantIndex]['amount']['value'] = input.value !== "" ? 0 : "";
        //     }

        // }

        formData = { ...formData, subVention };

        this.setState({
            // isCreditLineCollectionTypeSelected,
            subventionInterestValueTypeM,
            subventionInterestValueTypeEP,
            subventionInterestValueTypeLP,
            subventionInterestValueTypeFF,
            subventionInterestValueTypeM,
            isSubventionInterestCheckNotSelectedEP,
            isSubventionInterestCheckNotSelectedLP,
            isSubventionInterestCheckNotSelectedFF,
            isSubventionInterestCheckNotSelectedM,
            isSubventionInterestCheckDisabledEP,
            isSubventionInterestCheckDisabledLP,
            isSubventionInterestCheckDisabledFF,
            isSubventionInterestCheckDisabledM,
            subventionInterestCheckFullEntity,
            formData
        })
    }

    handleValueSubventionInterestBorneUpto() {
        let formData = this.state.formData;

        if (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread']) {
            let spreadArray = formData['subVention']['recoverInterest']['spread'];
            const enterprisePartnerData = spreadArray.filter((item) => item['entity'] === "ENTERPRISE_PARTNER");
            if (enterprisePartnerData && enterprisePartnerData.length > 0) {
                const enterprisePartnerObject = enterprisePartnerData[0];
                if (enterprisePartnerObject['tenure'] && enterprisePartnerObject['tenure']['value']) {
                    return enterprisePartnerObject['tenure']['value'];
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else {

            return "";
        }

    }

    handleSubventionInterestBorneUptoValueChange({ currentTarget: input }) {
        let formData = this.state.formData;

        let subVention = JSON.parse(JSON.stringify(formData['subVention']))

        let spreadEPObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "ENTERPRISE_PARTNER");

        if (input.value === "") {
            if ("tenure" in subVention['recoverInterest']['spread'][spreadEPObjectIndex]) {
                delete subVention['recoverInterest']['spread'][spreadEPObjectIndex]['tenure'];
            }

            if (formData['type'] && formData['type'] === "CL") {
                let loan = JSON.parse(JSON.stringify(formData['loan']))

                // if (!("recoverInterest" in loan)) {
                //     loan = {
                //         ...loan, recoverInterest: {
                //             type: "INTEREST",
                //             tenure: {
                //                 valueType: "DAYS",
                //                 value: ""
                //             }
                //         }
                //     }
                // }

                // if (!("tenure" in loan['recoverInterest'])) {
                //     loan['recoverInterest'] = {
                //         ...loan['recoverInterest'],
                //         tenure: {
                //             value: "",
                //             valueType: "DAYS"
                //         }
                //     }
                // }

                if (("recoverInterest" in loan) && ("tenure" in loan['recoverInterest'])) {
                    delete loan['recoverInterest']['tenure'];
                }


                formData = { ...formData, loan };
            }

            // if (formData['tenure'] && formData['tenure']['value']) {
            //     let spreadMerchantObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT");
            //     subVention['recoverInterest']['spread'][spreadMerchantObjectIndex]['tenure']['value'] = formData['tenure']['value'] - Number(input.value);
            // }
        } else if (input.value > 0) {
            if (!("tenure" in subVention['recoverInterest']['spread'][spreadEPObjectIndex])) {
                subVention['recoverInterest']['spread'][spreadEPObjectIndex] = {
                    ...subVention['recoverInterest']['spread'][spreadEPObjectIndex],
                    tenure: {
                        value: "",
                        valueType: "DAYS"
                    }
                }
            }

            subVention['recoverInterest']['spread'][spreadEPObjectIndex]['tenure']['value'] = Number(input.value);

            if (formData['type'] && formData['type'] === "CL") {
                let loan = formData['loan'] ? JSON.parse(JSON.stringify(formData['loan'])) : {
                    recoverInterest: {
                        type: "INTEREST",
                        tenure: {
                            valueType: "DAYS",
                            value: ""
                        }
                    }
                }

                if (!("recoverInterest" in loan)) {
                    loan = {
                        ...loan, recoverInterest: {
                            type: "INTEREST",
                            tenure: {
                                valueType: "DAYS",
                                value: ""
                            }
                        }
                    }
                }

                if (!("tenure" in loan['recoverInterest'])) {
                    loan['recoverInterest'] = {
                        ...loan['recoverInterest'],
                        tenure: {
                            value: "",
                            valueType: "DAYS"
                        }
                    }
                }

                loan['recoverInterest']['tenure']['value'] = Number(input.value);
                formData = { ...formData, loan };
            }

            // if (formData['tenure'] && formData['tenure']['value']) {
            //     let spreadMerchantObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "MERCHANT");
            //     subVention['recoverInterest']['spread'][spreadMerchantObjectIndex]['tenure']['value'] = formData['tenure']['value'] - Number(input.value);
            // }
        }

        formData = { ...formData, subVention };

        this.setState({
            formData
        });
    }

    handleSubventionInterestRICOFChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let subventionInterestRICOF = this.state.subventionInterestRICOF;

        let subVention = JSON.parse(JSON.stringify(formData['subVention']));

        // let spreadEPObjectIndex = subVention['recoverInterest']['spread'].findIndex((item) => item['entity'] === "ENTERPRISE_PARTNER");

        if (input.checked) {
            subVention['recoverInterest']['returnInCaseOfForeClosure'] = true;
            subventionInterestRICOF = true;
        } else {
            delete subVention['recoverInterest']['returnInCaseOfForeClosure']
            subventionInterestRICOF = false;
        }

        formData = { ...formData, subVention };

        this.setState({
            subventionInterestRICOF,
            formData
        });
    }

    handleSubventionProcessingFeesCheckChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;
        let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let isSubventionProcessingFeesCheckNotSelectedM = this.state.isSubventionProcessingFeesCheckNotSelectedM;

        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;
        let subventionProcessingFeesValueTypeM = this.state.subventionProcessingFeesValueTypeM;

        if (input.checked) {


            let subVention = formData['subVention'] ?
                JSON.parse(JSON.stringify(formData['subVention'])) :
                {
                    processingFees: {
                        stage: "PRE",
                        spreadType: "LOAN_AMOUNT",
                        spread: []
                    }
                }

            if (!("processingFees" in subVention)) {
                let processingFees = {
                    stage: "PRE",
                    spreadType: "LOAN_AMOUNT",
                    spread: []
                }

                subVention = { ...subVention, processingFees }
            }

            let existingObjectIndex = subVention['processingFees']['spread'].findIndex((item) => item['entity'] === entity);

            if (existingObjectIndex > -1) {
                subVention['processingFees']['spread'][existingObjectIndex]['amount']['valueType'] = "PERCENTAGE";
                subVention['processingFees']['spread'][existingObjectIndex]['amount']['value'] = "";

                // if (entity === "ENTERPRISE_PARTNER") {
                //     let existingMerchantObjectIndex = subVention['processingFees']['spread'].findIndex((item) => item['entity'] === "MERCHANT");

                //     subVention['processingFees']['spread'][existingMerchantObjectIndex]['amount']['valueType'] = "";
                //     subVention['processingFees']['spread'][existingMerchantObjectIndex]['amount']['value'] = 0;
                // }
            } else {
                let spreadObject = {
                    entity: entity,
                    type: "PROCESSING_FEES",
                    amount: {
                        valueType: "PERCENTAGE",
                        value: ""
                    }
                }

                subVention['processingFees']['spread'].push(spreadObject);

                // if (entity === "ENTERPRISE_PARTNER") {
                //     let spreadObjectMerchant = {
                //         entity: "MERCHANT",
                //         type: "PROCESSING_FEES",
                //         amount: {
                //             valueType: "",
                //             value: "",
                //         }
                //     }

                //     subVention['processingFees']['spread'].push(spreadObjectMerchant);
                // }
            }

            formData = { ...formData, subVention };

            isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedEP;
            isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedLP;
            isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? false : isSubventionProcessingFeesCheckNotSelectedFF;
            isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? false : isSubventionProcessingFeesCheckNotSelectedM;
        } else {
            let existingObjectIndex = formData['subVention']['processingFees']['spread'].findIndex((item) => item['entity'] === entity);
            formData['subVention']['processingFees']['spread'].splice(existingObjectIndex, 1);

            // if (entity === "ENTERPRISE_PARTNER") {
            //     let existingMerchantObjectIndex = formData['subVention']['processingFees']['spread'].findIndex((item) => item['entity'] === "MERCHANT");
            //     formData['subVention']['processingFees']['spread'].splice(existingMerchantObjectIndex, 1);
            // }

            subventionProcessingFeesValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "" : subventionProcessingFeesValueTypeEP;
            subventionProcessingFeesValueTypeLP = entity === "LENDING_PARTNER" ? "" : subventionProcessingFeesValueTypeLP;
            subventionProcessingFeesValueTypeFF = entity === "FUNDFINA" ? "" : subventionProcessingFeesValueTypeFF;
            subventionProcessingFeesValueTypeM = entity === "MERCHANT" ? "" : subventionProcessingFeesValueTypeM;

            isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? true : isSubventionProcessingFeesCheckNotSelectedEP;
            isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? true : isSubventionProcessingFeesCheckNotSelectedLP;
            isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? true : isSubventionProcessingFeesCheckNotSelectedFF;
            isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? true : isSubventionProcessingFeesCheckNotSelectedM;


            if (formData['subVention']['processingFees']['spread'].length === 0) {
                delete formData['subVention']['processingFees'];
                isSubventionProcessingFeesCheckNotSelectedEP = true;
                isSubventionProcessingFeesCheckNotSelectedLP = true;
                isSubventionProcessingFeesCheckNotSelectedFF = true;
                isSubventionProcessingFeesCheckNotSelectedM = true;
            }

            if (Object.keys(formData['subVention']).length === 0) {
                delete formData['subVention'];
            }
        }

        this.setState({
            isSubventionProcessingFeesCheckNotSelectedEP,
            isSubventionProcessingFeesCheckNotSelectedLP,
            isSubventionProcessingFeesCheckNotSelectedFF,
            isSubventionProcessingFeesCheckNotSelectedM,
            subventionProcessingFeesValueTypeEP,
            subventionProcessingFeesValueTypeLP,
            subventionProcessingFeesValueTypeFF,
            subventionProcessingFeesValueTypeM,
            formData
        })
    }

    handleSubventionProcessingFeesRadioChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;
        let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let isSubventionProcessingFeesCheckNotSelectedM = this.state.isSubventionProcessingFeesCheckNotSelectedM;

        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;
        let subventionProcessingFeesValueTypeM = this.state.subventionProcessingFeesValueTypeM;

        let isSubventionProcessingFeesCheckDisabledM = this.state.isSubventionProcessingFeesCheckDisabledM;
        let isSubventionProcessingFeesCheckDisabledEP = this.state.isSubventionProcessingFeesCheckDisabledEP;
        let isSubventionProcessingFeesCheckDisabledLP = this.state.isSubventionProcessingFeesCheckDisabledLP;
        let isSubventionProcessingFeesCheckDisabledFF = this.state.isSubventionProcessingFeesCheckDisabledFF;

        let subventionProcessingFeesCheckFullEntity = this.state.subventionProcessingFeesCheckFullEntity;


        let existingObjectIndex = formData['subVention']['processingFees']['spread'].findIndex((item) => item['entity'] === entity);

        formData['subVention']['processingFees']['spread'][existingObjectIndex]['amount']['valueType'] = "PERCENTAGE";
        formData['subVention']['processingFees']['spread'][existingObjectIndex]['amount']['value'] = "";

        if (input.value === "FULL") {
            formData['subVention']['processingFees']['spread'][existingObjectIndex]['amount']['value'] = 100;

            formData['subVention']['processingFees']['spread'] = formData['subVention']['processingFees']['spread'].filter(item => item['entity'] === entity);
            // for (let item = 0; item < formData['subVention']['recoverInterest']['spread'].length; item++) {
            //     if (formData['subVention']['recoverInterest']['spread'][item]['entity'] !== entity) {
            //         formData['subVention']['recoverInterest']['spread'].splice(item, 1);
            //     }
            // }

            subventionProcessingFeesValueTypeEP = entity === "ENTERPRISE_PARTNER" ? input.value : "";
            subventionProcessingFeesValueTypeLP = entity === "LENDING_PARTNER" ? input.value : "";
            subventionProcessingFeesValueTypeFF = entity === "FUNDFINA" ? input.value : "";
            subventionProcessingFeesValueTypeM = entity === "MERCHANT" ? input.value : "";

            // formData = { ...formData };

            isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionProcessingFeesCheckDisabledEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckDisabledLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckDisabledFF = entity === "FUNDFINA" ? false : true;
            // isSubventionProcessingFeesCheckDisabledM = entity === "MERCHANT" ? false : true;

            // subventionProcessingFeesCheckFullEntity = entity;

        } else {
            // if (entity === "ENTERPRISE_PARTNER") {
            //     let existingMerchantObjectIndex = formData['subVention']['processingFees']['spread'].findIndex((item) => item['entity'] === "MERCHANT");

            //     formData['subVention']['processingFees']['spread'][existingMerchantObjectIndex]['amount']['valueType'] = input.value;
            //     formData['subVention']['processingFees']['spread'][existingMerchantObjectIndex]['amount']['value'] = 0;
            // }



            subventionProcessingFeesValueTypeEP = entity === "ENTERPRISE_PARTNER" ? input.value : subventionProcessingFeesValueTypeEP;
            subventionProcessingFeesValueTypeLP = entity === "LENDING_PARTNER" ? input.value : subventionProcessingFeesValueTypeLP;
            subventionProcessingFeesValueTypeFF = entity === "FUNDFINA" ? input.value : subventionProcessingFeesValueTypeFF;
            subventionProcessingFeesValueTypeM = entity === "MERCHANT" ? input.value : subventionProcessingFeesValueTypeM;

            // formData = { ...formData };

            isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedEP;
            isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : isSubventionProcessingFeesCheckNotSelectedLP;
            isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? false : isSubventionProcessingFeesCheckNotSelectedFF;
            isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? false : isSubventionProcessingFeesCheckNotSelectedM;

            // if (subventionProcessingFeesCheckFullEntity == entity) {
            //     isSubventionProcessingFeesCheckDisabledEP = false;
            //     isSubventionProcessingFeesCheckDisabledLP = false;
            //     isSubventionProcessingFeesCheckDisabledFF = false;
            //     isSubventionProcessingFeesCheckDisabledM = false;

            //     subventionProcessingFeesCheckFullEntity = "";
            // }
        }

        this.setState({
            isSubventionProcessingFeesCheckNotSelectedEP,
            isSubventionProcessingFeesCheckNotSelectedLP,
            isSubventionProcessingFeesCheckNotSelectedFF,
            isSubventionProcessingFeesCheckNotSelectedM,
            isSubventionProcessingFeesCheckDisabledEP,
            isSubventionProcessingFeesCheckDisabledLP,
            isSubventionProcessingFeesCheckDisabledFF,
            isSubventionProcessingFeesCheckDisabledM,
            subventionProcessingFeesCheckFullEntity,
            subventionProcessingFeesValueTypeEP,
            subventionProcessingFeesValueTypeLP,
            subventionProcessingFeesValueTypeFF,
            subventionProcessingFeesValueTypeM,
            formData
        })
    }

    handleValueSubventionProcessingFees(entity, name) {
        let formData = this.state.formData;

        if (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread']) {

            let spreadArray = formData['subVention']['processingFees']['spread'];
            let spreadItem = spreadArray.filter((item) => item['entity'] === entity);
            return spreadItem.length > 0 ? spreadItem[0]['amount'][name] : ""
        } else {

            return "";
        }
    }

    handleSubventionProcessingFeesValueChange({ currentTarget: input }, entity) {
        let formData = this.state.formData;

        let subventionProcessingFeesValueTypeM = this.state.subventionProcessingFeesValueTypeM;
        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;

        let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let isSubventionProcessingFeesCheckNotSelectedM = this.state.isSubventionProcessingFeesCheckNotSelectedM;

        let isSubventionProcessingFeesCheckDisabledEP = this.state.isSubventionProcessingFeesCheckDisabledEP;
        let isSubventionProcessingFeesCheckDisabledLP = this.state.isSubventionProcessingFeesCheckDisabledLP;
        let isSubventionProcessingFeesCheckDisabledFF = this.state.isSubventionProcessingFeesCheckDisabledFF;
        let isSubventionProcessingFeesCheckDisabledM = this.state.isSubventionProcessingFeesCheckDisabledM;

        let subventionProcessingFeesCheckFullEntity = this.state.subventionProcessingFeesCheckFullEntity;


        let subVention = JSON.parse(JSON.stringify(formData['subVention']))

        // let spreadObject = {
        //     entity: entity,
        //     type: "INTEREST",
        //     amount: {
        //         // valueType : input.name,
        //         value: input.value
        //     },
        //     tenure: {
        //         valueType: "DAYS",
        //         value: -1
        //     }
        // }

        // if ("spread" in subVention['processingFees']) {
        //     let spreadObjectIndex = subVention['processingFees']['spread'].findIndex((item) => item['entity'] === entity)
        // subVention['processingFees']['spread'][spreadObjectIndex]['amount']['value'] = input.value !== "" ? Number(input.value) : "";
        // }

        let spreadObjectIndex = subVention['processingFees']['spread'].findIndex((item) => item['entity'] === entity)
        subVention['processingFees']['spread'][spreadObjectIndex]['amount']['value'] = input.value !== "" ? Number(input.value) : "";
        subVention['processingFees']['spread'][spreadObjectIndex]['amount']['valueType'] = "PERCENTAGE";

        if (Number(input.value) >= 100) {
            // && (subventionProcessingFeesValueTypeM === "PARTLY" || subventionProcessingFeesValueTypeEP === "PARTLY" || subventionProcessingFeesValueTypeLP === "PARTLY" || subventionProcessingFeesValueTypeFF === "PARTLY")

            subVention['processingFees']['spread'] = subVention['processingFees']['spread'].filter(item => item['entity'] === entity);

            subventionProcessingFeesValueTypeM = entity === "MERCHANT" ? "FULL" : "";
            subventionProcessingFeesValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "FULL" : "";
            subventionProcessingFeesValueTypeLP = entity === "LENDING_PARTNER" ? "FULL" : "";
            subventionProcessingFeesValueTypeFF = entity === "FUNDFINA" ? "FULL" : "";

            // formData = { ...formData };

            isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionProcessingFeesCheckDisabledEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckDisabledLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckDisabledFF = entity === "FUNDFINA" ? false : true;
            // isSubventionProcessingFeesCheckDisabledM = entity === "MERCHANT" ? false : true;

            // subventionProcessingFeesCheckFullEntity = entity;


        } else if (Number(input.value) < 100) {
            // && (subventionProcessingFeesValueTypeM === "FULL" || subventionProcessingFeesValueTypeEP === "FULL" || subventionProcessingFeesValueTypeLP === "FULL" || subventionProcessingFeesValueTypeFF === "FULL")
            subventionProcessingFeesValueTypeM = entity === "MERCHANT" ? "PARTLY" : "";
            subventionProcessingFeesValueTypeEP = entity === "ENTERPRISE_PARTNER" ? "PARTLY" : "";
            subventionProcessingFeesValueTypeLP = entity === "LENDING_PARTNER" ? "PARTLY" : "";
            subventionProcessingFeesValueTypeFF = entity === "FUNDFINA" ? "PARTLY" : "";

            // formData = { ...formData };

            // isSubventionProcessingFeesCheckNotSelectedEP = entity === "ENTERPRISE_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckNotSelectedLP = entity === "LENDING_PARTNER" ? false : true;
            // isSubventionProcessingFeesCheckNotSelectedFF = entity === "FUNDFINA" ? false : true;
            // isSubventionProcessingFeesCheckNotSelectedM = entity === "MERCHANT" ? false : true;

            // isSubventionProcessingFeesCheckDisabledEP = false;
            // isSubventionProcessingFeesCheckDisabledLP = false;
            // isSubventionProcessingFeesCheckDisabledFF = false;
            // isSubventionProcessingFeesCheckDisabledM = false;

            // subventionProcessingFeesCheckFullEntity = entity;
        }


        // if (entity === "ENTERPRISE_PARTNER") {
        //     let spreadObjectMerchantIndex = subVention['processingFees']['spread'].findIndex((item) => item['entity'] === "MERCHANT")

        //     if (subVention['processingFees']['spread'][spreadObjectMerchantIndex]['amount']['valueType'] === "PERCENTAGE") {
        //         subVention['processingFees']['spread'][spreadObjectMerchantIndex]['amount']['value'] = 2 - Number(input.value);
        //     } else {
        //         subVention['processingFees']['spread'][spreadObjectMerchantIndex]['amount']['value'] = 0;
        //     }

        // }

        formData = { ...formData, subVention };

        this.setState({
            // isCreditLineCollectionTypeSelected,
            subventionProcessingFeesValueTypeM,
            subventionProcessingFeesValueTypeEP,
            subventionProcessingFeesValueTypeLP,
            subventionProcessingFeesValueTypeFF,
            isSubventionProcessingFeesCheckNotSelectedEP,
            isSubventionProcessingFeesCheckNotSelectedLP,
            isSubventionProcessingFeesCheckNotSelectedFF,
            isSubventionProcessingFeesCheckNotSelectedM,
            isSubventionProcessingFeesCheckDisabledEP,
            isSubventionProcessingFeesCheckDisabledLP,
            isSubventionProcessingFeesCheckDisabledFF,
            isSubventionProcessingFeesCheckDisabledM,
            subventionProcessingFeesCheckFullEntity,
            formData
        })
    }

    handleLatePaymentRadioChange({ currentTarget: input }) {
        let formData = this.state.formData;
        let isProcessingFeeValueType = this.state.isProcessingFeeValueType;

        let latePayment = formData['latePayment'] ? JSON.parse(JSON.stringify(formData['latePayment'])) : {
            valueType: "",
            value: ""
        }

        // latePayment['valueType'] = input.value;
        // latePayment['value'] = "";

        latePayment = {
            ...latePayment,
            valueType: input.value,
            value: "",
            installments: {
            }
        }

        formData = { ...formData, latePayment };


        this.setState({
            formData,
            // isProcessingFeeValueType
        })
    }

    handleLatePaymentValueChange({ currentTarget: input }) {
        let formData = this.state.formData;

        if (input.value === "") {
            formData['latePayment']['value'] = "";
        } else {

            let latePayment = JSON.parse(JSON.stringify(formData['latePayment']))

            latePayment['value'] = Number(input.value);
            formData = { ...formData, latePayment };
        }

        this.setState({
            formData
        })
    }

    handleLateValuesChange({ currentTarget: input }) {
        let formData = this.state.formData;

        if (input.value === "") {
            delete formData['latePayment'][input.name];

            if (Object.keys(formData['latePayment']).length === 0) {
                delete formData['latePayment'];
            }
        } else {

            let latePayment = formData['latePayment'] ? JSON.parse(JSON.stringify(formData['latePayment'])) : {
                installments: {
                }
            }
            if (!("installments" in latePayment)) {
                latePayment = {
                    ...latePayment,
                    installments: {

                    }
                }
            }


            latePayment[input['name']] = Number(input['value']);

            formData = { ...formData, latePayment };
        }

        this.setState({
            formData
        })
    }

    handleLatePaymentCheckValuesChange({ currentTarget: input }) {
        let formData = this.state.formData;

        let latePayment = formData['latePayment'] ? JSON.parse(JSON.stringify(formData['latePayment'])) : {
            installments: {
                type: "PENDING_AMOUNT",
                principal: true,
                interest: false
            }
        }
        if (!("installments" in latePayment)) {
            latePayment = {
                ...latePayment,
                installments: {
                    type: "PENDING_AMOUNT",
                    principal: true,
                    interest: false
                }
            }
        }

        switch (input.name) {
            case "LatePaymentsPrincipal":
                input.name = 'principal';
                break;

            case "LatePaymentsInterest":
                input.name = 'interest';
                break;
        }

        latePayment['installments'][input.name] = input.checked;

        formData = { ...formData, latePayment }

        this.setState({
            formData
        })
    }

    handleLatePaymentRadioValuesChange({ currentTarget: input }) {
        let formData = this.state.formData;

        let latePayment = formData['latePayment'] ? JSON.parse(JSON.stringify(formData['latePayment'])) : {
            installments: {
                type: ""
            }
        }
        if (!("installments" in latePayment)) {

            latePayment = {
                ...latePayment,
                installments: {}
            }
        }

        switch (input.value) {
            case 'PER_INSTALLMENT':
                latePayment['installments'] = {
                    type: input.value,
                }
                break;

            case 'PENDING_AMOUNT':
                latePayment['installments'] = {
                    type: input.value,
                    principal: true,
                    interest: false
                }
                break;
        }


        // latePayment['installments']['type'] = input.value;

        formData = { ...formData, latePayment }

        this.setState({
            formData
        })
    }

    goBack = () => {
        let formData = this.state.formData;
        let errors = this.state.errors;
        let openForm = this.state.openForm;
        let isEditButtonDisabled = this.state.isEditButtonDisabled;
        let isFormEdit = this.state.isFormEdit;
        let isSaveDisabled = this.state.isSaveDisabled;
        let isProcessingFeeValueType = this.state.isProcessingFeeValueType;
        let processingFeesRadioValue = this.state.processingFeesRadioValue;
        let hasName = this.state.hasName;
        let interestPrePostRadioCheck = this.state.interestPrePostRadioCheck;
        let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
        let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let subscriptionCheck = this.state.subscriptionCheck;
        let isCreditLineCollectionTypeSelected = this.state.isCreditLineCollectionTypeSelected;
        let creditLineCollectionDropDownValue = this.state.creditLineCollectionDropDownValue;
        let creditLineNoOfInstallments = this.state.creditLineNoOfInstallments;
        let creditLineMaxNoOfDays = this.state.creditLineMaxNoOfDays;
        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;
        let subventionInterestRICOF = this.state.subventionInterestRICOF;
        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;
        let productMasterList = this.state.productMasterList;
        let searchField = this.state.searchField;
        let isFormDisabled = this.state.isFormDisabled;
        let isCloneButtonDisabled = this.state.isCloneButtonDisabled;
        let isDeleteButtonDisabled = this.state.isDeleteButtonDisabled;
        let isCloneProduct = this.state.isCloneProduct;
        // let tenureValue = this.state.tenureValue;
        let tenureObject = this.state.tenureObject;
        let productMasterListValues = this.state.productMasterListValues;
        let collectionTypeValue = this.state.collectionTypeValue;
        // let interestCollectionCheckbox = this.state.interestCollectionCheckbox;
        // let principalCollectionCheckbox = this.state.principalCollectionCheckbox;
        // let principalAtEndOfTenureCollectionCheckbox = this.state.principalAtEndOfTenureCollectionCheckbox;


        errors = {};
        searchField = "";
        isFormDisabled = true;
        // tenureValue = "";
        tenureObject = {
            minTenure: "",
            maxTenure: ""
        }
        isCloneProduct = false;
        hasName = false;
        isDeleteButtonDisabled = false;
        isCloneButtonDisabled = false;
        isCreditLineCollectionTypeSelected = true;
        creditLineCollectionDropDownValue = "";
        creditLineNoOfInstallments = "";
        creditLineMaxNoOfDays = "";
        subventionInterestValueTypeEP = "";
        subventionInterestValueTypeLP = "";
        subventionInterestValueTypeFF = "";
        subventionInterestRICOF = false;
        subventionProcessingFeesValueTypeEP = "";
        subventionProcessingFeesValueTypeLP = "";
        subventionProcessingFeesValueTypeFF = "";
        processingFeesRadioValue = "";
        isProcessingFeeValueType = true;
        productMasterList = JSON.parse(JSON.stringify(productMasterListValues));



        isSaveDisabled = false;
        isFormEdit = false;
        isEditButtonDisabled = false;
        openForm = !openForm;
        interestPrePostRadioCheck = "";
        formData = {
            type: "",
            category: "AMORTISING",
            latePayment: {
                installments: {
                    principal: false,
                    interest: false
                }
            },
            kycRecaptureInDays: 365
        }
        isSubventionInterestCheckNotSelectedEP = true;
        isSubventionInterestCheckNotSelectedLP = true;
        isSubventionInterestCheckNotSelectedFF = true;
        isSubventionProcessingFeesCheckNotSelectedEP = true;
        isSubventionProcessingFeesCheckNotSelectedLP = true;
        isSubventionProcessingFeesCheckNotSelectedFF = true;
        subscriptionCheck = true;
        collectionTypeValue = "";
        // interestCollectionCheckbox = false;
        // principalCollectionCheckbox = false;
        // principalAtEndOfTenureCollectionCheckbox = false;


        this.setState({
            formData,
            errors,
            openForm,
            isEditButtonDisabled,
            isFormEdit,
            isSaveDisabled,
            hasName,
            isProcessingFeeValueType,
            interestPrePostRadioCheck,
            isSubventionInterestCheckNotSelectedEP,
            isSubventionInterestCheckNotSelectedLP,
            isSubventionInterestCheckNotSelectedFF,
            isSubventionProcessingFeesCheckNotSelectedEP,
            isSubventionProcessingFeesCheckNotSelectedLP,
            isSubventionProcessingFeesCheckNotSelectedFF,
            subscriptionCheck,
            processingFeesRadioValue,
            isCreditLineCollectionTypeSelected,
            creditLineCollectionDropDownValue,
            creditLineNoOfInstallments,
            creditLineMaxNoOfDays,
            subventionInterestValueTypeEP,
            subventionInterestValueTypeLP,
            subventionInterestValueTypeFF,
            subventionInterestRICOF,
            subventionProcessingFeesValueTypeEP,
            subventionProcessingFeesValueTypeLP,
            subventionProcessingFeesValueTypeFF,
            isCloneButtonDisabled,
            isFormDisabled,
            isDeleteButtonDisabled,
            isCloneProduct,
            // tenureValue,
            tenureObject,
            searchField,
            productMasterList,
            collectionTypeValue,
            // interestCollectionCheckbox,
            // principalCollectionCheckbox,
            // principalAtEndOfTenureCollectionCheckbox
        })
        // console.log("openForm2: ", openForm)
    };

    handleCustomErrors() {
        let formData = this.state.formData;
        let errors = this.state.errors;

        let isSubventionInterestCheckNotSelectedEP = this.state.isSubventionInterestCheckNotSelectedEP;
        let isSubventionInterestCheckNotSelectedLP = this.state.isSubventionInterestCheckNotSelectedLP;
        let isSubventionInterestCheckNotSelectedFF = this.state.isSubventionInterestCheckNotSelectedFF;
        let isSubventionInterestCheckNotSelectedM = this.state.isSubventionInterestCheckNotSelectedM;

        let isSubventionProcessingFeesCheckNotSelectedEP = this.state.isSubventionProcessingFeesCheckNotSelectedEP;
        let isSubventionProcessingFeesCheckNotSelectedLP = this.state.isSubventionProcessingFeesCheckNotSelectedLP;
        let isSubventionProcessingFeesCheckNotSelectedFF = this.state.isSubventionProcessingFeesCheckNotSelectedFF;
        let isSubventionProcessingFeesCheckNotSelectedM = this.state.isSubventionProcessingFeesCheckNotSelectedM;

        let subventionInterestValueTypeEP = this.state.subventionInterestValueTypeEP;
        let subventionInterestValueTypeLP = this.state.subventionInterestValueTypeLP;
        let subventionInterestValueTypeFF = this.state.subventionInterestValueTypeFF;
        let subventionInterestValueTypeM = this.state.subventionInterestValueTypeM;

        let subventionProcessingFeesValueTypeEP = this.state.subventionProcessingFeesValueTypeEP;
        let subventionProcessingFeesValueTypeLP = this.state.subventionProcessingFeesValueTypeLP;
        let subventionProcessingFeesValueTypeFF = this.state.subventionProcessingFeesValueTypeFF;
        let subventionProcessingFeesValueTypeM = this.state.subventionProcessingFeesValueTypeM;

        let subventionInterestCheckFullEntity = this.state.subventionInterestCheckFullEntity;

        //Required values validation
        delete errors['globalError'];

        if (_.isEmpty(formData['type'])) {
            errors['typeError'] = "Please select Product Type";
        }
        else {
            delete errors['typeError'];
        }

        if (_.isEmpty(formData['name'])) {
            errors['nameError'] = "Please enter Product Name";
        } else {
            delete errors['nameError'];
        }

        // if (_.isEmpty(formData['lender'])) {
        //     errors['lenderError'] = "Please select a Lender";
        // } else {
        //     delete errors['lenderError'];
        // }

        if (_.isEmpty(formData['loanAmountRange']) || !(formData['loanAmountRange']['min'])) {
            errors['minLoanAmountError'] = "Please enter min Loan amount";
        } else if (formData && formData['loanAmountRange'] && formData['loanAmountRange']['min'] < 0) {
            errors['minLoanAmountError'] = "Negative value not accept";
        } else {
            delete errors['minLoanAmountError'];
        }

        if (_.isEmpty(formData['loanAmountRange']) || !(formData['loanAmountRange']['max'])) {
            errors['maxLoanAmountError'] = "Please enter max Loan amount";
        } else if (formData && formData['loanAmountRange'] && formData['loanAmountRange']['max'] < 0) {
            errors['maxLoanAmountError'] = "Negative value are not accepted";
        }
        else {
            delete errors['maxLoanAmountError'];
        }


        if (_.isEmpty(formData['tenure']) || (!formData['tenure']['min'] && !formData['tenure']['max'])) {
            errors['minTenureError'] = "Please enter Min Tenure";
            errors['maxTenureError'] = "Please enter Max Tenure";
        } else if (!formData['tenure']['min']) {
            errors['minTenureError'] = "Please enter Min Tenure";
        } else if (!formData['tenure']['max']) {
            errors['maxTenureError'] = "Please enter Max Tenure";
        } else if (formData['tenure']['min'] < 0) {
            errors['minTenureError'] = "Negative value are not accepted";
        } else if (formData['tenure']['max'] < 0) {
            errors['maxTenureError'] = "Negative value are not accepted";
        } else if (formData['tenure']['max'] <= formData['tenure']['min']) {
            errors['minTenureError'] = "Min Tenure cannot be greater than or equal to Max Tenure";
        }

        if (!formData['kycRecaptureInDays']) {
            errors['kycRecaptureInDaysError'] = "Please enter KYC Recapture value";
        } else if (formData['kycRecaptureInDays'] < 0) {
            errors['kycRecaptureInDaysError'] = "Negative value are not accepted";
        } else {
            delete errors['kycRecaptureInDaysError'];
        }


        // else {
        //     if (formData['type'] === "CL") {
        //         if (_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['recoverInterest']) || _.isEmpty(formData['loan']['recoverInterest']['tenure'])) {
        //             errors['tenureError'] = "Please enter Tenure";
        //         }
        //     }
        // }


        // let startDate = new Date(formData['startDate']);
        // startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
        // let today = new Date(Date.now());
        // today = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        // if (!formData['startDate'] || startDate <= today) {
        //     errors['startDate'] = "Please select Start Date greater than today";
        // } else {
        //     delete errors['startDate'];
        // }

        // let endDate = new Date(formData['endDate']);
        // endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0, 0);
        // if (!formData['endDate'] || endDate <= today) {
        //     errors['endDate'] = "Please select End Date greater than today";
        // } else {
        //     delete errors['endDate'];
        // }

        //Processing Fees + Interest
        if (_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['processingFees']) || _.isEmpty(formData['loan']['processingFees']['valueType'])) {
            errors['processingFeeValueTypeError'] = "Please select Processing Fee type";
        } else {
            delete errors['processingFeeValueTypeError'];
        }

        if (_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['processingFees']) || !formData['loan']['processingFees']['value']) {
            errors['processingFeeValueError'] = "Please enter Processing Fee value";
        }
        else if (formData['loan']['processingFees']['valueType'] === "PERCENTAGE" && formData['loan']['processingFees']['value'] && (formData['loan']['processingFees']['value'] < 0 || formData['loan']['processingFees']['value'] > 100)) {
            errors['processingFeeValueError'] = "Please enter a value between 0 and 100";
        }
        else if (formData && formData['loan'] && formData['loan']['processingFees']['value'] && formData['loan']['processingFees']['value'] < 0) {
            errors['processingFeeValueError'] = "Negative value not accept";
        }
        else {
            delete errors['processingFeeValueError'];
        }

        if (!_.isEmpty(formData['loan']) && !_.isEmpty(formData['loan']['processingFees']) && !_.isEmpty(formData['loan']['processingFees']['subscriptionTenure']) && !(formData['loan']['processingFees']['subscriptionTenure']['value'])) {
            errors['processingFeeSubscriptionTenureValueError'] = "Please enter Subscription tenure value";
        } else {
            delete errors['processingFeeSubscriptionTenureValueError'];
        }

        if (_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['recoverInterest']) || !formData['loan']['recoverInterest']['stage']) {
            errors['recoverInterestStageError'] = "Please select Interest stage";
        } else {
            delete errors['recoverInterestStageError'];
        }

        if (_.isEmpty(formData['interest']) || !formData['interest']['type']) {
            errors['interestComputationTypeError'] = "Please select Interest Computation type";
        } else {
            delete errors['interestComputationTypeError'];
        }

        if (_.isEmpty(formData['interest']) || _.isEmpty(formData['interest']['charges']) || !formData['interest']['charges']['valueType']) {
            errors['interestValueTypeError'] = "Please select Interest type";
        } else {
            delete errors['interestValueTypeError'];
        }


        if (_.isEmpty(formData['interest']) || _.isEmpty(formData['interest']['charges']) || !formData['interest']['charges']['value']) {
            errors['interestValueError'] = "Please enter Interest value";
        }
        else if (formData['interest']['charges']['valueType'] === "PERCENTAGE" && formData['interest']['charges']['value'] && (formData['interest']['charges']['value'] < 0 || formData['interest']['charges']['value'] > 100)) {
            errors['interestValueError'] = "Please enter a value between 0 and 100";
        }
        else if (formData && formData['interest'] && formData['interest']['charges'] && formData['interest']['charges']['value'] && formData['interest']['charges']['value'] < 0) {
            errors['interestValueError'] = "Negative value are not accepted";
        }
        else {
            delete errors['interestValueError'];
        }

        // // if (_.isEmpty(formData['subVention']) || _.isEmpty(formData['subVention']['recoverInterest']) || _.isEmpty(formData['subVention']['recoverInterest']['stage'])) {
        // //     errors['recoverInterestStageError'] = "Please select Interest stage";
        // // }

        let subventionRecoverInterestSpreadArray = null;
        let subventionProcessingFeesSpreadArray = null;

        //SubVention M Interest + Proc Fees
        subventionRecoverInterestSpreadArray = formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 ? formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0] : null;
        if (!isSubventionInterestCheckNotSelectedM && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['valueType']) {
            errors['subVentionMInterestTypeError'] = "Please select Interest value type";
        } else {
            delete errors['subVentionMInterestTypeError'];
        }

        if (!isSubventionInterestCheckNotSelectedM && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['value']) {
            errors['subVentionMInterestValueError'] = "Please enter Interest value";
        } else if (!isSubventionInterestCheckNotSelectedM && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && subventionRecoverInterestSpreadArray['amount']['value'] > 100) {
            errors['subVentionMInterestValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionInterestCheckNotSelectedM && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && subventionInterestValueTypeM === "PARTLY" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] == 100) {
        //     errors['subVentionMInterestValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionMInterestValueError'];
        }

        subventionProcessingFeesSpreadArray = formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT").length > 0 ? formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0] : null;

        if (!isSubventionProcessingFeesCheckNotSelectedM && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['valueType']) {
            errors['subVentionMProcessingFeesTypeError'] = "Please select Processing Fees value type";
        } else {
            delete errors['subVentionMProcessingFeesTypeError'];
        }

        if (!isSubventionProcessingFeesCheckNotSelectedM && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['value']) {
            errors['subVentionMProcessingFeesValueError'] = "Please enter Processing Fees value";
        } else if (!isSubventionProcessingFeesCheckNotSelectedM && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && subventionProcessingFeesSpreadArray['amount']['value'] > 100) {
            errors['subVentionMProcessingFeesValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionProcessingFeesCheckNotSelectedM && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && subventionProcessingFeesValueTypeM === "PARTLY" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "MERCHANT")[0]['amount']['value'] === 100) {
        //     errors['subVentionMProcessingFeesValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionMProcessingFeesValueError'];
        }

        //SubVention EP Interest + Proc Fees
        subventionRecoverInterestSpreadArray = formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 ? formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0] : null;

        if (!isSubventionInterestCheckNotSelectedEP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['valueType']) {
            errors['subVentionEPInterestTypeError'] = "Please select Interest value type";
        } else {
            delete errors['subVentionEPInterestTypeError'];
        }

        if (!isSubventionInterestCheckNotSelectedEP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['value']) {
            errors['subVentionEPInterestValueError'] = "Please enter Interest value";
        } else if (!isSubventionInterestCheckNotSelectedEP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && subventionRecoverInterestSpreadArray['amount']['value'] > 100) {
            errors['subVentionEPInterestValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionInterestCheckNotSelectedEP && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && subventionInterestValueTypeEP === "PARTLY" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] === 100) {
        //     errors['subVentionEPInterestValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionEPInterestValueError'];
        }

        let borneUptoValue;

        if (!isSubventionInterestCheckNotSelectedEP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['tenure'] && subventionRecoverInterestSpreadArray['tenure']['value']) {
            borneUptoValue = subventionRecoverInterestSpreadArray['tenure']['value'];
        }

        if (!isSubventionInterestCheckNotSelectedEP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['tenure'] && !subventionRecoverInterestSpreadArray['tenure']['value']) {
            errors['subVentionEPInterestBorneUptoError'] = "Please enter Borne Upto value";
        } else if (formData['tenure'] && formData['tenure']['min'] && formData['tenure']['max'] && (borneUptoValue > formData['tenure']['max'] || borneUptoValue < formData['tenure']['min'])) {
            errors['subVentionEPInterestBorneUptoError'] = "Please enter a value between Min Tenure and Max Tenure";
        }
        else {
            delete errors['subVentionEPInterestBorneUptoError'];
        }

        subventionProcessingFeesSpreadArray = formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER").length > 0 ? formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0] : null;

        if (!isSubventionProcessingFeesCheckNotSelectedEP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['valueType']) {
            errors['subVentionEPProcessingFeesTypeError'] = "Please select Processing Fees value type";
        } else {
            delete errors['subVentionEPProcessingFeesTypeError'];
        }

        if (!isSubventionProcessingFeesCheckNotSelectedEP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['value']) {
            errors['subVentionEPProcessingFeesValueError'] = "Please enter Processing Fees value";
        } else if (!isSubventionProcessingFeesCheckNotSelectedEP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && subventionProcessingFeesSpreadArray['amount']['value'] > 100) {
            errors['subVentionEPProcessingFeesValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionProcessingFeesCheckNotSelectedEP && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && subventionProcessingFeesValueTypeEP === "PARTLY" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "ENTERPRISE_PARTNER")[0]['amount']['value'] === 100) {
        //     errors['subVentionEPProcessingFeesValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionEPProcessingFeesValueError'];
        }

        //SubVention LP Interest + Proc Fees
        subventionRecoverInterestSpreadArray = formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 ? formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0] : null;

        if (!isSubventionInterestCheckNotSelectedLP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['valueType']) {
            errors['subVentionLPInterestTypeError'] = "Please select Interest value type";
        } else {
            delete errors['subVentionLPInterestTypeError'];
        }

        if (!isSubventionInterestCheckNotSelectedLP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['value']) {
            errors['subVentionLPInterestValueError'] = "Please enter Interest value";
        } else if (!isSubventionInterestCheckNotSelectedLP && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && subventionRecoverInterestSpreadArray['amount']['value'] > 100) {
            errors['subVentionLPInterestValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionInterestCheckNotSelectedLP && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && subventionInterestValueTypeLP === "PARTLY" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] === 100) {
        //     errors['subVentionLPInterestValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionLPInterestValueError'];
        }

        subventionProcessingFeesSpreadArray = formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER").length > 0 ? formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0] : null;

        if (!isSubventionProcessingFeesCheckNotSelectedLP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['valueType']) {
            errors['subVentionLPProcessingFeesTypeError'] = "Please select Processing Fees value type";
        } else {
            delete errors['subVentionLPProcessingFeesTypeError'];
        }

        if (!isSubventionProcessingFeesCheckNotSelectedLP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['value']) {
            errors['subVentionLPProcessingFeesValueError'] = "Please enter Processing Fees value";
        } else if (!isSubventionProcessingFeesCheckNotSelectedLP && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && subventionProcessingFeesSpreadArray['amount']['value'] > 100) {
            errors['subVentionLPProcessingFeesValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionProcessingFeesCheckNotSelectedLP && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && subventionProcessingFeesValueTypeLP === "PARTLY" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "LENDING_PARTNER")[0]['amount']['value'] === 100) {
        //     errors['subVentionLPProcessingFeesValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionLPProcessingFeesValueError'];
        }

        //SubVention FF Interest + Proc Fees
        subventionRecoverInterestSpreadArray = formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 ? formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0] : null;

        if (!isSubventionInterestCheckNotSelectedFF && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['valueType']) {
            errors['subVentionFFInterestTypeError'] = "Please select Interest value type";
        } else {
            delete errors['subVentionFFInterestTypeError'];
        }

        if (!isSubventionInterestCheckNotSelectedFF && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && !subventionRecoverInterestSpreadArray['amount']['value']) {
            errors['subVentionFFInterestValueError'] = "Please enter Interest value";
        } else if (!isSubventionInterestCheckNotSelectedFF && subventionRecoverInterestSpreadArray && subventionRecoverInterestSpreadArray['amount'] && subventionRecoverInterestSpreadArray['amount']['value'] > 100) {
            errors['subVentionFFInterestValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionInterestCheckNotSelectedFF && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread'] && subventionInterestValueTypeFF === "PARTLY" && formData['subVention']['recoverInterest']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] === 100) {
        //     errors['subVentionFFInterestValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionFFInterestValueError'];
        }

        subventionProcessingFeesSpreadArray = formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA").length > 0 ? formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0] : null;

        if (!isSubventionProcessingFeesCheckNotSelectedFF && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['valueType']) {
            errors['subVentionFFProcessingFeesTypeError'] = "Please select Processing Fees value type";
        } else {
            delete errors['subVentionFFProcessingFeesTypeError'];
        }

        if (!isSubventionProcessingFeesCheckNotSelectedFF && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && !subventionProcessingFeesSpreadArray['amount']['value']) {
            errors['subVentionFFProcessingFeesValueError'] = "Please enter Processing Fees value";
        } else if (!isSubventionProcessingFeesCheckNotSelectedFF && subventionProcessingFeesSpreadArray && subventionProcessingFeesSpreadArray['amount'] && subventionProcessingFeesSpreadArray['amount']['value'] > 100) {
            errors['subVentionFFProcessingFeesValueError'] = "Please enter a value between 0 and 100";
        }
        // else if (!isSubventionProcessingFeesCheckNotSelectedFF && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread'] && subventionProcessingFeesValueTypeFF === "PARTLY" && formData['subVention']['processingFees']['spread'].filter((item) => item['entity'] === "FUNDFINA")[0]['amount']['value'] === 100) {
        //     errors['subVentionFFProcessingFeesValueError'] = "For type Partly, please enter a value less than 100";
        // }
        else {
            delete errors['subVentionFFProcessingFeesValueError'];
        }


        let percentageSum = 0;
        if (formData['subVention'] && formData['subVention']['recoverInterest'] && formData['subVention']['recoverInterest']['spread']) {
            for (const item of formData['subVention']['recoverInterest']['spread']) {
                percentageSum += item['amount']['value'];
            }

            if (percentageSum > 100) {
                for (const item of formData['subVention']['recoverInterest']['spread']) {
                    switch (item['entity']) {
                        case 'ENTERPRISE_PARTNER':
                            errors['subVentionEPInterestValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'MERCHANT':
                            errors['subVentionMInterestValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'LENDING_PARTNER':
                            errors['subVentionLPInterestValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'FUNDFINA':
                            errors['subVentionFFInterestValueError'] = "Total of values cannot be greater than 100";
                            break;
                    }
                }
            } else if (percentageSum < 100) {
                for (const item of formData['subVention']['recoverInterest']['spread']) {
                    switch (item['entity']) {
                        case 'ENTERPRISE_PARTNER':
                            errors['subVentionEPInterestValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'MERCHANT':
                            errors['subVentionMInterestValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'LENDING_PARTNER':
                            errors['subVentionLPInterestValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'FUNDFINA':
                            errors['subVentionFFInterestValueError'] = "Total of values cannot be less than 100";
                            break;
                    }
                }
            }

        }
        percentageSum = 0;

        if (formData['subVention'] && formData['subVention']['processingFees'] && formData['subVention']['processingFees']['spread']) {
            for (const item of formData['subVention']['processingFees']['spread']) {
                percentageSum += item['amount']['value'];
            }

            if (percentageSum > 100) {
                for (const item of formData['subVention']['processingFees']['spread']) {
                    switch (item['entity']) {
                        case 'ENTERPRISE_PARTNER':
                            errors['subVentionEPProcessingFeesValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'MERCHANT':
                            errors['subVentionMProcessingFeesValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'LENDING_PARTNER':
                            errors['subVentionLPProcessingFeesValueError'] = "Total of values cannot be greater than 100";
                            break;

                        case 'FUNDFINA':
                            errors['subVentionFFProcessingFeesValueError'] = "Total of values cannot be greater than 100";
                            break;
                    }
                }
            } else if (percentageSum < 100) {
                for (const item of formData['subVention']['processingFees']['spread']) {
                    switch (item['entity']) {
                        case 'ENTERPRISE_PARTNER':
                            errors['subVentionEPProcessingFeesValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'MERCHANT':
                            errors['subVentionMProcessingFeesValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'LENDING_PARTNER':
                            errors['subVentionLPProcessingFeesValueError'] = "Total of values cannot be less than 100";
                            break;

                        case 'FUNDFINA':
                            errors['subVentionFFProcessingFeesValueError'] = "Total of values cannot be less than 100";
                            break;
                    }
                }
            }

        }
        percentageSum = 0;



        let processingFeesList = formData.subVention && formData.subVention.processingFees;

        if (processingFeesList && processingFeesList.spread && processingFeesList.spread.length) {

            for (let j = 0; j < processingFeesList.spread.length; j++) {

                let item = processingFeesList.spread[j];
                if (item.amount && item.amount.value < 0) {

                    if (item.entity == "ENTERPRISE_PARTNER")
                        errors['subVentionEPProcessingFeesValueError'] = "Negative value not allowed";

                    if (item.entity == "LENDING_PARTNER")
                        errors['subVentionLPProcessingFeesValueError'] = "Negative value not allowed";

                    if (item.entity == "FUNDFINA")
                        errors['subVentionFFProcessingFeesValueError'] = "Negative value not allowed";

                }
            }
        }

        let recoverInterestList = formData.subVention && formData.subVention.recoverInterest;

        if (recoverInterestList && recoverInterestList.spread && recoverInterestList.spread.length) {

            for (let j = 0; j < recoverInterestList.spread.length; j++) {

                let item = recoverInterestList.spread[j];


                if (item.amount && item.amount.value < 0) {
                    if (item.entity == "ENTERPRISE_PARTNER")
                        errors['subVentionEPInterestValueError'] = "Negative value not allowed";

                    if (item.entity == "LENDING_PARTNER")
                        errors['subVentionLPInterestValueError'] = "Negative value not allowed";

                    if (item.entity == "FUNDFINA")
                        errors['subVentionFFInterestValueError'] = "Negative value not allowed";

                }

                if (item.tenure && item.tenure.value < 0) {
                    if (item.entity == "ENTERPRISE_PARTNER")
                        errors['subVentionEPInterestBorneUptoError'] = "Negative value not allowed";

                }
            }
        }

        //Collections
        if (_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection']) || !('afterDays' in formData['loan']['collection'])) {
            errors['collectionAfterDaysValueError'] = "Please enter Collection After Days value";

        } else if (!_.isEmpty(formData['loan']) && !_.isEmpty(formData['loan']['collection']) && !('afterDays' in formData['loan']['collection'])) {

            errors['collectionAfterDaysValueError'] = "Please enter Collection After Days value";

        } else if (formData && formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['afterDays'] && formData['loan']['collection']['afterDays'] < 0) {
            errors['collectionAfterDaysValueError'] = "Negative value not accept";
        }
        else {
            delete errors['collectionAfterDaysValueError'];
        }

        if ((!_.isEmpty(formData['type']) && formData['type'] !== "CL") && ((_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection']) || _.isEmpty(formData['loan']['collection']['frequency'])) || !formData['loan']['collection']['frequency']['valueType'])) {
            errors['collectionTypeError'] = "Please select Collection type";
        } else if ((!_.isEmpty(formData['type']) && formData['type'] === "CL") && ((_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection']) || _.isEmpty(formData['loan']['collection']['type'])) || (formData['loan']['collection']['type'].length < 2))) {
            errors['collectionTypeError'] = "Please select Collection type";
        } else {
            delete errors['collectionTypeError'];
        }

        if ((!_.isEmpty(formData['type']) && formData['type'] !== "CL") && ((!_.isEmpty(formData['loan']) && !_.isEmpty(formData['loan']['collection']) && !_.isEmpty(formData['loan']['collection']['frequency']) && !_.isEmpty(formData['loan']['collection']['frequency']['valueType']) && formData['loan']['collection']['frequency']['valueType'] && formData['loan']['collection']['frequency']['valueType'] === "ADHOC" && !formData['loan']['collection']['frequency']['value']))) {
            errors['collectionAdhocValueError'] = "Please enter Adhoc days value";
        } else if (formData && formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['frequency'] && formData['loan']['collection']['frequency']['value'] < 0) {
            errors['collectionAdhocValueError'] = "Negative value not accept";
        }
        else {
            delete errors['collectionAdhocValueError'];
        }

        // if ((!_.isEmpty(formData['type']) && formData['type'] === "CL") && ((_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection']) || _.isEmpty(formData['loan']['collection']['type'])) || (formData['loan']['collection']['type'].length < 2))) {
        //     errors['collectionTypeError'] = "Please select Collection type";
        // } else {
        //     delete errors['collectionTypeError'];
        // }

        if ((!_.isEmpty(formData['type']) && formData['type'] === "CL") && ((_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection'])) || (!formData['loan']['collection']['installments']))) {
            errors['collectionNoOfInstallmentsError'] = "Please enter number of Installments";
        } else if (formData && formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['installments'] < 0) {
            errors['collectionNoOfInstallmentsError'] = "Negative value not accept";
        } else {
            delete errors['collectionNoOfInstallmentsError'];
        }

        if ((!_.isEmpty(formData['type']) && formData['type'] === "CL") && ((_.isEmpty(formData['loan']) || _.isEmpty(formData['loan']['collection']) || (!formData['loan']['collection']['maxDays'])))) {
            errors['collectionmaxDaysError'] = "Please enter maximum days";
        } else if (formData && formData['loan'] && formData['loan']['collection'] && formData['loan']['collection']['maxDays'] && formData['loan']['collection']['maxDays'] < 0) {
            errors['collectionmaxDaysError'] = "Negative value not accept";
        } else {
            delete errors['collectionmaxDaysError'];
        }

        //Late Payment block
        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['graceDays']) {
            errors['graceDaysError'] = "Please enter Grace Days value";
        } else if (formData && formData['latePayment'] && formData['latePayment']['graceDays'] && formData['latePayment']['graceDays'] < 0) {
            errors['graceDaysError'] = "Negative Value Not Accept";
        } else {
            delete errors['graceDaysError'];
        }

        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['valueType']) {
            errors['latePaymentValueTypeError'] = "Please select Value Type value";
        } else {
            delete errors['latePaymentValueTypeError'];
        }

        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['value']) {
            errors['latePaymentValueError'] = "Please enter value";
        } else if (formData['latePayment']['valueType'] === "PERCENTAGE" && formData['latePayment']['value'] && formData['latePayment']['value'] > 100) {
            errors['latePaymentValueError'] = "Please enter a value between 0 and 100";
        } else if (formData && formData['latePayment'] && formData['latePayment']['value'] && formData['latePayment']['value'] < 0) {
            errors['latePaymentValueError'] = "Negative value not accept";
        }
        else {
            delete errors['latePaymentValueError'];
        }

        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['installments']['type']) {
            errors['latePaymentInstallmentsTypeError'] = "Please select Per Installment OR Pending Amount";
        }
        else {
            delete errors['latePaymentInstallmentsTypeError'];
        }

        if (formData && formData['latePayment'] && formData['latePayment']['installments'] && formData['latePayment']['installments']['type'] && formData['latePayment']['installments']['type'] === "PENDING_AMOUNT" && !formData['latePayment']['installments']['principal'] && formData['latePayment']['installments']['interest']) {
            errors['latePaymentCheckValuesError'] = "Please select either principal OR principal with interest";
        } else {
            delete errors['latePaymentCheckValuesError'];
        }


        //Late After block
        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['lateAfter']) {
            errors['lateAfterError'] = "Please enter Late After value";
        } else if (formData && formData['latePayment'] && formData['latePayment']['lateAfter'] < 0) {
            errors['lateAfterError'] = "Negative value not accept";
        } else {
            delete errors['lateAfterError'];
        }


        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['delinquentAfter']) {
            errors['delinquentAfterError'] = "Please enter Delinquent After value";
        } else if (formData && formData['latePayment'] && formData['latePayment']['delinquentAfter'] < 0) {
            errors['delinquentAfterError'] = "Negative value not accept";
        } else {
            delete errors['delinquentAfterError'];
        }

        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['defaultAfter']) {
            errors['defaultAfterError'] = "Please enter Default After value";
        } else if (!formData['latePayment']['defaultAfter']) {
            errors['defaultAfterError'] = "Please enter Default After value";

        } else if (formData && formData['latePayment'] && formData['latePayment']['defaultAfter'] < 0) {
            errors['defaultAfterError'] = "Negative value not accept";

        } else {
            delete errors['defaultAfterError'];
        }

        if (_.isEmpty(formData['latePayment']) || !formData['latePayment']['writeOffAfter']) {
            errors['writeOffAfterError'] = "Please enter Write Off After value";
        } else if (formData && formData['latePayment'] && formData['latePayment']['writeOffAfter'] < 0) {
            errors['writeOffAfterError'] = "Negative value not accept";
        } else if (!formData['latePayment']['writeOffAfter']) {
            errors['writeOffAfterError'] = "Please enter Write Off After value";

        } else {
            delete errors['writeOffAfterError'];
        }

        //General Error

        if (!_.isEmpty(errors)) {
            errors['globalError'] = "Please fill all fields correctly"
        }


        this.setState({
            errors
        })

        return errors

    }

    async handleFormSubmit(event) {
        event.preventDefault();

        let errors = this.state.errors;
        let errorsObj = this.handleCustomErrors();


        if (_.isEmpty(errorsObj)) {

            let { id } = event.nativeEvent.submitter;

            if (id === "saveDraft") {
                await this.handleProductSaveInForm(false)
            } else {
                await this.handleProductSaveInForm(true)
            }
        }


    }
}

export default ProductMasterModel;